/**
 * @category Utilities
 * @namespace Utilities.Helpers
 * @description Utility functions used across the application.
 */
// * @module src/utils/helpers

import React from "react";
import {
  deepCopy,
  getModelById,
  getModelsByIds,
  replaceModelById,
} from "../components/leftSide/Models/modelLogic";
import { getUpdatedUniqueIds } from "../components/rightSide/parameters/parameterLogic";

/**
 * Creates a warning object with given text and level, then adds it to the global
 * warning context and updates the *new* warning count (not yet seen by user).
 * @memberof Utilities.Helpers
 * @function
 * @param {string} message - A string message that the users sees relating to the error.
 * @param {number} level - Error level, 0 - info, 1 - warning, 2 - error.
 * @param {Function} setWarnings - Function to set the global warning context.
 * @param {Function} setNewWarningCount - Function to set new warning count.
 */
export function generateWarningObject(
  message,
  level,
  setWarnings,
  setNewWarningCount,
  isReportable = false,
  requestId = "none"
) {
  const now = new Date();
  const formattedTime = now.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const formattedDate = now.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const formattedDateTime = `${formattedTime} ${formattedDate}`;

  setNewWarningCount((old) => old + 1);

  setWarnings((old) => [
    ...old,
    {
      message: message,
      level: level,
      time: formattedDateTime,
      reportable: isReportable,
      requestId: requestId,
    },
  ]);
}

const symbols = {
  // Greek letters (lowercase)
  alpha: "α",
  beta: "β",
  gamma: "γ",
  delta: "δ",
  epsilon: "ε",
  zeta: "ζ",
  eta: "η",
  theta: "θ",
  iota: "ι",
  kappa: "κ",
  lambda: "λ",
  mu: "μ",
  nu: "ν",
  xi: "ξ",
  omicron: "ο",
  pi: "π",
  rho: "ρ",
  sigma: "σ",
  tau: "τ",
  upsilon: "υ",
  phi: "φ",
  chi: "χ",
  psi: "ψ",
  omega: "ω",
  // Greek letters (uppercase)
  Alpha: "Α",
  Beta: "Β",
  Gamma: "Γ",
  Delta: "Δ",
  Epsilon: "Ε",
  Zeta: "Ζ",
  Eta: "Η",
  Theta: "Θ",
  Iota: "Ι",
  Kappa: "Κ",
  Lambda: "Λ",
  Mu: "Μ",
  Nu: "Ν",
  Xi: "Ξ",
  Omicron: "Ο",
  Pi: "Π",
  Rho: "Ρ",
  Sigma: "Σ",
  Tau: "Τ",
  Upsilon: "Υ",
  Phi: "Φ",
  Chi: "Χ",
  Psi: "Ψ",
  Omega: "Ω",
  // Symbols
  inf: "∞",
};

/**
 * Converts greek letters and symbols in the input string to unicode.
 * @function
 * @memberof Utilities.Helpers
 * @param {string} input - Finds greek letters and symbols in the input string and replaces
 * them with their corresponding unicode characters.
 * @returns {string} Returns the input string with greek letters and symbols converted to unicode.
 */
export function processSymbols(input) {
  // Replace Greek letters
  let replaced = input.replace(/greek\((.*?)\)/g, (match, p1) => {
    return symbols[p1] || match;
  });

  // Replace symbols
  replaced = replaced.replace(/symbol\((.*?)\)/g, (match, p1) => {
    return symbols[p1] || match;
  });

  // Handle subscripts and superscripts
  let jsx = [];
  let buffer = "";
  let i = 0;
  while (i < replaced.length) {
    if (replaced[i] === "_") {
      jsx.push(buffer);
      buffer = "";
      if (replaced[i + 1] === "(") {
        let endPos = replaced.indexOf(")", i + 2);
        if (endPos !== -1) {
          jsx.push(<sub key={i}>{replaced.slice(i + 2, endPos)}</sub>);
          i = endPos + 1;
        } else {
          // handle error
          jsx.push("_");
          i++;
        }
      } else {
        jsx.push(<sub key={i}>{replaced[i + 1]}</sub>);
        i += 2;
      }
    } else if (replaced[i] === "^") {
      jsx.push(buffer);
      buffer = "";
      if (replaced[i + 1] === "(") {
        let endPos = replaced.indexOf(")", i + 2);
        if (endPos !== -1) {
          jsx.push(<sup key={i}>{replaced.slice(i + 2, endPos)}</sup>);
          i = endPos + 1;
        } else {
          // handle error
          jsx.push("^");
          i++;
        }
      } else {
        jsx.push(<sup key={i}>{replaced[i + 1]}</sup>);
        i += 2;
      }
    } else {
      buffer += replaced[i];
      i++;
    }
  }
  jsx.push(buffer);

  return jsx;
}

/**
 * This function takes in a string and returns a JSX element that contains the input string with greek
 * letters transformed to unicode.
 * @function
 * @memberof Utilities.Helpers
 * @param {string} param0 - takes in a property called 'input' which is a string that can contain
 * greek letters and symbols with our own encoding.
 * @returns {JSX.Element} Returns a JSX element that contains the input string with greek letters
 * transformed to unicode.
 */
export function FormattedText({ input }) {
  return <>{processSymbols(input)}</>;
}

/**
 * This function takes in a numeric value and returns a numeric value of how many pixels wide is the
 * input as a string with that many characters.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - A numeric value that represents the size of input in characters.
 * @returns {number} Returns a numeric value of how many pixels wide is the input as a string.
 */
export function useInputSize(value) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  ctx.font = "1.1rem system-ui, sans-serif"; // Match the font size and family of the input
  const inputSize = ctx.measureText(value || " ").width;

  return inputSize;
}

/**
 * This function checks if the input string is a valid number.
 * @function
 * @memberof Utilities.Helpers
 * @param {string} value - A string that is checked if it is a valid number.
 * @returns {boolean} Returns true if the input string is a valid number, false otherwise.
 */
export function isAllowedNum(value) {
  if (/^[-+]?\d*\.?\d*([Ee][+-]?\d*)?$/.test(value)) {
    return true;
  } else {
    return false;
  }
}

/**
 * This function is used extensively in the application to check if 2 objects are equal by their values.
 * This function goes through all the properties, all the way down the object tree, and checks if the objects
 * indeed have the same values.
 * @function
 * @memberof Utilities.Helpers
 * @param {object} obj1 - First object to compare.
 * @param {object} obj2 - Second object to compare.
 * @returns {boolean} Returns true if the objects are equal, false otherwise.
 */
export function isDeepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (!isDeepEqual(value1, value2)) {
      return false;
    }
  }

  return true;
}

/**
 * This function returns one of 20 colors based on the input number.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} number - A number that is used to determine which color to return.
 * @returns {string} Returns a string that represents a color in hex format.
 * @example
 * // returns "#1f77b4"
 * getColor(0);
 *
 * @example
 * // returns "#ff7f0e"
 * getColor(1);
 *
 * @example
 * // returns "#ff7f0e"
 * getColor(21);
 */
export function getColor(number) {
  const colors = [
    "#1f77b4", // blue
    "#ff7f0e", // orange
    "#2ca02c", // green
    "#d62728", // red
    "#9467bd", // purple
    "#8c564b", // brown
    "#e377c2", // pink
    "#7a0419", // dark red
    "#bcbd22", // olive
    "#17becf", // cyan
    "#aec7e8", // light blue
    "#ffbb78", // light orange
    "#98df8a", // light green
    "#ff9896", // light red
    "#c5b0d5", // light purple
    "#c49c94", // light brown
    "#f7b6d2", // light pink
    "#c7c7c7", // light gray
    "#dbdb8d", // light olive
    "#9edae5", // light cyan
  ];

  return colors[number % colors.length];
}

/**
 * This function takes graph data object and returns representatives of each curve in the graph (one representative per
 * file or model-quantity pair). This is used to display the legend.
 * @function
 * @memberof Utilities.Helpers
 * @param {object} data - This is the graph data object that contains all the data for the graph.
 * @returns {object[]} Returns an array of objects that represent each curve in the graph.
 */
export function getRepresentatives(data) {
  let representatives = [];
  let addedIds = { fileId: [], modelId: [] };

  for (let i = 0; i < data.length; i++) {
    let item = data[i];

    if (item.fileId !== undefined) {
      // This means we are dealing with file representative
      // Check if we've already added an item with this id and legendgroup
      if (
        !addedIds["fileId"].some(
          (id) =>
            id.id === item["fileId"] && id.legendgroup === item.legendgroup
        )
      ) {
        // If not, add it to the representatives array and record the id and legendgroup
        representatives.push({
          name: item.name,
          fileId: item["fileId"],
        });
        addedIds["fileId"].push({
          id: item["fileId"],
          legendgroup: item.legendgroup,
        });
      }
    } else {
      // this means we are dealing with model representative
      if (
        !addedIds["modelId"].some(
          (id) =>
            id.id === item["modelId"] &&
            id.legendgroup === item.legendgroup &&
            id.quantity === item.quantity
        )
      ) {
        // If not, add it to the representatives array and record the id and legendgroup
        representatives.push({
          name: item.name,
          modelId: item["modelId"],
          quantity: item.quantity,
        });
        addedIds["modelId"].push({
          id: item.modelId,
          legendgroup: item.legendgroup,
          quantity: item.quantity,
        });
      }
    }

    // Decide whether to use fileId or modelId for this item
    // let idKey = item.fileId !== undefined ? "fileId" : "modelId";
  }

  return representatives;
}

/**
 * This function takes in a string of file content and returns this content with headings removed.
 * This function is useful only for @link{getDataPointsFromFile} function, which is deprecated (used only in
 * demo)
 * @function
 * @memberof Utilities.Helpers
 * @param {string} fileContent - This is the string representation of the file content.
 * @returns {string} File content as a string with headings removed.
 */
function sanitizeFileContent(fileContent) {
  // Split the file content into lines
  const spaceRegex = /\s/g;

  const lines = fileContent.split("\n");

  // Use a regular expression to match lines that look like data points.
  // This regex checks for a line that contains a floating point (or integer) number,
  // followed by a tab or space, followed by a positive or negative float.
  // The \r? at the end allows for an optional carriage return character.
  const dataPointPattern = /^-?\d+(\.\d+)?(\t|\s)-?\d+(\.\d+)?(\r|\\r)?$/;

  // Find the index of the first line that looks like a data point
  const firstDataLineIndex = lines.findIndex((line) =>
    dataPointPattern.test(line)
  );

  // If no data point line was found, return an empty string
  if (firstDataLineIndex === -1) {
    return "";
  }

  // Join all the lines after the first data point line into a single string,
  // replacing spaces with tabs and newlines with a special string "#newline#"
  const joinedLines = lines.slice(firstDataLineIndex).join("#newline#");

  // Replace all spaces with tabs and all "#newline#" strings with newlines.
  // We do this instead of just replacing all the spaces with tabs because when
  // we joing by '\n', they are later treated as spaces and we get a bunch of spaces
  // and no new lines.
  const handledInput = joinedLines
    .replace(spaceRegex, "\t")
    .replace(/#newline#/g, "\n");

  return handledInput;
}

/**
 * This function takes in a string of file content and returns an array of data points. This
 * function is deprecated and no longer used in the application except for demo.
 * @deprecated
 * @function
 * @memberof Utilities.Helpers
 * @param {string} fileContent - File content as a string
 * @returns {object[]} Returns an array of data points.
 */
export function getDataPointsFromFile(fileContent) {
  const sanitizedContent = sanitizeFileContent(fileContent);

  const data = sanitizedContent
    .trim()
    .split("\n")
    .map((line) => {
      const [x, y] = line.split("\t");
      return { x: parseFloat(x), y: parseFloat(y) };
    })
    .filter((coords) => {
      return (
        coords.x != null &&
        !Number.isNaN(coords.x) &&
        coords.y != null &&
        !Number.isNaN(coords.y)
      );
    });

  return data;
}

/**
 * This function checks if uploaded files match the supported file extension criteria. If they do, it
 * calls the processFiles function that processes the files, otherwise it displays an alert.
 * @function
 * @memberof Utilities.Helpers
 * @param {React.ChangeEvent<HTMLInputElement>} event - This is event object of file being uploaded.
 * @param {Array.<string>} supportedExtensions - This is an array of supported file extensions.
 * @param {Function(Array.<File>)} processFiles - This is a function that processes the files if they fit the extension criteria.
 */
export const handleFileChange = (event, supportedExtensions, processFiles) => {
  const files = Array.from(event.target.files);
  // const supportedExtensions = ["json"];

  const filteredFiles = files.filter((file) => {
    const fileExtension = file.name.split(".").pop();
    return supportedExtensions.includes(fileExtension);
  });

  const unsupportedFiles = files.filter(
    (file) => !filteredFiles.includes(file)
  );

  if (unsupportedFiles.length > 0) {
    const unsupportedFileNames = unsupportedFiles
      .map((file) => file.name)
      .join(", ");
    alert(
      `The following files have unsupported formats and will not be processed: ${unsupportedFileNames}`
    );
  }

  if (filteredFiles.length > 0) {
    processFiles(filteredFiles)
      .then(() => {
        // If files were processed successfully, clear the file input
        event.target.value = "";
      })
      .catch(() => {
        // If there was an error processing the files, clear the file input
        event.target.value = "";
      });
  } else {
    alert("Please select at least one .json file.");
  }
};

/**
 * This function takes in file content and name as string and causes a download of the file.
 * @function
 * @memberof Utilities.Helpers
 * @param {string} fileString - This is a string that represents the file content.
 * @param {string} fileName - This is as string that represents the file name.
 */
export function downloadStringTxtFile(fileString, fileName, format = ".txt") {
  const blob = new Blob([fileString], {
    type: "text/plain",
  });
  const link = document.createElement("a");

  link.href = URL.createObjectURL(blob);
  link.download = fileName + format;

  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove the link from the DOM
  setTimeout(() => {
    window.URL.revokeObjectURL(link.href);
    link.remove();
  }, 100);
}

/**
 * This takes in an array of data points and returns an array of data points that are within the
 * range of min and max.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} data - This is a list of data points.
 * @param {number} min - This is a number that represents the minimum value of the x axis.
 * @param {number} max - This is a number that represents the maximum value of the x axis.
 * @returns {object[]} Returns an array of data points that are within the range of min and max.
 */
export function filterCurveDataToRange(data, min = null, max = null) {
  if (min !== null && max !== null) {
    return data.filter((entry) => entry.x >= min && entry.x <= max);
  } else if (min === null && max !== null) {
    return data.filter((entry) => entry.x <= max);
  } else if (min !== null && max === null) {
    return data.filter((entry) => entry.x >= min);
  } else {
    return data;
  }
}

/**
 * This function checks if the input value is a number.
 * @function
 * @memberof Utilities.Helpers
 * @param {*} value - This is a value that is checked if it is a number.
 * @returns {boolean} Returns true if the input value is a number, false otherwise.
 */
export function isNumber(value) {
  return typeof value === "number" && !isNaN(value);
}

/**
 * This is a function that rounds a number to a given precision
 * @function
 * @memberof Utilities.Helpers
 * @param {number} number - This is a number that is rounded to the given precision.
 * @param {number} precision - This is a precision to which the number is rounded.
 * @returns {number} Returns a number that is rounded to the given precision.
 */
export function toPrecision(number, precision) {
  if (isNumber(number)) {
    return parseFloat(number.toFixed(precision));
  } else {
    return number;
  }
}

/**
 * This is function that checks if the object contains given property. We use this function, because it is
 * needed a lot and it simplifies the syntax.
 * @function
 * @memberof Utilities.Helpers
 * @param {object} object - This is an object that we want to check for a property.
 * @param {string} property - This is a property that we want to check if it exists in the object.
 * @returns {boolean} Returns true if the object has the property, false otherwise.
 */
export function hasProperty(object, property) {
  return Object.prototype.hasOwnProperty.call(object, property);
}

/**
 * This function takes in coordinates and returns an object that represents a curve which we use in the
 * application, for example, in a file loading system.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} coords - This is an array of data points.
 * @returns This returns an object that represents a curve.
 */
export function produceCurveObject(coords, quantity = 5) {
  return {
    xmin: Math.floor(coords[0].x),
    xmax: Math.ceil(coords[coords.length - 1].x),
    curve: coords,
    quantity: quantity,
  };
}

/**
 * This function takes in a list of curves and a new curve object. It checks whether the new curve object
 * is an update to the existing curve or a new curve. If it is an update, it replaces the old curve with
 * the new one, otherwise it adds the new curve to the list of curves.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} curveList - This is a list of curves.
 * @param {object} newCurveObj - This is a new curve object with which we want to update the list of curves.
 * @returns This returns an updated list of curves.
 */
export function updateOrAddCurve(curveList, newCurveObj) {
  // Find the index of the curve in the array with matching xmin and xmax
  const listToUse = curveList !== undefined ? curveList : [];
  const index = listToUse.findIndex(
    (item) => item.xmin === newCurveObj.xmin && item.xmax === newCurveObj.xmax
  );

  if (index !== -1) {
    // Replace the found curve with the new one
    listToUse[index] = newCurveObj;
  } else {
    // Add the new curve to the array if no match was found
    listToUse.push(newCurveObj);
  }

  return listToUse;
}

/**
 * This function takes in a max and min values and returns a number that represents the leeway based on
 * how large the distance between max and min is. This is used for loading in model curves after graph is
 * zoomed in or out (changes the x axis range displayed).
 * @function
 * @memberof Utilities.Helpers
 * @param {number} max - This is a maximum value of the x axis.
 * @param {number} min - This is a minimum value of the x axis.
 * @returns {number} This returns a number that represents the leeway.
 */
function getLeeway(max, min) {
  // Determine the leeway based on the distance between min and max
  const distance = max - min;
  if (distance > 500) return 100;
  else if (distance > 300) return 50;
  else if (distance > 150) return 25;
  else if (distance > 50) return 13;
  else if (distance > 10) return 2;
  else return 1;
}

/**
 * This function takes in an array of curve objects and min and max values and returns the best matching
 * curve object and a boolean that represents whether the best match was found. This function is used when
 * loading in model curves after graph is zoomed in or out (changes the x axis range displayed). If no
 * best match is found, it returns the first curve object in the array. We are supposed to load in a new
 * curve in that situation, so the first curve object is a temporary fallback.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} curveArray - This is an array of curve objects.
 * @param {number} min - This is a minimum value of the x axis.
 * @param {number} max - This is a maximum value of the x axis.
 * @returns {object} Returns an object that contains the best matching curve and a boolean that represents
 * whether the best match was found.
 */
export function findBestMatchingCurve(curveArray, min, max, quantity) {
  let bestMatch = null;
  let bestFound = false;
  const oneSideLeeway = getLeeway(max, min);
  let goodObjects = [];
  const filteredCurvesByQuantity = curveArray.filter(
    (curve) => curve.quantity === quantity
  );

  for (let i = 0; i < filteredCurvesByQuantity.length; i++) {
    const curveObject = filteredCurvesByQuantity[i];

    // Here we filter out curves that are smaller than required boundaries
    if (curveObject.xmin <= min && curveObject.xmax >= max) {
      // Now we find out what is the distance between curve object min/max and required min/max
      const minDist =
        curveObject.xmin - min >= 0
          ? curveObject.xmin - min
          : (curveObject.xmin - min) * -1;
      const maxDist =
        curveObject.xmax - max >= 0
          ? curveObject.xmax - max
          : (curveObject.xmax - max) * -1;

      // Here we check if leeway bounds are not stepped over
      if (minDist <= oneSideLeeway && maxDist <= oneSideLeeway) {
        // Since current curve object is withing range and leeway, we add it to consideration for best fit
        goodObjects.push({
          sum: minDist + maxDist,
          data: curveObject,
        });

        bestFound = true;
      } else {
        continue; // Leeway was stepped over, continuing the search for fitting object
      }
    } else {
      continue; // Curve object range wan smaller than required by min/max, continuing to search for objects
    }
  }

  // We find the best fitting object here - one that has the smallest distance between object min/max and
  // requested min/max
  if (bestFound) {
    bestMatch = goodObjects.reduce((smallest, current) => {
      return current.sum < smallest.sum ? current : smallest;
    }).data;
  }

  // If no best match is found, select any curve object as a fallback
  if (!bestMatch && curveArray.length > 0) {
    bestMatch = curveArray[0];
  }

  return {
    curve: bestMatch,
    bestFound: bestFound,
  };
}

// export function containsGoodCurve(curveArray, min, max) {
//   const oneSideLeeway = getLeeway(max, min);

//   for (let i = 0; i < curveArray.length; i++) {
//     const curveObject = curveArray[i];

//     // Here we filter out curves that are smaller than required boundaries
//     if (curveObject.xmin <= min && curveObject.xmax >= max) {
//       // Now we find out what is the distance between curve object min/max and required min/max
//       const minDist =
//         curveObject.xmin - min >= 0
//           ? curveObject.xmin - min
//           : (curveObject.xmin - min) * -1;
//       const maxDist =
//         curveObject.xmax - max >= 0
//           ? curveObject.xmax - max
//           : (curveObject.xmax - max) * -1;

//       // Here we check if leeway bounds are not stepped over
//       if (minDist <= oneSideLeeway && maxDist <= oneSideLeeway) {
//         return true;
//       } else {
//         continue; // Leeway was stepped over, continuing the search for fitting object
//       }
//     } else {
//       continue; // Curve object range wan smaller than required by min/max, continuing to search for objects
//     }
//   }

//   return false;
// }

/**
 * This function takes in a list of graphs and returns a dictionary of all the curve ranges used in graphs.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} graphs - This is a list of all displayed graphs.
 * @returns {object} Returns a dictionary of all the curve ranges used in graphs.
 * 
 * @example
 * // returns 
{
  "1": { // This represents model id
    "5": [ // This represents quantity
      -108.88,
      319.29
    ]
  },
  "2": {
    "10": [
      -108.88,
      319.29
    ]
  }
}
* // Number as a key represents model id, and the array represents the ranges of the curve
* // through all the graphs.
*/
export function getRangesForModelsFromGraphs(graphs) {
  let modelRangeDictionary = {};

  for (let i = 0; i < graphs.length; i++) {
    const modelsInGraph = graphs[i].containedModels;
    const xRange = graphs[i].layout.xaxis.range;

    for (let j = 0; j < modelsInGraph.length; j++) {
      const modelIdAndQuantity = modelsInGraph[j];

      if (hasProperty(modelRangeDictionary, modelIdAndQuantity.modelId)) {
        if (
          hasProperty(
            modelRangeDictionary[modelIdAndQuantity.modelId],
            modelIdAndQuantity.quantity
          )
        ) {
          modelRangeDictionary = {
            ...modelRangeDictionary,
            [modelIdAndQuantity.modelId]: {
              ...modelRangeDictionary[modelIdAndQuantity.modelId],
              [modelIdAndQuantity.quantity]: [
                ...modelRangeDictionary[modelIdAndQuantity.modelId][
                  modelIdAndQuantity.quantity
                ],
                xRange,
              ],
            },
          };
        } else {
          modelRangeDictionary = {
            ...modelRangeDictionary,
            [modelIdAndQuantity.modelId]: {
              ...modelRangeDictionary[modelIdAndQuantity.modelId],
              [modelIdAndQuantity.quantity]: [xRange],
            },
          };
        }
      } else {
        modelRangeDictionary = {
          ...modelRangeDictionary,
          [modelIdAndQuantity.modelId]: {
            [modelIdAndQuantity.quantity]: [xRange],
          },
        };
      }
    }
  }

  return modelRangeDictionary;
}

/**
 * This function takes in all the models used in the application and clears them from the unused curves.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} models - This is a list of all models loaded in the application.
 * @param {object[]} curveDictionary - This is dictionary of all the curve ranges used in graphs.
 * @returns {object[]} Returns a list of models with curves that are used in graphs.
 */
export function cleanModelsFromUnusedCurves(models, curveDictionary) {
  let updatedModels = deepCopy(models);
  const curveModels = Object.keys(curveDictionary);

  for (let i = 0; i < curveModels.length; i++) {
    const curveModel = curveModels[i];

    const modelQuantities = Object.keys(curveDictionary[curveModel]);

    const modelUsed = getModelById(parseInt(curveModel), updatedModels);

    const curvesUsedInModel = [];

    for (let k = 0; k < modelQuantities.length; k++) {
      const quantity = modelQuantities[k];

      for (let j = 0; j < curveDictionary[curveModel][quantity].length; j++) {
        const rangeSet = curveDictionary[curveModel][quantity][j];

        const bestCurve = findBestMatchingCurve(
          modelUsed.curves,
          rangeSet[0],
          rangeSet[1],
          parseInt(quantity)
        );
        curvesUsedInModel.push(bestCurve.curve);
      }
    }

    modelUsed.curves = curvesUsedInModel;
    updatedModels = replaceModelById(updatedModels, modelUsed, curveModel);
  }

  return updatedModels;
}

/**
 * This function takes in a list of curves returned from backend and returns a dictionary of all the curves.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} returnedCurves - These are the curves returned from the backend.
 * @returns {object} Returns a dictionary of all the returned curves.
 */
export function getReturnedCurveDictionary(returnedCurves) {
  let dictionary = {};

  for (let i = 0; i < returnedCurves.length; i++) {
    const curve = returnedCurves[i];
    const objectToPut = produceCurveObject(
      curve.curves[0].coordinates,
      curve.curves[0].quantity
    );

    if (hasProperty(dictionary, curve.modelid)) {
      if (hasProperty(dictionary[curve.modelid], curve.curves[0].quantity)) {
        dictionary = {
          ...dictionary,
          [curve.modelid]: {
            ...dictionary[curve.modelid],
            [curve.quantity]: [
              ...dictionary[curve.modelid][curve.curves[0].quantity],
              objectToPut,
            ],
          },
        };
      } else {
        dictionary = {
          ...dictionary,
          [curve.modelid]: {
            ...dictionary[curve.modelid],
            [curve.curves[0].quantity]: [objectToPut],
          },
        };
      }
    } else {
      dictionary = {
        ...dictionary,
        [curve.modelid]: {
          [curve.curves[0].quantity]: [objectToPut],
        },
      };
    }
  }

  return dictionary;
}

/**
 * This function takes in an array of plots that model contains, a new curve that we want to add to the model
 * and a model id that we want to add the curve to. It returns an array of plots with the new curve added to
 * the model.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} plotArray - This is an array of plots that model contains.
 * @param {object[]} newCurve - This is a new curve that we want to add to the model.
 * @param {number} modelId - This is a model id that we want to add the curve to.
 * @param {number} modelId - This is a model quantity that helps select exact curve for update.
 * @returns {object[]} Returns an array of plots with the new curve added to the model.
 */
export function replaceModelPlotData(plotArray, newCurve, modelId, quantity) {
  return plotArray.map((plot) => {
    if (
      hasProperty(plot, "modelId") &&
      plot.modelId === modelId &&
      quantity === plot.quantity
    ) {
      return {
        ...plot,
        x: newCurve.curve.curve.map((point) => point.x),
        y: newCurve.curve.curve.map((point) => point.y),
      };
    } else {
      return plot;
    }
  });
}

/**
 * This function translates a number into scientific notation if the number is longer than 5 digits.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} num - This is number that we want to translate into scientific notation.
 * @param {number} decPlaces - This is a number that represents the number of decimal places we want to use.
 * Default is 1.
 * @returns {number} Returns a number in scientific notation if the number is longer than 5 digits.
 */
export function toScientificNotation(num, decPlaces = 1) {
  // Check if the number is longer than 5 digits
  if (Math.abs(num) >= 100000) {
    // Convert to scientific notation with custom decimal places
    return num.toExponential(decPlaces);
  } else {
    // Return the number as is if it's less than 100000
    return num;
  }
}

/**
 * This is a function that takes in a list of data points and edges and returns a list of data points split
 * into segments based on edges.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} dataPoints - List of data points.
 * @param {Array.<Array.<number>>} edges - List of edges.
 * @returns {object[]} Returns a list of data points split into segments based on edges.
 */
export function splitCurveIntoSegmentsByEdges(dataPoints, edges) {
  // Split dataWithin into segments based on edges
  let segments = [];
  let currentSegment = [];
  dataPoints.forEach((point) => {
    if (
      edges.some(
        (edge) =>
          edge.min !== "" &&
          edge.max !== "" &&
          point.x > edge.min &&
          point.x < edge.max
      )
    ) {
      if (currentSegment.length > 0) {
        segments.push(currentSegment);
        currentSegment = [];
      }
    } else {
      currentSegment.push(point);
    }
  });
  if (currentSegment.length > 0) {
    segments.push(currentSegment);
  }

  return segments;
}

/**
 * This function combines segments of a curve into a single curve.
 * @function
 * @memberof Utilities.Helpers
 * @param {Array.<Array.<object>>} segments - Segments of a curve.
 * @returns {object[]} Returns a single curve.
 */
export function segmentsIntoSingleCurve(segments) {
  let singleCurve = [];

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    singleCurve = [...singleCurve, ...segment];
  }

  return singleCurve;
}

/**
 * This is a function that updates model data based on the changes in value groups.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} modelsToUpdate - This is a list of models that we want to update.
 * @param {object[]} valGroups - This is a list of value groups that we want to use to update the models.
 * @returns {object[]} Returns a list of models with updated values.
 */
export function updateModelsOnGroupChanges(modelsToUpdate, valGroups) {
  const modelDataCopy = deepCopy(modelsToUpdate);
  const updatedIds = [];

  for (let i = 0; i < modelDataCopy.length; i++) {
    const model = modelDataCopy[i];
    handleDeepGroups(model);
  }

  return {
    udpatedModels: modelDataCopy,
    updatedIds: updatedIds,
  };

  function handleDeepGroups(model) {
    let includeModelID = false;

    for (let i = 0; i < model.modelParams.length; i++) {
      const param = model.modelParams[i];
      if (Object.prototype.hasOwnProperty.call(param, "group")) {
        includeModelID = true;
        const group = valGroups.find(
          (entry) => entry.groupNumber == param.group
        );
        model.modelParams[i] = {
          ...model.modelParams[i],
          value: group.value,
          hardMax: group.hardMax != null ? group.hardMax : "",
          hardMin: group.hardMin != null ? group.hardMin : "",
        };
        if (group.fixed != null) {
          model.modelParams[i] = {
            ...model.modelParams[i],
            customFixed: group.fixed,
          };
        }
      }
    }

    if (Object.prototype.hasOwnProperty.call(model, "recParams")) {
      for (let i = 0; i < model.recParams.length; i++) {
        const paramArray = model.recParams[i];
        for (let j = 0; j < paramArray.length; j++) {
          const param = paramArray[j];
          if (Object.prototype.hasOwnProperty.call(param, "group")) {
            includeModelID = true;
            const group = valGroups.find(
              (entry) => entry.groupNumber == param.group
            );
            model.recParams[i][j] = {
              ...model.recParams[i][j],
              hardMax: group.hardMax != null ? group.hardMax : "",
              hardMin: group.hardMin != null ? group.hardMin : "",
            };
            if (group.fixed != null) {
              model.recParams[i][j] = {
                ...model.recParams[i][j],
                customFixed: group.fixed,
              };
            }
            model.recTableRows[i] = {
              ...model.recTableRows[i],
              [param.name]: group.value,
            };
          }
        }
      }
    }

    if (includeModelID) {
      updatedIds.push(model.FE_ID);
    }

    if (model.subModels.length > 0) {
      model.subModels.forEach((model) => {
        handleDeepGroups(model);
      });
    }
  }
}

/**
 * This function takes in a list of groups and a model, it updates that list with values from the model.
 * This function is used for laoding in models from files and checking if they have clashing groups to the
 * groups in the context.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} groups - This is a list of groups, not necessarily from the context.
 * @param {object} model - A model that we want to extract group information from.
 * @returns {object[]} Returns a list of groups with values from the model.
 */
export function produceGroupListWithModelGroups(groups, model) {
  if (model !== null) {
    let copyOfGroups = deepCopy(groups);

    for (let i = 0; i < model.modelParams.length; i++) {
      const param = model.modelParams[i];

      if (hasProperty(param, "group")) {
        copyOfGroups = copyOfGroups.map((group) => {
          if (group.groupNumber === param.group && group.value === null) {
            return { ...group, value: param.value };
          }
          return group;
        });
      }
    }

    for (let j = 0; j < model.recParams.length; j++) {
      const paramRow = model.recParams[j];

      for (let i = 0; i < paramRow.length; i++) {
        const param = paramRow[i];

        if (hasProperty(param, "group")) {
          copyOfGroups = copyOfGroups.map((group) => {
            if (group.groupNumber === param.group && group.value === null) {
              return { ...group, value: param.value };
            }
            return group;
          });
        }
      }
    }

    return copyOfGroups;
  } else {
    return null;
  }
}

/**
 * This function takes in an object with all boolean properties and checks if all the values are false. This
 * is used in deciding whether left side should be displayed in the application.
 * @function
 * @memberof Utilities.Helpers
 * @param {object} paramSet - This is an object containing only boolean values.
 * @returns {boolean} Returns true if all the values in the object are false, false otherwise.
 */
export function isAllFalse(paramSet) {
  const keys = Object.keys(paramSet);

  let allFalse = true;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (paramSet[key]) {
      allFalse = false;
      break;
    }
  }

  return allFalse;
}

/**
 * This function takes in a string and returns a shortened version of it if it is longer than 20 characters.
 * @function
 * @memberof Utilities.Helpers
 * @param {string} name - String to be shortened.
 * @returns {string} Returns a shortened string.
 */
export function getShortName(name) {
  const MAX_NAME_LENGTH = 20;
  return name.length > MAX_NAME_LENGTH
    ? name.slice(0, MAX_NAME_LENGTH - 3) + "..."
    : name;
}

/**
 * This function takes in an array of ranges and checks if any of those ranges wrap zero. This is used in
 * checking if the user did the 0 exlusion himself when automatic exlusion is off.
 * @function
 * @memberof Utilities.Helpers
 * @param {object[]} arr - This is an array of ranges.
 * @returns {boolean} Returns true if any of the ranges in the array wrap zero, false otherwise.
 */
export function doesAnyRangeWrapZero(arr) {
  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];

    if (element.min === "" || element.max === "") {
      continue;
    }

    if (element.min <= 0 && element.max >= 0) {
      return true;
    }
  }
  return false;
}

const h = 4.135667516e-15;
const c = 29979245800;

/**
 * This function translates a number given in cm-1 to eV.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in eV.
 */
export function cm1ToeV(value) {
  return value * h * c;
}

/**
 * This function translates a number given in cm-1 to THz.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in THz.
 */
export function cm1ToTHz(value) {
  return value * c * 1e-12;
}

/**
 * This function translates a number given in eV to cm-1.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in cm-1.
 */
export function eVTocm1(value) {
  return value / (h * c);
}

/**
 * This function translates a number given in eV to THz.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in THz.
 */
export function eVToTHz(value) {
  return cm1ToTHz(eVTocm1(value));
}

/**
 * This function translates a number given in THz to cm-1.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in cm-1.
 */
export function THzTocm1(value) {
  return (value * 1e12) / c;
}

/**
 * This function translates a number given in THz to eV.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in eV.
 */
export function THzToeV(value) {
  return THzTocm1(cm1ToeV(value));
}

/**
 * This function translates a number given in nm to cm-1.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in cm-1.
 */
export function nmTocm1(value) {
  if (value === 0 || value === 1e-30) {
    return 1e-30;
  }
  return 10000000 / value;
}

/**
 * This function translates a number given in cm-1 to nm.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in nm.
 */
export function cm1Tonm(value) {
  if (value === 0 || value === 1e-30) {
    return 1e-30;
  }
  return 10000000 / value;
}

/**
 * This function translates a number given in nm to eV.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in eV.
 */
export function nmToeV(value) {
  return cm1ToeV(nmTocm1(value));
}

/**
 * This function translates a number given in nm to THz.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in THz.
 */
export function nmToTHz(value) {
  return cm1ToTHz(nmTocm1(value));
}

/**
 * This function translates a number given in eV to nm.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in nm.
 */
export function eVTonm(value) {
  return cm1Tonm(eVTocm1(value));
}

/**
 * This function translates a number given in THz to nm.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in nm.
 */
export function THzTonm(value) {
  return cm1Tonm(THzTocm1(value));
}

/**
 * This function translates a number given in cm-1 to microns.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in microns.
 */
export function cm1toMicrons(value) {
  if (value === 0 || value === 1e-30) {
    return 1e-30;
  }
  return 10000 / value;
}

/**
 * This function translates a number given in microns to cm-1.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in cm-1.
 */
export function micronsTocm1(value) {
  if (value === 0 || value === 1e-30) {
    return 1e-30;
  }
  return 10000 / value;
}

/**
 * This function translates a number given in eV to microns.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in microns.
 */
export function eVToMicrons(value) {
  return cm1toMicrons(eVTocm1(value));
}

/**
 * This function translates a number given in THz to microns.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in microns.
 */
export function THzToMicrons(value) {
  return cm1toMicrons(THzTocm1(value));
}

/**
 * This function translates a number given in microns to eV.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in eV.
 */
export function micronsToeV(value) {
  return cm1ToeV(micronsTocm1(value));
}

/**
 * This function translates a number given in microns to THz.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in THz.
 */
export function micronsToTHz(value) {
  return cm1ToTHz(micronsTocm1(value));
}

/**
 * This function translates a number given in cm-1 to ps.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in microns.
 */
export function cm1tops(value) {
  if (value === 0 || value === 1e-30) {
    return 1e-30;
  }
  return (1e12 * c) / value;
}

/**
 * This function translates a number given in ps to cm-1.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - Numeric value to be converted.
 * @returns {number} Returns a number that represents the value in cm-1.
 */
export function psTocm1(value) {
  if (value === 0 || value === 1e-30) {
    return 1e-30;
  }
  return (1e12 * c) / value;
}

/**
 * This function takes in a string and returns true if it is in a correct email syntax
 * @function
 * @memberof Utilities.Helpers
 * @param {string} email - String representing email
 * @returns {boolean} Returns true if email is in correct format
 */
export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

/**
 * This function takes in days as number and returns a data string in ISO 8601 format.
 * @function
 * @memberof Utilities.Helpers
 * @deprecated
 * @param {number} days - This number represents how many days we want to add to today
 * @returns {string} Returns current time with given days added in ISO 8601 format
 * @example
 * // returns "2024-02-18T15:01:03.438Z"
 * addDaysToDate(5);
 *
 * @example
 * // returns "2024-02-13T15:01:03.438Z"
 * addDaysToDate(0);
 */
export function addDaysToDate(days) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + days);
  return currentDate.toISOString();
}

/**
 * This function takes in date string in ISO 8601 format and returns it in a more readable format
 * @function
 * @memberof Utilities.Helpers
 * @param {string} dateString - This string represents date string in ISO 8601 forma
 * @returns {string} This function returns string in DD-MM-YYYY HH:mm format
 */
export function formatDate(dateString) {
  // Create a Date object from the input string
  const date = new Date(dateString);

  // Get the day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();

  // Get the hours and minutes
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Format the string
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

/**
 * This function takes in a date and returns if it's in the future or not. Used for determining if poll
 * is still active.
 * @function
 * @memberof Utilities.Helpers
 * @param {string} dateString - This is ISO 8601 format date string
 * @returns {boolean} This function returns true if given date is in the future, false otherwise
 */
export function isActive(dateString) {
  // Parse the given date string and current date to Date objects
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  // Remove milliseconds for precise comparison to the second
  givenDate.setMilliseconds(0);
  currentDate.setMilliseconds(0);

  // Compare the two dates
  if (givenDate > currentDate) {
    return true;
  } else {
    return false;
  }
}

/**
 * This function takes in a part and total numeric values and return how many percent is part of the total.
 * @function
 * @memberof Utilities.Helpers
 * @param {number} part - This is a number that we want to know the percentage of.
 * @param {number} total - This is a total number of counts that represents 100%.
 * @returns {number} Returns a numeric value that represents percentage of how much part is of total.
 */
export function calculatePercentage(part, total) {
  // Check for invalid inputs: part is 0 or any number is negative
  if (part === 0 || part < 0 || total <= 0) {
    return 0;
  }

  // Calculate the percentage
  const percentage = (part / total) * 100;

  // Return the percentage value formatted to 2 numbers after comma
  return percentage.toFixed(2);
}

/**
 * This function takes in a number that represents temperature and a string that shows which temperature
 * unit is being used, then it returns the value in Kelvin
 * @function
 * @memberof Utilities.Helpers
 * @param {number} value - This value represents numeric temperature value
 * @param {string} unit - This is a single character string that represets temperature unit
 * @returns {number} This returns a numeric temperature representation in Kelvin
 */
export function convertToKelvin(value, unit) {
  let kelvin;
  switch (unit.toUpperCase()) {
    case "K":
      kelvin = value;
      break;
    case "C":
      kelvin = value + 273.15;
      break;
    case "F":
      kelvin = (value - 32) * (5 / 9) + 273.15;
      break;
    default:
      throw new Error(
        "Invalid temperature unit. Use 'K' for Kelvin, 'C' for Celsius, or 'F' for Fahrenheit."
      );
  }
  return kelvin;
}

/**
 * This function handles file reading into manageable format. If file cannot be readed for some reason, it rejects
 * with an error. This is different from fileProcessingLogic function in a way that it generates no warnings and
 * leaves with the caller of this function to handle errors related to file loading.
 * @function
 * @memberof Utilities.Helpers
 * @param {file} file - File loaded.
 * @returns {Promise} - Returns a promise that resolves in file reading response.
 */
export function simpleReadFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileData = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        content: event.target.result,
      };
      resolve(fileData);
    };
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Error reading the file."));
    };
    if (
      file.name.endsWith(".rfm") ||
      file.name.endsWith(".RFM") ||
      file.name.endsWith(".rfv") ||
      file.name.endsWith(".RFV")
    ) {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }
  });
}

/**
 * This function takes in unit and value and returns that value in cm^-1
 * @function
 * @memberof Utilities.Helpers
 * @param {object} unit - This variable represents the units in which the value is given
 * @param {number} value - This is the numeric value that we want to change to cm^-1
 * @returns {number} This is value translated into cm^-1
 */
export function anyUnitToCm1(unit, value) {
  let newValue = value;

  switch (unit.name) {
    case "cm^-1":
      break;
    case "eV":
      newValue = eVTocm1(newValue);
      break;
    case "THz":
      newValue = THzTocm1(newValue);
      break;
    case "nm":
      newValue = nmTocm1(newValue);
      break;
    case "microns":
      newValue = micronsTocm1(newValue);
      break;
    case "ps":
      newValue = psTocm1(newValue);
      break;
    default:
      break;
  }

  return newValue;
}

/**
 * This function takes value in cm^-1 and returns that value in unit requested
 * @function
 * @memberof Utilities.Helpers
 * @param {object} unit - This variable represents the units we need to convert to
 * @param {number} value - This is the numeric value in cm^-1
 * @returns {number} This is value translated into requested units
 */
export function cm1ToAnyUnit(unit, value) {
  let newValue = value;

  switch (unit.name) {
    case "cm^-1":
      break;
    case "eV":
      newValue = cm1ToeV(newValue);
      break;
    case "THz":
      newValue = cm1ToTHz(newValue);
      break;
    case "nm":
      newValue = cm1Tonm(newValue);
      break;
    case "microns":
      newValue = cm1toMicrons(newValue);
      break;
    case "ps":
      newValue = cm1tops(newValue);
      break;
    default:
      break;
  }

  return newValue;
}

/**
 * This function takes in information about models and which model-quantity pair we want to check and returns
 * a name associated to that quantity.
 * @function
 * @param {Array.<object>} modelList - This is a list of all the models used in app at the moment
 * @param {number} modelId - This number represents ID of the model we wan to check
 * @param {number} quantity - This number represents quantity of the model we are checking
 * @param {boolean} noProcess - This flag decides if we want to do string processing before returning
 * @returns {string} This returns quantity name
 */
export function getQuantityNameFromModels(
  modelList,
  modelId,
  quantity,
  noProcess = false
) {
  const model = getModelById(modelId, modelList);

  if (model && hasProperty(model, "outputs") && model.outputs.length > 0) {
    for (let i = 0; i < model.outputs.length; i++) {
      const output = model.outputs[i];

      if (output.reffit_id === quantity) {
        if (noProcess) {
          return output.name;
        } else {
          return processSymbols(output.name);
        }
      }
    }
  }

  return "no_outputs";
}

/**
 * Adjusts modal position and size to ensure it fits within the viewport.
 * @function
 * @param {Object} desiredPosition - The desired position with optional 'top', 'left', or 'right' ({ top, left, right }).
 * @param {Object} size - Initial width and height of the modal ({ width, height }).
 * @returns {Object} - Adjusted position and size including 'top' and either 'left' or 'right', formatted with "px".
 */
export function adjustModalPositionAndSize(desiredPosition, size) {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  let { top, left, right } = desiredPosition;
  let { width, height } = size;

  // Adjust size to fit the viewport while not going below 70% of the original size
  let adjustedWidth = Math.min(width, viewportWidth);
  let adjustedHeight = Math.min(height, viewportHeight);

  adjustedWidth = Math.max(adjustedWidth, width * 0.7);
  adjustedHeight = Math.max(adjustedHeight, height * 0.7);

  // Position adjustments
  if (typeof right !== "undefined") {
    if (right !== "auto") {
      right = Math.max(
        0,
        Math.min(viewportWidth - adjustedWidth, parseInt(right, 10))
      );
      right = `${right}px`;
    }
  }
  if (typeof left !== "undefined") {
    if (left !== "auto") {
      left = Math.max(
        0,
        Math.min(viewportWidth - adjustedWidth, parseInt(left, 10))
      );
      left = `${left}px`;
    }
  }
  if (typeof right === "undefined" && typeof left === "undefined") {
    // Default to left 0px if neither left nor right is defined
    left = "0px";
  }

  top = Math.max(
    0,
    Math.min(viewportHeight - adjustedHeight, parseInt(top, 10))
  );
  top = `${top}px`;

  // Ensure string values with 'px' for dimensions
  adjustedWidth = `${adjustedWidth}px`;
  adjustedHeight = `${adjustedHeight}px`;

  return { top, right, left, width: adjustedWidth, height: adjustedHeight };
}

/**
 * Converts viewport width units (vw) to pixels.
 * @function
 * @param {number} vw - The number of viewport width units.
 * @returns {number} - The corresponding pixel value.
 */
export function vwToPixels(vw) {
  return vw * (window.innerWidth / 100);
}

/**
 * Converts viewport height units (vh) to pixels.
 * @function
 * @param {number} vh - The number of viewport height units.
 * @returns {number} - The corresponding pixel value.
 */
export function vhToPixels(vh) {
  return vh * (window.innerHeight / 100);
}

/**
 * Converts percentage of parent width to pixels.
 * @function
 * @param {number} percent - The percentage of the parent element's width.
 * @param {number} parentWidth - The parent element's width in pixels.
 * @returns {number} - The corresponding pixel value.
 */
export function percentWidthToPixels(percent, parentWidth) {
  return (percent / 100) * parentWidth;
}

/**
 * Converts percentage of parent height to pixels.
 * @function
 * @param {number} percent - The percentage of the parent element's height.
 * @param {number} parentHeight - The parent element's height in pixels.
 * @returns {number} - The corresponding pixel value.
 */
export function percentHeightToPixels(percent, parentHeight) {
  return (percent / 100) * parentHeight;
}

/**
 * This function takes in a model and puts its data into csv and downloads it.
 * @function
 * @param {object} model - This is a model that we want to download.
 */
export function constructAndDownloadCSV(model) {
  const csvRows = [];
  const headers = ["Name", "Recurring", "Unit", "Value"];
  csvRows.push(headers.join(","));

  for (let i = 0; i < model.modelParams.length; i++) {
    const parameter = model.modelParams[i];
    if (parameter.recuring === 0) {
      csvRows.push(`${parameter.name},0,,${parameter.value}`);
    }
  }

  for (let i = 0; i < model.recParams.length; i++) {
    const parameterRow = model.recParams[i];
    for (let j = 0; j < parameterRow.length; j++) {
      const parameter = parameterRow[j];
      csvRows.push(`${parameter.name},${i + 1},cm^-1,${parameter.value}`);
    }
  }

  const joinedRows = csvRows.join("\n");

  downloadCSV(joinedRows, model.displayName);
}

/**
 * Triggers a download of a CSV file containing the provided data.
 * @function
 * @param {Array.<object>} data - The array of objects to be converted into CSV format. Each object in the array should have the same structure.
 * @returns - Initiates a download action but does not return any value.
 */
export function downloadCSV(csvData, name) {
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}.csv`);
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

/**
 * Converts an ArrayBuffer to a base64 encoded string.
 * @function
 * @param {ArrayBuffer} buffer - The ArrayBuffer to be converted to base64 format.
 * @returns {string} - The base64 encoded string representing the binary data.
 */
export const arrayBufferToBase64 = (buffer) => {
  // Create a binary string from the ArrayBuffer
  let binary = "";
  const bytes = new Uint8Array(buffer);

  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  // Encode the binary string to base64
  return window.btoa(binary);
};

/**
 * This function takes in and id list of models with supporting data and returns a dictionary of model and quantity pairs
 * @function
 * @param {Array.<number>} idList - This is a list of ids that we want to generate a dictionary for
 * @param {object} rangesForModels - This is a dictionary for what model and what quantity has what ranges
 * @param {Array.<object>} modelData - This is all the models contained in the app with their data
 * @returns {object} Returns an object with models that were requested and quantity pairs
 */
export function generateModelsRangesQuantPairsForModelDist(
  idList,
  rangesForModels,
  modelData
) {
  let models = [];
  let quantityPairs = [];

  for (let i = 0; i < idList.length; i++) {
    const idToFind = idList[i];
    const foundModel = getModelById(idToFind, modelData);

    if (hasProperty(rangesForModels, idToFind)) {
      const allQuantities = Object.keys(rangesForModels[idToFind]);
      for (let k = 0; k < allQuantities.length; k++) {
        const quantity = allQuantities[k];
        if (!models.some((model) => model.FE_ID === foundModel.FE_ID)) {
          models.push(foundModel);
        }
        quantityPairs.push({
          modelId: foundModel.FE_ID,
          quantity: parseInt(quantity),
        });
      }
    }
  }

  return {
    models,
    quantityPairs,
  };
}

/**
 * This function takes in a list of model ids and model data and returns those ids with added sub model ids.
 * @function
 * @param {Array.<number>} modelIdList - This is a list of ids that we want to check
 * @param {Array.<object>} modelData - These are all the models contained in the app with their data
 * @returns {Array.<number>} This function returns all the ids that requested array contained plus the ids of sub models.
 *  This array has no duplicates
 */
export function getAllIdsForModelsAndSubmodels(modelIdList, modelData) {
  const allIds = [];

  for (let i = 0; i < modelIdList.length; i++) {
    const id = modelIdList[i];
    const matchingModels = getModelsByIds([id], modelData);

    for (let j = 0; j < matchingModels.length; j++) {
      const model = matchingModels[j];

      addIdToList(model);
    }
  }

  const uniqueIds = [...new Set(allIds)];

  return uniqueIds;

  function addIdToList(model) {
    allIds.push(model.FE_ID);

    if (hasProperty(model, "subModels") && model.subModels.length > 0) {
      for (let i = 0; i < model.subModels.length; i++) {
        const subModel = model.subModels[i];

        addIdToList(subModel);
      }
    }
  }
}

/**
 * This function finds largest and smallest y axis value from given coordinates
 * @function
 * @param {Array.<object>} coordinates - This is an array of coordinates [{x: 1, y: 1}]
 * @returns {object} This function returns an object that contains information about largest and smallest y axis value.
 */
export function findMinMaxY(coordinates) {
  if (coordinates.length === 0) {
    return { minY: null, maxY: null };
  }

  let minY = coordinates[0].y;
  let maxY = coordinates[0].y;

  for (let i = 1; i < coordinates.length; i++) {
    if (coordinates[i].y < minY) {
      minY = coordinates[i].y;
    }
    if (coordinates[i].y > maxY) {
      maxY = coordinates[i].y;
    }
  }

  return { minY: minY, maxY: maxY };
}

/**
 * This function checks if the input string is a complete and valid number.
 * @function
 * @param {string} value - A string that is checked if it is a complete and valid number.
 * @returns {boolean} Returns true if the input string is a complete and valid number, false otherwise.
 */
export function isCompleteNumber(value) {
  // Regular expression to check if the string is a valid complete number
  const validNumberRegex = /^[-+]?\d+(\.\d+)?([Ee][+-]?\d+)?$/;

  // If the input matches the valid number regex, it is a complete number
  if (validNumberRegex.test(value)) {
    return true;
  }

  // Otherwise, it is incomplete
  return false;
}

export function removeModelsFromTablesById(modelList, idToRemove) {
  const updatedList = modelList.map((model) => {
    if (!model.canAdd) {
      return model;
    }

    let indexOfFoundRow = -1;

    for (let i = 0; i < model.recParams.length; i++) {
      const paramRow = model.recParams[i];
      if (
        paramRow.some(
          (param) => param.type === "Model" && param.FE_ID === idToRemove
        )
      ) {
        indexOfFoundRow = i;
        break;
      }
    }

    if (indexOfFoundRow !== -1) {
      model.recTableRows.splice(indexOfFoundRow, 1);
      model.recParams.splice(indexOfFoundRow, 1);
    }

    if (model.subModels.length > 0) {
      model.subModels = removeModelsFromTablesById(model.subModels, idToRemove);
    }

    return model;
  });

  return updatedList;
}

export function checkBool(value) {
  switch (value) {
    case 1:
      return true;
    case 0:
      return false;
    default:
      return value;
  }
}

export function produceListOfExchangeableModels(
  modelReffitId,
  modelId,
  modelData
) {
  const currentModelIdChain = getUpdatedUniqueIds([modelId], modelData);

  const foundMembers = [];

  const recordedIds = [];

  const checkSubModels = (refId, models) => {
    for (let i = 0; i < models.length; i++) {
      const subModel = models[i];

      if (
        subModel.reffitID === refId &&
        !recordedIds.some((id) => id === subModel.FE_ID)
      ) {
        foundMembers.push({
          title: subModel.displayName,
          FE_ID: subModel.FE_ID,
        });

        recordedIds.push(subModel.FE_ID);
      }

      if (subModel.subModels.length > 0) {
        checkSubModels(refId, subModel.subModels);
      }
    }
  };

  for (let i = 0; i < modelData.length; i++) {
    const model = modelData[i];

    if (currentModelIdChain.some((chainId) => chainId === model.FE_ID)) {
      continue;
    }

    if (
      model.reffitID === modelReffitId &&
      !recordedIds.some((id) => id === model.FE_ID)
    ) {
      foundMembers.push({
        title: model.displayName,
        FE_ID: model.FE_ID,
      });

      recordedIds.push(model.FE_ID);
    }

    if (model.subModels.length > 0) {
      checkSubModels(modelReffitId, model.subModels);
    }
  }

  return foundMembers;
}

export function djb2Hash(str) {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); // hash * 33 + c
  }
  return hash >>> 0; // Ensure positive integer
}

/**
 * This function takes in a list of model FE_IDs and checks which of those models are contained in graphs, then
 * it returns a lsit of pairs of those contained models with their quantities.
 * @param {Array.<number>} modelsIds - This array contains FE_IDs of the models that interest us in graphs
 * @param {Array.<object>} graphs - This is current graph array
 * @returns This function returns a list of pairs of model ids and their quantities, no duplicates.
 */
export function checkModelsInGraphs(modelsIds, graphs) {
  const allMergedModels = [];
  let dictionary = {};
  const pairList = [];

  for (let i = 0; i < graphs.length; i++) {
    const graph = graphs[i];

    allMergedModels.push(...graph.containedModels);
  }

  for (let i = 0; i < allMergedModels.length; i++) {
    const modelEntry = allMergedModels[i];

    if (modelsIds.some((id) => id === modelEntry.modelId)) {
      if (hasProperty(dictionary, modelEntry.modelId)) {
        const newQuantList = [
          ...new Set([...dictionary[modelEntry.modelId], modelEntry.quantity]),
        ];
        dictionary = {
          ...dictionary,
          [modelEntry.modelId]: newQuantList,
        };
      } else {
        dictionary = {
          ...dictionary,
          [modelEntry.modelId]: [modelEntry.quantity],
        };
      }
    }
  }

  const containedModels = Object.keys(dictionary);
  const parsedModelIds = [];

  // We are doing this instead of just returning all merged models is because we avoid duplicate pairs and we get
  // only models that we asked for.
  for (let i = 0; i < containedModels.length; i++) {
    const modelId = parseInt(containedModels[i]);

    parsedModelIds.push(modelId);

    for (let j = 0; j < dictionary[modelId].length; j++) {
      const quantity = dictionary[modelId][j];

      pairList.push({ modelId: modelId, quantity: quantity });
    }
  }

  return {
    pairs: pairList,
    modelList: parsedModelIds,
  };
}

export function checkIfAllowedActionRequest(message) {
  if (
    hasProperty(message, "stopfit") ||
    hasProperty(message, "stop_task") ||
    hasProperty(message, "pause_next_iteration") ||
    hasProperty(message, "stop_pause")
  ) {
    return true;
  }
  return false;
}

/**
 * Converts a positive real number to its log10 exponent.
 *
 * For example:
 *   - normalToLog(1)   -> 0
 *   - normalToLog(25)  -> ~1.39794
 *   - normalToLog(100) -> 2
 *
 * @param {number} value A positive number.
 * @return {number} The base-10 log exponent.
 */
export function normalToLog(value) {
  if (value <= 0) {
    throw new Error("Value must be greater than 0 for a log scale.");
  }
  return Math.log10(value);
}

/**
 * Converts a base-10 log exponent to a normal (linear) number.
 *
 * Examples:
 *   - logToNormal(0)      -> 1
 *   - logToNormal(1)      -> 10
 *   - logToNormal(-1)     -> 0.1
 *   - logToNormal(1.39794)-> ~25
 *
 * @param {number} logValue The base-10 log exponent.
 * @return {number} The corresponding linear (normal) value.
 */
export function logToNormal(logValue) {
  return Math.pow(10, logValue);
}

export function checkIfAxisYIsLog(layout) {
  try {
    if (layout.yaxis.type === "log") {
      return true;
    }
    return false;
  } catch (_) {
    return false;
  }
}
