import React from "react";
import "./legal.scss";

function PrivacyPage() {
  return (
    <div className="legalPage">
      <h1>Privacy Statement for Alpha Testers</h1>

      <div className="section">
        <h2>1. Introduction</h2>
        <p>
          Welcome to the Speqqle Alpha Testing Program. We are committed to
          protecting your privacy and handling your personal data in compliance
          with the General Data Protection Regulation (GDPR) and Swiss data
          protection laws. This Privacy Statement explains how we collect, use,
          and safeguard your personal data during your participation in the
          Alpha Testing Program.
        </p>
        <p>
          Before you participate in the Alpha Testing Program, you will be
          asked, on the alpha testing agreement page, to provide your explicit
          consent to the collection, use, and processing of your personal data
          as described in this Privacy Statement. You may withdraw your consent
          at any time by contacting us at&nbsp;
          <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>
        </p>
      </div>

      <div className="section">
        <h2>2. Data Controller</h2>
        <p>
          The data controller responsible for processing your personal data in
          connection with the Alpha Testing Program is:
        </p>
        <p>
          <strong>Speqqle SA</strong>
          <br />
          Route de la Galaise 34
          <br />
          1228, Plan-les-Ouates, Switzerland <br />
          Email:&nbsp;
          <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>
        </p>
        <p>
          If you have any questions or concerns regarding this Privacy Statement
          or the handling of your personal data, please contact us at the email
          address provided above.
        </p>
      </div>

      <div className="section">
        <h2>3. Data We Collect</h2>
        <ul>
          <li>
            <strong>Identity Data</strong>: Your full name.
          </li>
          <li>
            <strong>Contact Data</strong>: Your email address.
          </li>
          <li>
            <strong>Feedback and Testing Data</strong>: Any feedback, reports,
            and bug submissions you provide while using the Speqqle platform.
          </li>
          <li>
            <strong>Technical Data</strong>: Information about how you use the
            platform, including activity logs, usage data, and device
            information.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>4. Purpose and Legal Basis for Processing</h2>
        <ul>
          <li>
            <strong>Facilitation of Testing Activities</strong>: To provide
            access to the Speqqle platform and enable you to participate in the
            Alpha Testing Program.
          </li>
          <li>
            <strong>Communication</strong>: To contact you with instructions,
            updates, and requests for feedback related to the Alpha Testing
            Program.
          </li>
          <li>
            <strong>Product Improvement</strong>: To analyze your feedback,
            testing reports, and usage data to improve the performance,
            functionality, and user experience of the Speqqle platform.
          </li>
          <li>
            <strong>Compliance with Legal Obligations</strong>: To comply with
            applicable laws and regulations, if required.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>5. How We Use Your Data</h2>
        <ul>
          <li>
            To identify you as an Alpha Tester and provide access to the Speqqle
            platform.
          </li>
          <li>
            To communicate important updates, timelines, and deliverables.
          </li>
          <li>
            To process the feedback, error reports, and suggestions you submit.
          </li>
          <li>
            To analyze testing data and improve our products and services.
          </li>
        </ul>
        <p>
          Your feedback will be anonymized wherever possible. If your personal
          data is necessary to address specific feedback or issues, we will
          ensure it is used solely for that purpose.
        </p>
      </div>

      <div className="section">
        <h2>6. Data Sharing and Disclosure</h2>
        <p>
          We do not sell, trade, or rent your personal data to third parties.
          However, we may share your data with trusted service providers or
          partners who assist us in operating our platform or conducting testing
          activities. These third parties are contractually obligated to protect
          your data and use it only in accordance with our instructions.
        </p>
        <p>
          We may disclose your data if required by law, regulation, legal
          process, or governmental request.
        </p>
      </div>

      <div className="section">
        <h2>7. Data Retention</h2>
        <p>
          We will retain your personal data for as long as necessary to fulfill
          the purposes outlined in this Privacy Statement, unless a longer
          retention period is required by law. Data collected during the Alpha
          Testing Program will be deleted or anonymized at the end of the
          testing period, unless you request earlier deletion or consent to
          continued processing.
        </p>
        <p>
          Upon completion of the Alpha Testing Program, we will either delete
          your personal data or anonymize it to ensure that it cannot be linked
          to you personally. If you wish to have your data deleted before the
          end of the testing period, you may request deletion by contacting us
          at&nbsp;
          <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>.
        </p>
      </div>

      <div className="section">
        <h2>8. Your Rights</h2>
        <ul>
          <li>
            <strong>Right to Access</strong>: You may request a copy of the
            personal data we hold about you.
          </li>
          <li>
            <strong>Right to Rectification</strong>: You may request correction
            of inaccurate or incomplete personal data.
          </li>
          <li>
            <strong>Right to Erasure</strong>: You may request the deletion of
            your personal data when it is no longer necessary for the purpose it
            was collected.
          </li>
          <li>
            <strong>Right to Restrict Processing</strong>: You may request the
            restriction of processing if you contest the accuracy of your
            personal data, object to its processing, or believe its processing
            is unlawful.
          </li>
          <li>
            <strong>Right to Data Portability</strong>: You may request to
            receive your personal data in a structured, commonly used, and
            machine-readable format and transfer it to another controller.
          </li>
          <li>
            <strong>Right to Object</strong>: You may object to the processing
            of your personal data where we rely on a legitimate interest as our
            legal basis.
          </li>
          <li>
            <strong>Right to Withdraw Consent</strong>: You may withdraw your
            consent at any time, although this may limit your ability to
            continue participating in the Alpha Testing Program.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>9. Data Security</h2>
        <p>
          We are committed to ensuring the security and confidentiality of your
          personal data. To protect your personal data from unauthorized access,
          disclosure, alteration, or destruction, we have implemented
          appropriate technical and organizational measures in accordance with
          GDPR and industry best practices. Our security measures include, but
          are not limited to, the following:
        </p>

        <ul>
          <li>
            <strong>Data Encryption</strong>: Personal data is encrypted in
            transit using industry-standard encryption protocols (e.g., TLS for
            data in transit and AES-256 for data at rest).
          </li>
          <li>
            <strong>Password Protection</strong>: Passwords are hashed and
            salted before storage to prevent unauthorized access to accounts,
            even in the event of a data breach.
          </li>
          <li>
            <strong>Data Minimization and Pseudonymization</strong>: Where
            possible, personal data is pseudonymized or anonymized to reduce
            risk in the event of unauthorized access.
          </li>
          <li>
            <strong>Access Control</strong>: Access to personal data is limited
            to authorized personnel only and is based on the principle of least
            privilege. Access is managed through role-based access controls
            (RBAC).
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>10. Data Transfers</h2>
        <p>
          We prioritize the security and privacy of your personal data. Your
          data will be stored and processed within the European Economic Area
          (EEA) and will not be transferred outside the EEA. In the event that a
          transfer outside the EEA becomes necessary, we will ensure that
          appropriate safeguards are in place, such as the use of Standard
          Contractual Clauses (SCCs) or reliance on adequacy decisions, to
          guarantee that your data receives the same level of protection as
          required by GDPR.
        </p>
      </div>

      <div className="section">
        <h2>11. Cookies and Tracking Technologies</h2>
        <p>
          We respect your privacy and are committed to ensuring a transparent
          and user-friendly experience. We are pleased to inform you that the
          Speqqle platform does not use cookies or employ any tracking
          technologies to monitor your activity. This means we do not collect,
          store, or analyze your browsing behavior while using the platform.
        </p>
        <p>
          By avoiding cookies and tracking technologies, we prioritize your
          privacy and reduce the amount of personal data collected during your
          use of our services.
        </p>
      </div>

      <div className="section">
        <h2>12. Children&apos;s Privacy</h2>
        <p>
          The Alpha Testing Program is intended for individuals who are at least
          18 years old. We do not knowingly collect personal data from
          individuals under 18. If we become aware that we have inadvertently
          collected personal data from a child, we will delete it immediately.
        </p>
      </div>

      <div className="section">
        <h2>13. Changes to this Privacy Statement</h2>
        <p>
          We may update this Privacy Statement from time to time. If significant
          changes are made, we will notify you via email or through the
          platform.
        </p>
      </div>

      <div className="section">
        <h2>14. Contact Us</h2>
        <p>
          For any questions, please contact us at&nbsp;
          <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPage;
