import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Copyright from "../components/Copyright";
import AppBar from "../components/actionBar/AppBar";
import { GraphContext } from "../context/GraphContext";
import { WebSocketContext } from "../context/WebSocketContext";
import { DashboardContext } from "../context/DashboardContext";
import { FileHandlingContext } from "../context/FileHandlingContext";
import { GeneralContext } from "../context/GeneralContext";
import LeftSide from "../components/leftSide/LeftSide";
import RightSide from "../components/rightSide/RightSide";
import GraphContainer from "../components/middle/GraphContainer";
import "./dashboard.scss";
import {
  // getListOfTermsToUpdate,
  performChi2Message,
  updatedChi2,
} from "../components/rightSide/fit/fitLogic";
import { ReadyState } from "react-use-websocket";
import { AuthContext } from "../context/AuthContext";
import {
  checkBool,
  cleanModelsFromUnusedCurves,
  downloadStringTxtFile,
  findBestMatchingCurve,
  generateModelsRangesQuantPairsForModelDist,
  generateWarningObject,
  getRangesForModelsFromGraphs,
  getReturnedCurveDictionary,
  hasProperty,
  isDeepEqual,
  produceCurveObject,
  replaceModelPlotData,
  updateOrAddCurve,
} from "../utils/helpers";
import StickyFileWindow from "../components/leftSide/Files/StickyFileWindow";
import { isClickedOutside } from "../components/leftSide/Files/FileUploadLogic";
import BroadcastModal from "../components/commonComponents/BroadcastModal";
import Modal from "react-modal";
import SingleFileReprocess from "../components/leftSide/Files/fileProcessing/SingleFileReprocess";
import {
  createModelFromParamList,
  deepCopy,
  getModelById,
  replaceModelById,
} from "../components/leftSide/Models/modelLogic";
import WindowSelector from "../components/windowSelector/WindowSelector";
import Loop from "../components/middle/loop/Loop";
import FitStopWindow from "../components/middle/FitStopWindow";
import LoopStopWindow from "../components/middle/loop/LoopStopWindow";
import {
  containedModelIDsInGraphs,
  createModelDistPayload,
  generatePlotData,
} from "../components/middle/graphLogic";
// import { getUpdatedUniqueIds } from "../components/rightSide/parameters/parameterLogic";

//this is the main function of the whole application
export default function Dashboard() {
  const navigate = useNavigate();
  const { selectedFiles, setSelectedFiles, updateSelected } =
    useContext(FileHandlingContext);
  const {
    chi2Terms,
    setChi2Terms,
    graphs,
    setGraphs,
    selectedChi2Terms,
    requestedFitModel,
    setRequestedFitModel,
    updatedModelFE_IDs,
    setUpdatedModelFE_IDs,
  } = useContext(GraphContext);
  const {
    lastJsonMessage,
    readyState,
    sendMessage,
    authSent,
    setAuthSent,
    isFitOngoing,
    isLoopOngoing,
    setIsLoopOngoing,
    setIsFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
    sendJsonMessage,
    clearMessage,
    lastMessage,
    setAuthConfirmed,
  } = useContext(WebSocketContext);
  const {
    fitIteration,
    setFitIteration,
    setWarnings,
    setNewWarningCount,
    cleanupNeeded,
    setCleanupNeeded,
    modelData,
    setModelData,
    // showLeftPanel,
    // setShowLeftPanel,
    fileCallFromGraph,
    setFileCallFromGraph,
    fileToChange,
    setFileToChange,
    uploadedFiles,
    setUploadedFiles,
    setTotalActiveParams,
    chi2ReqAllowed,
    setChi2ReqAllowed,
    chi2QueRef,
    loopOpen,
    // setLoopOpen,
    outputsOpen,
    // setOutputsOpen,
    // requestedVdf,
    // setRequestedVdf,
    loopIteration,
    setLoopIteration,
    listOfLoopModelsToSave,
    setListOfLoopModelsToSave,
    listOfLoopCurvesToSave,
    setListOfLoopCurvesToSave,
    loopModelFilesCustomName,
    loopCurveFilesCustomName,
    valueGroups,
    setValueGroups,
    pauseOnNextLoop,
    setPauseOnNextLoop,
    loopPaused,
    setLoopPaused,
  } = useContext(DashboardContext);
  const { authToken, isAuthReady, currentUser } = useContext(AuthContext);
  const {
    setBroadcastMessage,
    limitedToast,
    limitedWarningToast,
    limitedSucessToast,
    recordedErrorLog,
  } = useContext(GeneralContext);
  const [broadcastOpen, setBroadcastOpen] = useState(false);
  const [reprocessOpen, setReprocessOpen] = useState(false);
  const [fileToReprocess, setFileToReprocess] = useState(null);

  // We need state and ref in this case, because ref updates are not caught in StickyFileWindow immediately
  // and state change is not visible in useEffect where we have click outside logic
  const [fromGraphState, setfromGraphState] = useState(false);
  const [updateChiTerms, setUpdateChiTerms] = useState(false);
  const fromGraph = useRef(false);
  const dashboardRef = useRef();
  const updateIDWaitList = useRef([]);
  const updatePending = useRef(false);
  const requestedFitModelsLocal = useRef([]);
  const localValueGroups = useRef(valueGroups);

  useEffect(() => {
    requestedFitModelsLocal.current = requestedFitModel;
  }, [requestedFitModel]);

  useEffect(() => {
    if (!isDeepEqual(valueGroups, localValueGroups.current)) {
      localValueGroups.current = valueGroups;
    }
  }, [valueGroups]);

  useEffect(() => {
    if (updatedModelFE_IDs.length > 0) {
      const idsToUpdate = containedModelIDsInGraphs(updatedModelFE_IDs, graphs);
      if (!isFitOngoing && !isLoopOngoing) {
        handleMultipleIdUpdate(idsToUpdate);

        if (chi2Terms.length > 0 && selectedChi2Terms.length > 0) {
          const termsToSend = chi2Terms.filter((term) => {
            if (
              selectedChi2Terms.some((id) => id === term.id) &&
              term.weight > 0
            ) {
              return true;
            } else {
              return false;
            }
          });

          if (termsToSend.length > 0) {
            performChi2Message(
              termsToSend,
              modelData,
              uploadedFiles,
              sendJsonMessage,
              currentUser,
              recordedErrorLog,
              chi2ReqAllowed,
              setChi2ReqAllowed,
              chi2QueRef
            );
          }
        }
      }

      setUpdatedModelFE_IDs([]);
    }
  }, [updatedModelFE_IDs]);

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "Broadcast")) {
          setBroadcastMessage(lastJsonMessage.Broadcast);
          setBroadcastOpen(true);
          clearMessage();
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json message broadcast useEffect has failed: ",
        error
      );
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    try {
      if (fileToChange !== null) {
        const foundFile = uploadedFiles.find(
          (uFile) => uFile.ID === fileToChange
        );
        if (foundFile !== undefined) {
          setFileToReprocess(foundFile);
          setReprocessOpen(true);
        }
      }
    } catch (error) {
      recordedErrorLog("'fileToChange' useEffect has failed: ", error);
    }
  }, [fileToChange]);

  useEffect(() => {
    fromGraph.current = false;
    setfromGraphState(false);
  }, [selectedFiles]);

  useEffect(() => {
    if (fileCallFromGraph && !fromGraph.current) {
      fromGraph.current = true;
      setfromGraphState(true);
    }
  }, [fileCallFromGraph]);

  useEffect(() => {
    if (fileCallFromGraph) {
      setFileCallFromGraph(false);
    }
  }, [fileCallFromGraph]);

  function resetSelection() {
    setSelectedFiles([]);
    fromGraph.current = false;
    setfromGraphState(false);
  }

  useEffect(() => {
    function handleMouseDownOutside(event) {
      if (
        !fromGraph.current &&
        dashboardRef.current &&
        isClickedOutside(event)
      ) {
        setSelectedFiles([]);
      }
    }

    document.addEventListener("mousedown", handleMouseDownOutside);

    return () => {
      document.removeEventListener("mousedown", handleMouseDownOutside);
    };
  }, [dashboardRef]);

  useEffect(() => {
    if (isAuthReady) {
      if (authToken === "") {
        navigate("/");
      }
    }
  }, [isAuthReady]);

  useEffect(() => {
    if (ReadyState.OPEN === readyState && !authSent) {
      sendMessage("Token: " + authToken);
      setAuthSent(true);
    }
  }, [readyState]);

  useEffect(() => {
    if (lastMessage === "websocket connection autheticated") {
      setAuthConfirmed(true);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (chi2ReqAllowed && chi2QueRef.current.length > 0) {
      performChi2Message(
        [],
        modelData,
        uploadedFiles,
        sendJsonMessage,
        currentUser,
        recordedErrorLog,
        chi2ReqAllowed,
        setChi2ReqAllowed,
        chi2QueRef
      );
    }
  }, [chi2ReqAllowed]);

  // We are cleaning up unused curves from models here
  useEffect(() => {
    try {
      if (cleanupNeeded) {
        const neededRangeDictionary = getRangesForModelsFromGraphs(graphs);

        const cleanedModels = cleanModelsFromUnusedCurves(
          modelData,
          neededRangeDictionary
        );

        setModelData(cleanedModels);

        setCleanupNeeded(false);
      }
    } catch (error) {
      recordedErrorLog("Cleanup needed useEffect failure: ", error);
    }
  }, [cleanupNeeded]);

  // We do this chi2 update here instead of in the fit, because it takes a bit of time to unlock the websocket
  // to allow other requests straight after fit.
  useEffect(() => {
    // if (updateChiTerms && chiTermsToUpdate.current.length > 0) {
    if (updateChiTerms) {
      if (selectedChi2Terms.length > 0) {
        const termsToSend = chi2Terms.filter((term) => {
          if (
            selectedChi2Terms.some((id) => id === term.id) &&
            term.weight > 0
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (termsToSend.length > 0) {
          performChi2Message(
            termsToSend,
            modelData,
            uploadedFiles,
            sendJsonMessage,
            currentUser,
            recordedErrorLog,
            chi2ReqAllowed,
            setChi2ReqAllowed,
            chi2QueRef
          );
        }
      }

      // chiTermsToUpdate.current = [];
      setUpdateChiTerms(false);
    }
  }, [updateChiTerms]);

  useEffect(() => {
    try {
      if (
        lastJsonMessage != null &&
        hasProperty(lastJsonMessage, "Error") &&
        lastJsonMessage.Error.length > 0
      ) {
        for (let i = 0; i < lastJsonMessage.Error.length; i++) {
          const error = lastJsonMessage.Error[i];
          const idRequest = hasProperty(lastJsonMessage, "requestID")
            ? lastJsonMessage.requestID
            : "none";
          limitedToast(error.message);
          generateWarningObject(
            error.message,
            2,
            setWarnings,
            setNewWarningCount,
            error.reportable,
            idRequest
          );
        }
        if (isFitOngoing) {
          setIsFitOngoing(false);
        }
      }
      if (
        lastJsonMessage != null &&
        hasProperty(lastJsonMessage, "Warning") &&
        lastJsonMessage.Warning.length > 0
      ) {
        for (let i = 0; i < lastJsonMessage.Warning.length; i++) {
          const warning = lastJsonMessage.Warning[i];
          limitedWarningToast(warning);
          generateWarningObject(warning, 1, setWarnings, setNewWarningCount);
        }
      }
    } catch (error) {
      recordedErrorLog("Error and Warning handling useEffect failure: ", error);
    }
  }, [lastJsonMessage]);

  function handleChi2Message(jsonMessage, fit = true) {
    const newTerms = fit
      ? jsonMessage.Fit.chi2.chi2s
      : jsonMessage.Loop.chi2.chi2s;
    const newTotalAp = fit
      ? jsonMessage.Fit.chi2.nActiveParameters
      : jsonMessage.Loop.chi2.nActiveParameters;

    setTotalActiveParams(newTotalAp);

    const updatedTerms = updatedChi2(newTerms, chi2Terms);

    setChi2Terms(updatedTerms);
  }

  function handleVdfResponse(lastMessage, requestedVdfs) {
    try {
      let modelsCopy = deepCopy(modelData);
      for (let i = 0; i < requestedVdfs.length; i++) {
        const requestedModelVDF = requestedVdfs[i];
        const foundVdf = lastMessage.Model.SendModel.find(
          (model) => model.modelid === requestedModelVDF.id
        );

        if (foundVdf !== null) {
          const modelToUpdate = deepCopy(
            getModelById(requestedModelVDF.id, modelsCopy)
          );

          modelToUpdate.vdfData = {
            ...modelToUpdate.vdfData,
            ...foundVdf.vdf,
          };
          if (hasProperty(requestedModelVDF, "gridData")) {
            modelToUpdate.vdfData.gridData = requestedModelVDF.gridData;
          }
          if (hasProperty(requestedModelVDF, "gridlinear")) {
            modelToUpdate.vdfData.gridlinear = requestedModelVDF.gridlinear;
          }

          modelsCopy = replaceModelById(
            modelsCopy,
            modelToUpdate,
            requestedModelVDF.id
          );
        } else {
          console.warn(
            "VDF requested but not received for: ",
            requestedModelVDF[i]
          );
        }
      }

      setModelData(modelsCopy);

      // const allUpdatedIds = requestedVdfs.map((update) => update.id);
      // const termsToUpdate = getListOfTermsToUpdate(
      //   chi2Terms,
      //   getUpdatedUniqueIds(allUpdatedIds, modelsCopy)
      // );
      // if (termsToUpdate.length > 0) {
      if (chi2Terms.length > 0 && selectedChi2Terms.length > 0) {
        const termsToSend = chi2Terms.filter((term) => {
          if (
            selectedChi2Terms.some((id) => id === term.id) &&
            term.weight > 0
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (termsToSend.length > 0) {
          performChi2Message(
            termsToSend,
            modelsCopy,
            uploadedFiles,
            sendJsonMessage,
            currentUser,
            recordedErrorLog,
            chi2ReqAllowed,
            setChi2ReqAllowed,
            chi2QueRef
          );
        }
      }
      // }
    } catch (error) {
      recordedErrorLog("Vdf response handling error: ", error);
    }
  }

  // function handleVdfBinaryLoad(lastMessage) {
  //   try {
  //     console.log("last message: ", lastMessage);
  //   } catch (error) {
  //     recordedErrorLog(
  //       "There was a problem trying to handle binary vdf file load"
  //     );
  //   }
  // }

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "requestID")) {
          const requestData = retrieveRequestData(lastJsonMessage.requestID);
          if (requestData !== null) {
            switch (requestData.type) {
              case "chi2-message":
                handleChi2Message(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                setChi2ReqAllowed(true);
                break;
              case "vdf-request":
                handleVdfResponse(lastJsonMessage, requestData.requestedVdfs);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "binary-vdf-load":
                // handleVdfBinaryLoad(lastJsonMessage);
                handleVdfResponse(lastJsonMessage, requestData.requestedVdfs);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "loop-request":
                // handleVdfBinaryLoad(lastJsonMessage);
                handleLoopResponse(lastJsonMessage);
                break;
              case "multiple-id-dist-update":
                handleMultiIdDistUpdate(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "autofit":
                handleFitResponse(lastJsonMessage, true, false);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "fit-message":
                handleFitMessage(lastJsonMessage);
                break;
              default:
                break;
            }
          }
        } else {
          throw new Error("Request ID was not attached to the json message.");
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json message useEffect handler has failed: ",
        error
      );
    }
  }, [lastJsonMessage]);

  function handleMultiIdDistUpdate(jsonMessage) {
    try {
      // if (updatePending.current) {
      // We extract coordinate set from the message
      const coordSets = jsonMessage.Model.SendCurve;

      const dict = getReturnedCurveDictionary(coordSets);
      const modelIds = Object.keys(dict);
      let graphsCopy = deepCopy(graphs);
      let modelsCopy = deepCopy(modelData);

      // We loop through graphs in order to update them
      for (let i = 0; i < graphsCopy.length; i++) {
        const min = graphsCopy[i].layout.xaxis.range[0];
        const max = graphsCopy[i].layout.xaxis.range[1];

        // We loop through contained model ids in graphs to check which ones received updated data
        for (let j = 0; j < graphsCopy[i].containedModels.length; j++) {
          const modelQuantitySet = graphsCopy[i].containedModels[j];

          // We check if current model id from graph is contained in updates
          if (
            modelIds.some((key) => parseInt(key) === modelQuantitySet.modelId)
          ) {
            const bestCurve = findBestMatchingCurve(
              dict[modelQuantitySet.modelId][modelQuantitySet.quantity],
              min,
              max,
              modelQuantitySet.quantity
            );

            modelsCopy = modelsCopy.map((modelToChange) => {
              if (modelToChange.FE_ID === modelQuantitySet.modelId) {
                const modelToUpdate = modelToChange;

                modelToUpdate.curves = updateOrAddCurve(
                  modelToUpdate.curves,
                  bestCurve.curve
                );
                return modelToUpdate;
              } else {
                return modelToChange;
              }
            });

            graphsCopy[i].plotData = replaceModelPlotData(
              graphsCopy[i].plotData,
              bestCurve,
              modelQuantitySet.modelId,
              modelQuantitySet.quantity
            );
          }
        }
      }

      // localGraphs.current = graphsCopy;
      setGraphs(graphsCopy);
      setModelData(modelsCopy);

      if (updateIDWaitList.current.length > 0) {
        // handle ids from wait list here
        handleMultipleIdUpdate(updateIDWaitList.current);
        updateIDWaitList.current = [];
      }

      // We reset the pending update flag to false
      // THIS DOES NOT GET RESET ALWAYS, MAINLY ON SLOW PCS
      updatePending.current = false;
      // }
    } catch (error) {
      recordedErrorLog("Error handling new model curve response: ", error);
    }
  }

  const handleMultipleIdUpdate = (idList) => {
    if (idList.length > 0) {
      try {
        if (updatePending.current) {
          updateIDWaitList.current = [
            ...new Set([...updateIDWaitList.current, ...idList]),
          ];
        } else {
          // requestedModelsUpdate.current = idList;

          const rangesForModels = getRangesForModelsFromGraphs(graphs);

          const paramsForPayload = generateModelsRangesQuantPairsForModelDist(
            idList,
            rangesForModels,
            modelData
          );

          const payload = createModelDistPayload(
            paramsForPayload.models,
            rangesForModels,
            paramsForPayload.quantityPairs,
            currentUser
          );

          updatePending.current = true;
          sendJsonMessage(payload, { type: "multiple-id-dist-update" });
          // console.log("PAYLOAD FOR GRAPH UPDATE SENT: ", payload);
        }
      } catch (error) {
        recordedErrorLog("Multiple Id update failed: ", error);
      }
    }
  };

  const handleFitResponse = (
    response,
    isDone = true,
    requestChiUpdate = false
  ) => {
    if (hasProperty(response, "Error") && response.Error.length > 0) {
      requestedFitModelsLocal.current = [];
      setRequestedFitModel(requestedFitModelsLocal.current);
    } else if (requestedFitModelsLocal.current.length > 0) {
      try {
        const newTerms = response.Fit.chi2.chi2s;

        let updatedTerms = updatedChi2(newTerms, chi2Terms);

        const termsLeftToUpdate = [];

        const termsToUpdateAfterFit = [];

        updatedTerms.forEach((term) => {
          if (
            !newTerms.some((newTerm) => {
              return (
                term.modelId === newTerm.ModelID &&
                term.fileId === newTerm.DataID &&
                term.quantity === newTerm.Dist
              );
            })
          ) {
            termsLeftToUpdate.push(term);
          }
          if (requestChiUpdate) {
            if (hasProperty(term, "needsUpdate") && term.needsUpdate) {
              termsToUpdateAfterFit.push(term);
            }
          }
        });

        // if (requestChiUpdate && termsToUpdateAfterFit.length > 0) {
        //   chiTermsToUpdate.current = termsToUpdateAfterFit;
        // }

        if (termsLeftToUpdate.length > 0) {
          // FOR NOW NO ERROR, BUT LATER WE NEED TO RETRIEVE ALL THE TERMS WITH FIT, NOT JUST THE ONES FOR THE SENT
          // FILE

          // limitedToast("Chi2 retrieval error.");
          generateWarningObject(
            "Not all the Chi2 Terms were updated in request. Please report this to support.",
            1,
            setWarnings,
            setNewWarningCount
          );

          // If we perform chi 2 message here instead of throwing an error, we get into infinite request loop
          // if BE never returns that chi 2 due to an error
          // performChi2Message(
          //   termsLeftToUpdate,
          //   modelData,
          //   uploadedFiles,
          //   sendJsonMessage
          // );
        }

        setChi2Terms(updatedTerms);
      } catch (error) {
        recordedErrorLog(
          "There was an error retrieving chi2 values from fit message: ",
          error
        );
      }

      try {
        //Updating edges of the file
        const filesCopy = deepCopy(uploadedFiles);
        for (let i = 0; i < response.Fit.chi2.chi2s.length; i++) {
          const chi2 = response.Fit.chi2.chi2s[i];
          if (hasProperty(chi2, "autofitedge")) {
            for (let j = 0; j < filesCopy.length; j++) {
              if (filesCopy[j].ID === chi2.DataID) {
                const maxFromFit = chi2.autofitedge[1];
                const minFromFit = chi2.autofitedge[0];
                if (
                  !filesCopy[j].edges.some(
                    (edge) => edge.min === minFromFit && edge.max === maxFromFit
                  )
                ) {
                  filesCopy[j].edges.push({ min: minFromFit, max: maxFromFit });
                  filesCopy[j].edges = filesCopy[j].edges.filter(
                    (edge) => edge.min !== "" && edge.max !== ""
                  );
                }
              }
            }
          }
        }
        //Files edges updated from autofit, we can update the files now.
        setUploadedFiles(filesCopy);

        let remainingIDs = [...requestedFitModelsLocal.current];
        let copyOfModels = deepCopy(modelData);
        let copyOfGraphs = deepCopy(graphs);
        let updateModels = false;
        let updateGraphs = false;

        // This variable will check if there are any strange values from the BE for parameters that are not used in FE
        let strangeResults = false;

        // We are updating model parameters and curves separately, because when we make a fit request, more than
        // just requested curve model is returned - sub models don't necessarily require curve update, but their
        // parameter update needs to be taken into account

        // UPDATING MODEL PARAMETERS AND ROWS
        for (let i = 0; i < response.Model.SendModel.length; i++) {
          const modelDataFromResponse = response.Model.SendModel[i];

          // Check if requested model is actually received
          updateModels = true;

          // Find the model which needs updating
          const modelToUpdate = deepCopy(
            getModelById(modelDataFromResponse.modelid, copyOfModels)
          );

          if (
            hasProperty(modelDataFromResponse, "vdf") &&
            hasProperty(modelToUpdate, "vdfData")
          ) {
            modelToUpdate.vdfData = {
              ...modelToUpdate.vdfData,
              ...modelDataFromResponse.vdf,
            };
          }

          // Find non recuring params from model
          const nonRecParams = modelToUpdate.modelParams.filter(
            (param) => param.recuring == 0
          );

          // Get new parameters for the model, remove first entry, since it's not needed
          const modelParamsToUse = deepCopy(
            modelDataFromResponse.parameters.global
          );
          if (modelDataFromResponse.modeltype !== 0) {
            modelParamsToUse.shift(); // IF NOT A DL MODEL ONLY!
          }

          let recParamsForUpdate = [];
          const hasRec = hasProperty(modelToUpdate, "recTemplate");

          // From a model recuring parameter template, get a parameter with a smallest reffit id
          const smallestIDTemplateParam = hasRec
            ? modelToUpdate.recTemplate.params.reduce(
                (min, param) => (min.reffit_id < param.reffit_id ? min : param),
                modelToUpdate.recTemplate.params[0]
              )
            : null;

          // In this array we will keep all the found parameters and we will use it to check which parameters
          // have not been used
          let allFoundParams = [];

          // Loop through all the new parameters to sort them
          for (let i = 0; i < modelParamsToUse.length; i++) {
            const modelParam = modelParamsToUse[i];
            // If new parameter matches non recuring parameter reffit id, update the non recuring parameter
            if (
              nonRecParams.some(
                (param) => param.reffit_id == modelParam.reffit_id
              )
            ) {
              modelToUpdate.modelParams = modelToUpdate.modelParams.map(
                (param) => {
                  if (param.reffit_id == modelParam.reffit_id) {
                    return {
                      ...param,
                      value:
                        param.type !== "Checkbox"
                          ? modelParam.value
                          : checkBool(modelParam.value),
                    };
                  } else {
                    return param;
                  }
                }
              );

              // Add Non Recuring parameters to the list of found values
              allFoundParams.push(modelParam);
            } else {
              // If it doesn't match, that means the new parameter is potentially a recuring one, add it to
              // possibly recuring parameter array if its reffit id is equal or higher than the smallest template reffit id
              // this prevents of adding values which are guaranteed unfit for the recuring parameters
              if (
                hasRec &&
                modelParam.reffit_id >= smallestIDTemplateParam.reffit_id
              ) {
                recParamsForUpdate.push(modelParam);
              } else {
                if (modelParam.value !== 0) {
                  strangeResults = true;
                }
              }
            }
          }

          // The following actions are only relevant if model has recurring parameters
          if (hasRec) {
            // Find the new parameter with the largest reffit id
            const paramForUpdateWithLargestID = recParamsForUpdate.reduce(
              (max, param) => (max.reffit_id > param.reffit_id ? max : param),
              recParamsForUpdate[0]
            );

            // Find the recuring parameter from a template with the largest reffit id and recuring value combination for
            // given largest reffit id of parameter for updating
            const largestIDModelTemplateParam =
              modelToUpdate.recTemplate.params.reduce(
                (max, param) =>
                  (paramForUpdateWithLargestID.reffit_id - max.reffit_id) /
                    max.recuring >
                  (paramForUpdateWithLargestID.reffit_id - param.reffit_id) /
                    param.recuring
                    ? max
                    : param,
                modelToUpdate.recTemplate.params[0]
              );

            // Calculate maximum possible recuring table rows
            const maxPossibleRows = Math.floor(
              (paramForUpdateWithLargestID.reffit_id -
                largestIDModelTemplateParam.reffit_id) /
                largestIDModelTemplateParam.recuring
            );

            let rowsOfValues = [];

            // Loop until max possible rows are reached, <= is used because we calculate rows from 0
            for (let row = 0; row <= maxPossibleRows; row++) {
              let values = [];
              // loop through template parameters to match each parameter with
              for (
                let paramIndex = 0;
                paramIndex < modelToUpdate.recTemplate.params.length;
                paramIndex++
              ) {
                // Find the value according to a parameter from the template
                const param = modelToUpdate.recTemplate.params[paramIndex];
                const foundValue = recParamsForUpdate.find(
                  (paramToUpdate) =>
                    paramToUpdate.reffit_id ==
                    param.reffit_id + row * param.recuring
                );
                // Add recuring parameters to the list of found values
                allFoundParams.push(foundValue);
                // If the value is found, push it to the value row
                if (foundValue != undefined) {
                  values.push({
                    value:
                      param.type !== "Checkbox"
                        ? foundValue.value
                        : checkBool(foundValue.value),
                    reffit_id: param.reffit_id,
                  });
                }
              }
              // Push the row of values to the array of rows
              rowsOfValues.push(values);
            }

            // Find all the unincluded parameters
            const allNotIncluded = recParamsForUpdate.filter(
              (item) => !allFoundParams.includes(item)
            );

            if (allNotIncluded.some((param) => param.value !== 0)) {
              strangeResults = true;
            }

            // Check if results are not strange and if they are, create an error message for the user
            if (strangeResults) {
              limitedToast("Model parameter calculation error: E0001");
              generateWarningObject(
                "There was an error with model parameter calculations. Please report this to support. Error code: E0001",
                2,
                setWarnings,
                setNewWarningCount
              );
            }

            // Check if the last row is completely filled, if not, discard it
            if (
              rowsOfValues[rowsOfValues.length - 1].length !=
              modelToUpdate.recTemplate.params.length
            ) {
              rowsOfValues.pop();
            }

            // Loop through the array of new row values
            for (let rowIndex = 0; rowIndex < rowsOfValues.length; rowIndex++) {
              const rowValues = rowsOfValues[rowIndex];

              // Check if the row is in the recuring parameters table
              if (modelToUpdate.recParams.length > rowIndex) {
                // If the recuring parameters table contains this row, do the updates
                for (
                  let i = 0;
                  i < modelToUpdate.recParams[rowIndex].length;
                  i++
                ) {
                  // Find the value from row values that matches rec table parameter reffit id
                  const foundVal = rowValues.find(
                    (rowVal) =>
                      rowVal.reffit_id ==
                      modelToUpdate.recParams[rowIndex][i].reffit_id
                  );

                  // If the value is found, update rec table parameter and row values
                  if (foundVal != undefined) {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        modelToUpdate.recParams[rowIndex][i],
                        "group"
                      )
                    ) {
                      const groupToUpdate =
                        modelToUpdate.recParams[rowIndex][i].group;

                      const updatedGroups = localValueGroups.current.map(
                        (group) => {
                          if (group.groupNumber === groupToUpdate) {
                            const maxVal =
                              group.hardMax < foundVal.value
                                ? null
                                : group.hardMax;
                            const minVal =
                              group.hardMin > foundVal.value
                                ? null
                                : group.hardMin;
                            return {
                              ...group,
                              value: foundVal.value,
                              hardMax: maxVal,
                              hardMin: minVal,
                            };
                          } else {
                            return group;
                          }
                        }
                      );

                      localValueGroups.current = updatedGroups;
                    }

                    modelToUpdate.recParams[rowIndex][i] = {
                      ...modelToUpdate.recParams[rowIndex][i],
                      value: foundVal.value,
                    };

                    modelToUpdate.recTableRows[rowIndex] = {
                      ...modelToUpdate.recTableRows[rowIndex],
                      [modelToUpdate.recParams[rowIndex][i].name]:
                        foundVal.value,
                    };
                  }
                }

                if (!isDeepEqual(localValueGroups.current, valueGroups)) {
                  setValueGroups(localValueGroups.current);
                }
              } else {
                // If row index of new values is greater than current recuring parameter table lenght, that means we
                // need to add additional parameter row from template
                let paramRow = [];
                let tableRow = {};

                // Loop through recuring parameters template
                for (
                  let i = 0;
                  i < modelToUpdate.recTemplate.params.length;
                  i++
                ) {
                  // Find the value from values row that matches template parameter reffit id
                  const foundVal = rowValues.find(
                    (rowVal) =>
                      rowVal.reffit_id ==
                      modelToUpdate.recTemplate.params[i].reffit_id
                  );
                  // If the value is found, add the paramter with value from row of values to temporary parameters
                  // If the value is not found, we still add entry to the table, but with value from the template - this
                  // prevents the creation of tables with undefined table cells
                  if (foundVal != undefined) {
                    paramRow.push({
                      ...modelToUpdate.recTemplate.params[i],
                      value: foundVal.value,
                    });
                    tableRow = {
                      ...tableRow,
                      [modelToUpdate.recTemplate.params[i].name]:
                        foundVal.value,
                    };
                  } else {
                    paramRow.push({
                      ...modelToUpdate.recTemplate.params[i],
                    });
                    tableRow = {
                      ...tableRow,
                      [modelToUpdate.recTemplate.params[i].name]:
                        modelToUpdate.recTemplate.params[i].value,
                    };
                  }
                }

                // When temporary recuring parameter and table row values are filled, update model with them
                modelToUpdate.recParams.push(paramRow);
                modelToUpdate.recTableRows.push(tableRow);
              }

              // Here we will check if there are any other rows remaining in the parameter table after we finished adding ones from BE. We will remove them if found
              if (rowIndex === rowsOfValues.length - 1) {
                modelToUpdate.recTableRows.splice(rowIndex + 1);
                modelToUpdate.recParams.splice(rowIndex + 1);
              }
            }
          }

          copyOfModels = replaceModelById(
            copyOfModels,
            modelToUpdate,
            modelDataFromResponse.modelid
          );
        }

        // UPDATING MODEL CURVES
        for (let i = 0; i < requestedFitModelsLocal.current.length; i++) {
          const requestedModelQuantitySet = requestedFitModelsLocal.current[i];
          const modelCurveFound = hasProperty(response.Model, "SendCurve")
            ? response.Model.SendCurve.filter(
                (modelCurve) =>
                  modelCurve.modelid === requestedModelQuantitySet.modelId &&
                  modelCurve.curves[0].quantity ===
                    requestedModelQuantitySet.quantity
              )
            : [];

          if (isDone) {
            remainingIDs = remainingIDs.filter(
              (id) =>
                id.modelId != requestedModelQuantitySet.modelId &&
                id.quantity != requestedModelQuantitySet.quantity
            );
          }
          // Check if requested model is actually received
          if (modelCurveFound.length > 0) {
            // Find the model which needs updating
            const modelToUpdate = deepCopy(
              getModelById(requestedModelQuantitySet.modelId, copyOfModels)
            );

            // Update model with new Curve
            // We need to check if the curve exists here. If we do fit and model is not displayed in graph, we don't
            // receive a curve back from BE.
            const curveObj = produceCurveObject(
              modelCurveFound[0].curves[0].coordinates,
              modelCurveFound[0].quantity
            );
            modelToUpdate.curves = updateOrAddCurve(
              modelToUpdate.curves,
              curveObj
            );

            copyOfModels = replaceModelById(
              copyOfModels,
              modelToUpdate,
              requestedModelQuantitySet.modelId
            );
            copyOfGraphs = copyOfGraphs.map((graph) => {
              if (
                graph.containedModels.some(
                  (modelQuantityPair) =>
                    modelQuantityPair.modelId ===
                      requestedModelQuantitySet.modelId &&
                    modelQuantityPair.quantity ===
                      requestedModelQuantitySet.quantity
                )
              ) {
                updateGraphs = true;
                graph.plotData = graph.plotData.map((data) => {
                  if (
                    hasProperty(data, "modelId") &&
                    data.modelId === requestedModelQuantitySet.modelId &&
                    data.quantity === requestedModelQuantitySet.quantity
                  ) {
                    let coordSetForData =
                      modelCurveFound[0].curves[0].coordinates.map((coord) => {
                        return {
                          x: parseFloat(coord.x),
                          y: parseFloat(coord.y),
                        };
                      });
                    const dataGenerated = generatePlotData(
                      coordSetForData,
                      "",
                      "",
                      data.line.color,
                      data.name,
                      null,
                      requestedModelQuantitySet.modelId,
                      null,
                      data.quantity
                    );
                    return {
                      ...data,
                      x: dataGenerated[0].x,
                      y: dataGenerated[0].y,
                    };
                  } else {
                    return data;
                  }
                });
              }
              return graph;
            });
          }
        }

        requestedFitModelsLocal.current = remainingIDs;
        setRequestedFitModel(requestedFitModelsLocal.current);
        if (updateModels) {
          setModelData(copyOfModels);
        }
        if (updateGraphs) {
          setGraphs(copyOfGraphs);
        }
      } catch (error) {
        recordedErrorLog("Error updating details from AutoFit: ", error);
      }
    }
  };

  function handleFitMessage(jsonMessage) {
    try {
      if (hasProperty(jsonMessage, "Error") && jsonMessage.Error.length > 0) {
        setUpdateChiTerms(true);
        setIsFitOngoing(false);
        setFitIteration(null);
        // Timeout here to make sure this is displayed, as the warnings and erros are generally caught and
        // displayed in another useEffect hook
        setTimeout(() => {
          limitedToast(`The fit has failed.`);
        }, 10);
        deleteRequestDataEntry(lastJsonMessage.requestID);
      } else {
        const fitSection = jsonMessage.Fit;
        if (hasProperty(fitSection, "fitactive")) {
          setIsFitOngoing(false);
          setFitIteration(null);
          deleteRequestDataEntry(lastJsonMessage.requestID);
        } else if (fitSection.fit.fit_done) {
          const fitStatus = fitSection.fit;
          handleFitResponse(jsonMessage, true, true);
          setUpdateChiTerms(true);
          setIsFitOngoing(false);
          setFitIteration(null);
          limitedSucessToast(
            `The fit was finished succesfully with ${fitStatus.n_iterations} iterations.`
          );
          generateWarningObject(
            `The fit was finished succesfully with ${fitStatus.n_iterations} iterations.`,
            0,
            setWarnings,
            setNewWarningCount
          );
          deleteRequestDataEntry(lastJsonMessage.requestID);
        } else {
          const fitStatus = fitSection.fit;
          setFitIteration(fitStatus.n_iterations);
          handleFitResponse(jsonMessage, false, false);

          const newTerms = jsonMessage.Fit.chi2.chi2s;
          const updatedTerms = updatedChi2(newTerms, chi2Terms);
          setChi2Terms(updatedTerms);
        }
      }
    } catch (error) {
      recordedErrorLog("Error handling Fit message: ", error);
    }
  }

  const handleLoopResponse = (lastJsonMessage) => {
    try {
      // We check if this is standard message or the one idetifying end of Loop
      if (hasProperty(lastJsonMessage, "Loop")) {
        //HERE WE HANDLE CHI2 UPDATES

        if (pauseOnNextLoop) {
          setLoopPaused(true);
        }

        if (hasProperty(lastJsonMessage.Loop, "chi2")) {
          handleChi2Message(lastJsonMessage, false);
        }

        //We make a copy of models to keep updating single array
        let modelsCopy = deepCopy(modelData);

        //Here we deal with curve updates
        if (lastJsonMessage.Model.SendCurve.length > 0) {
          const coordSets = lastJsonMessage.Model.SendCurve;
          const dict = getReturnedCurveDictionary(coordSets);
          const modelIds = Object.keys(dict);
          let graphsCopy = deepCopy(graphs);
          // let modelsCopy = deepCopy(modelData);
          // We loop through graphs in order to update them
          for (let i = 0; i < graphsCopy.length; i++) {
            const min = graphsCopy[i].layout.xaxis.range[0];
            const max = graphsCopy[i].layout.xaxis.range[1];

            // We loop through contained model ids in graphs to check which ones received updated data
            for (let j = 0; j < graphsCopy[i].containedModels.length; j++) {
              const modelQuantitySet = graphsCopy[i].containedModels[j];

              // We check if current model id from graph is contained in updates
              if (
                modelIds.some(
                  (key) => parseInt(key) === modelQuantitySet.modelId
                )
              ) {
                const bestCurve = findBestMatchingCurve(
                  dict[modelQuantitySet.modelId][modelQuantitySet.quantity],
                  min,
                  max,
                  modelQuantitySet.quantity
                );

                modelsCopy = modelsCopy.map((modelToChange) => {
                  if (modelToChange.FE_ID === modelQuantitySet.modelId) {
                    const modelToUpdate = modelToChange;

                    modelToUpdate.curves = updateOrAddCurve(
                      modelToUpdate.curves,
                      bestCurve.curve
                    );
                    return modelToUpdate;
                  } else {
                    return modelToChange;
                  }
                });

                graphsCopy[i].plotData = replaceModelPlotData(
                  graphsCopy[i].plotData,
                  bestCurve,
                  modelQuantitySet.modelId,
                  modelQuantitySet.quantity
                );
              }
            }
          }

          setGraphs(graphsCopy);
        }

        //Here we deal with parameter updates
        if (
          hasProperty(lastJsonMessage.Model, "SendModel") &&
          lastJsonMessage.Model.SendModel.length > 0
        ) {
          for (let i = 0; i < lastJsonMessage.Model.SendModel.length; i++) {
            const updatedModelEntry = lastJsonMessage.Model.SendModel[i];
            const modelToUpdate = getModelById(
              updatedModelEntry.modelid,
              modelsCopy
            );

            const getModelForParams = (reffitID, modelId) => {
              const subModel = getModelById(modelId, modelsCopy);

              if (subModel === null || reffitID !== subModel.reffitID) {
                recordedErrorLog(
                  "There was issue retrieving sub model for updating model from parameter list.",
                  new Error("Sub model finding error.")
                );
              }

              return subModel;
            };

            const filledModelWithParams = createModelFromParamList(
              updatedModelEntry,
              modelToUpdate,
              getModelForParams
            );

            modelsCopy = replaceModelById(
              modelsCopy,
              filledModelWithParams,
              modelToUpdate.FE_ID
            );
          }
        } else {
          limitedWarningToast(
            "The loop response did not contain any model information."
          );
        }

        //We update models array to reflect the changes
        setModelData(modelsCopy);

        //Here we handle with users request to save MODELS into files
        const modelsThatNeedSaving = listOfLoopModelsToSave.filter(
          (model) => model.save
        );
        if (modelsThatNeedSaving.length > 0) {
          for (let i = 0; i < modelsThatNeedSaving.length; i++) {
            const modelToSave = modelsThatNeedSaving[i];

            const relevantModelFromResponse =
              lastJsonMessage.Model.SendModel.find(
                (modelFromResponse) =>
                  modelFromResponse.modelid === modelToSave.FE_ID
              );

            if (relevantModelFromResponse) {
              downloadStringTxtFile(
                JSON.stringify(relevantModelFromResponse),
                `${loopModelFilesCustomName}_${modelToSave.name}_Iteration_${lastJsonMessage.Loop.current_loop_iteration}`,
                ".spqm"
              );
            } else {
              console.warn("No loop model found to save.");
            }
          }
        }
        //Here we handle with users request to save CURVES into files
        const curvesThatNeedSaving = listOfLoopCurvesToSave.filter(
          (curve) => curve.save
        );
        if (curvesThatNeedSaving.length > 0) {
          for (let i = 0; i < curvesThatNeedSaving.length; i++) {
            const curveToSave = curvesThatNeedSaving[i];

            const correctModelCurves = lastJsonMessage.Model.SendCurve.find(
              (curveSet) => curveSet.modelid === curveToSave.modelId
            );

            if (correctModelCurves) {
              const correctQuantity = correctModelCurves.curves.find(
                (curveEntry) => curveEntry.quantity === curveToSave.quantity
              );

              if (correctQuantity) {
                let fileContent = `X\tY\n`;

                for (let i = 0; i < correctQuantity.coordinates.length; i++) {
                  const coordSet = correctQuantity.coordinates[i];
                  fileContent = fileContent + `${coordSet.x}\t${coordSet.y}\n`;
                }

                downloadStringTxtFile(
                  fileContent,
                  `${loopCurveFilesCustomName}_${curveToSave.name}_${curveToSave.quantityName}_Iteration_${lastJsonMessage.Loop.current_loop_iteration}`
                );
              } else {
                console.warn("No correct curve quantity found to save.");
              }
            } else {
              console.warn("No loop curve found to save.");
            }
          }
        }

        //Here we finish up with loop handling and check if it is done. If so, we reset relevant variables, otherwise, we
        //update iteration count
        if (lastJsonMessage.Loop.loop_done) {
          setLoopPaused(false);
          setPauseOnNextLoop(false);
          setIsLoopOngoing(false);
          setLoopIteration(null);
          setListOfLoopModelsToSave([]);
          setListOfLoopCurvesToSave([]);
          deleteRequestDataEntry(lastJsonMessage.requestID);
        } else {
          setLoopIteration(lastJsonMessage.Loop.current_loop_iteration);
        }
      } else {
        if (
          hasProperty(lastJsonMessage, "Fit") &&
          hasProperty(lastJsonMessage.Fit, "fitactive") &&
          !lastJsonMessage.Fit.fitactive
        ) {
          setIsLoopOngoing(false);
          setLoopIteration(null);
          setListOfLoopModelsToSave([]);
          setListOfLoopCurvesToSave([]);
          deleteRequestDataEntry(lastJsonMessage.requestID);
        }
      }
    } catch (error) {
      recordedErrorLog("Error handling loop response: ", error);
    }
  };

  const handleCloseIconClick = () => {
    setBroadcastOpen(false);
  };

  const handleReprocessClose = () => {
    setReprocessOpen(false);
    setFileToReprocess(null);
    setFileToChange(null);
  };

  const handleReprocessDone = (newFile) => {
    try {
      const updatedFiles = uploadedFiles.map((uFile) => {
        if (uFile.ID === newFile.ID) {
          return newFile;
        } else {
          return uFile;
        }
      });

      setUploadedFiles(updatedFiles);

      handleReprocessClose();
    } catch (error) {
      recordedErrorLog("Reprocess done handler failure: ", error);
    }
  };

  const handleFitStop = () => {
    sendJsonMessage(
      {
        User: currentUser.id,
        stopfit: true,
      },
      false
    );
    limitedSucessToast(`The fit was stopped after ${fitIteration} iterations.`);
    generateWarningObject(
      `The fit was stopped after ${fitIteration} iterations.`,
      0,
      setWarnings,
      setNewWarningCount
    );
    setIsFitOngoing(false);
    setFitIteration(null);
  };

  const handleLoopStop = () => {
    sendJsonMessage(
      {
        User: currentUser.id,
        stop_task: true,
      },
      false
    );

    setLoopPaused(false);
    setPauseOnNextLoop(false);
  };

  const handleLoopStep = () => {
    sendJsonMessage(
      {
        User: currentUser.id,
        pause_next_iteration: true,
      },
      false
    );
    setLoopPaused(false);
    setPauseOnNextLoop(true);
  };

  const handleLoopRun = () => {
    sendJsonMessage(
      {
        User: currentUser.id,
        stop_pause: true,
      },
      false
    );
    setLoopPaused(false);
    setPauseOnNextLoop(false);
  };

  return (
    <Box sx={{ display: "flex" }} ref={dashboardRef}>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 3,
          height: "100vh",
          overflow: "scroll",
          width: 1,
        }}
      >
        <AppBar />
        <WindowSelector />
        <Container maxWidth={false} disableGutters className="mainContainer">
          <Grid
            container
            spacing={0.5}
            sx={{ mt: 0, marginTop: "0.5vh", flexWrap: "nowrap" }}
            className="gridContainer"
            style={{ position: "relative" }}
          >
            {/* dashboard is divided by 3 grids 
                First grid = data loading, data settings, models */}
            {/* {showLeftPanel ? <LeftSide /> : <></>} */}
            <LeftSide />
            {/* Second grid = graph */}
            <Grid item xs={5}>
              {outputsOpen ? <GraphContainer /> : <></>}
              {loopOpen ? <Loop /> : <></>}
            </Grid>
            {/* Third grid = fit results, params table, warnings and export (all imported components) */}
            {/* <RightSide setShowOtherPanel={setShowLeftPanel} /> */}
            <RightSide />
          </Grid>
          {/* imported Copyright component */}
          <div className="copyright">
            <Copyright sx={{ pb: 4 }} />
          </div>
        </Container>
        {isFitOngoing && (
          <FitStopWindow
            iterationCount={fitIteration}
            handleStop={handleFitStop}
          />
        )}
        {isLoopOngoing && (
          <LoopStopWindow
            iterationCount={loopIteration}
            handleStop={handleLoopStop}
            handleLoopStep={handleLoopStep}
            handleLoopRun={handleLoopRun}
            paused={loopPaused}
          />
        )}
      </Box>
      {selectedFiles !== undefined && selectedFiles.length == 1 ? (
        <StickyFileWindow
          selectedFiles={selectedFiles}
          updateSelected={updateSelected}
          callFromGraph={fromGraphState}
          resetSelection={resetSelection}
        />
      ) : (
        <></>
      )}
      <BroadcastModal
        elementRef={dashboardRef}
        isOpen={broadcastOpen}
        setIsOpen={setBroadcastOpen}
        handleClose={handleCloseIconClick}
      />
      <Modal
        isOpen={reprocessOpen}
        onRequestClose={handleReprocessClose}
        shouldCloseOnOverlayClick={true}
        contentLabel="File Re-Processing Modal"
        appElement={dashboardRef.current}
        id="fileReProcessModal"
        style={{
          content: {
            width: "80vw",
            height: "70vh",
            top: `10%`,
            left: "10%",
            right: "auto",
          },
          overlay: {
            zIndex: "8000",
          },
        }}
      >
        <SingleFileReprocess
          file={fileToReprocess}
          handleDone={handleReprocessDone}
          handleCancel={handleReprocessClose}
          modalId={"fileReProcessModal"}
        />
      </Modal>
    </Box>
  );
}
