import React from "react";
import "./legal.scss";

function TermsOfUse() {
  return (
    <div className="legalPage">
      <h1>Terms of Use of the SaaS platform (for Alpha testing only)</h1>

      <div className="section">
        <h2>1. Introduction</h2>
        <p>
          Welcome to the Speqqle Alpha Testing Program. By accessing or using
          the Speqqle SaaS platform during the alpha testing phase, you agree to
          be bound by these Terms of Use. These terms outline your rights and
          obligations as a tester, as well as the rights and obligations of
          Speqqle SA.
        </p>
        <p>
          If you do not agree to these terms, please do not access or use the
          Speqqle platform.
        </p>
      </div>

      <div className="section">
        <h2>2. Definitions</h2>
        <ul>
          <li>
            <strong>&quot;Speqqle&quot;</strong> refers to Speqqle SA, the
            provider of the SaaS platform.
          </li>
          <li>
            <strong>&quot;Platform&quot;</strong> refers to the Speqqle
            software-as-a-service (SaaS) platform, including all applications,
            features, and content made available during the alpha testing phase.
          </li>
          <li>
            <strong>&quot;Tester&quot; or &quot;User&quot;</strong> refers to
            you, the individual who accesses or uses the platform as part of the
            alpha testing program.
          </li>
          <li>
            <strong>&quot;Personal Data&quot;</strong> refers to personal data
            as defined under GDPR and Swiss data protection laws.
          </li>
          <li>
            <strong>&quot;Feedback&quot;</strong> refers to any ideas,
            suggestions, reports, or data you submit as part of your testing
            activities.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>3. Purpose of the Alpha Testing Program</h2>
        <p>
          The Alpha Testing Program allows users to test pre-release versions of
          the Speqqle platform. The primary objective is to identify bugs,
          gather usability feedback, and validate features prior to public
          release. As a tester, you agree to provide feedback to Speqqle SA to
          assist in improving the platform.
        </p>
      </div>

      <div className="section">
        <h2>4. License and Access Rights</h2>
        <ul>
          <li>
            <strong>Limited License</strong>: Speqqle SA grants you a
            non-exclusive, non-transferable, revocable license to access and use
            the platform solely for alpha testing purposes.
          </li>
          <li>
            <strong>No Commercial Use</strong>: The platform is made available
            exclusively for testing and evaluation purposes. You may not use it
            for commercial or production purposes.
          </li>
          <li>
            <strong>No Ownership Rights</strong>: Speqqle SA retains all rights,
            title, and interest in and to the platform, including intellectual
            property rights.
          </li>
          <li>
            <strong>No Distribution or Sharing</strong>: You may not distribute,
            sublicense, or share access to the platform with third parties.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>5. User Obligations</h2>
        <ul>
          <li>
            <strong>Confidentiality</strong>: Keep all information related to
            the platform, including features, interface, and content,
            confidential and refrain from disclosing it to third parties.
          </li>
          <li>
            <strong>Testing and Feedback</strong>: Actively participate in
            testing activities and provide feedback, including bug reports,
            usability feedback, and suggestions for improvement.
          </li>
          <li>
            <strong>Responsible Use</strong>: Refrain from using the platform
            for any unlawful or harmful purposes.
          </li>
          <li>
            <strong>Compliance</strong>: Comply with all instructions and
            guidance provided by Speqqle SA related to testing activities.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>6. Prohibited Activities</h2>
        <p>You may not:</p>
        <ul>
          <li>
            Reverse engineer, decompile, disassemble, or otherwise attempt to
            derive the source code of the platform.
          </li>
          <li>
            Modify, copy, or create derivative works based on the platform.
          </li>
          <li>Use automated tools or bots to access the platform.</li>
          <li>Exploit the platform for personal or commercial gain.</li>
          <li>
            Engage in any activity that could harm, damage, or compromise the
            platform&apos;s integrity, performance, or security.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>7. Data Protection, Privacy, and Legal Basis for Processing</h2>
        <p>
          Speqqle SA respects your privacy and is committed to protecting your
          personal data. All data collection, use, and processing are governed
          by the Speqqle Privacy Statement. By participating in the Alpha
          Testing Program, you explicitly consent to the processing of your
          personal data as outlined in the Speqqle Privacy Statement.
        </p>
        <p>
          Speqqle SA processes personal data in accordance with Article 6(1)(a)
          of the General Data Protection Regulation (GDPR), which requires the
          user&apos;s explicit consent for the collection, processing, and use
          of personal data. Personal data collected may include, but is not
          limited to:
        </p>
        <ul>
          <li>
            <strong>Identity Data</strong> (e.g., name)
          </li>
          <li>
            <strong>Contact Data</strong> (e.g., email address)
          </li>
          <li>
            <strong>Feedback Data</strong> (e.g., suggestions, bug reports)
          </li>
          <li>
            <strong>Technical Data</strong> (e.g., usage logs, device
            information)
          </li>
        </ul>
        <p>
          This data is processed for the purposes of enabling access to the
          platform, supporting testing activities, analyzing platform usage, and
          improving Speqqle&apos;s products and services.
        </p>
        <p>
          Users may withdraw their consent at any time by contacting Speqqle at{" "}
          <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>. Requests
          for withdrawal will be addressed within 30 days, in accordance with
          GDPR requirements. For more details, please review our{" "}
          <a
            href={`${window.location.origin}/privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Statement
          </a>
          .
        </p>
      </div>

      <div className="section">
        <h2>8. Feedback and Intellectual Property</h2>
        <p>
          Any feedback you provide (e.g., bug reports, suggestions, or
          improvements) becomes the property of Speqqle SA. By submitting
          feedback, you assign to Speqqle SA all rights, title, and interest in
          that feedback. You will not be compensated for the feedback you
          provide.
        </p>
      </div>

      <div className="section">
        <h2>9. Platform Availability and Updates</h2>
        <ul>
          <li>
            <strong>No Guarantees</strong>: The platform is provided on an
            &quot;as is&quot; and &quot;as available&quot; basis. Speqqle SA
            does not guarantee uninterrupted or error-free operation.
          </li>
          <li>
            <strong>Maintenance and Updates</strong>: Speqqle SA reserves the
            right to update, modify, or discontinue access to the platform at
            any time without notice.
          </li>
          <li>
            <strong>No Liability for Downtime</strong>: Speqqle SA is not
            responsible for downtime, delays, or service interruptions.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>10. Limitation of Liability</h2>
        <p>
          Speqqle SA&apos;s liability is limited to the maximum extent permitted
          by law. Speqqle SA will not be liable for any loss of data, loss of
          revenue, or indirect, consequential, or punitive damages.
        </p>
      </div>

      <div className="section">
        <h2>11. Termination of Use</h2>
        <ul>
          <li>
            <strong>Termination by Speqqle</strong>: Speqqle SA may terminate or
            suspend your access to the platform at any time without prior
            notice.
          </li>
          <li>
            <strong>Termination by User</strong>: You may withdraw from the
            alpha testing program at any time by notifying Speqqle SA.
          </li>
          <li>
            <strong>Post-Termination Obligations</strong>: Upon termination, you
            must delete any materials, files, or outputs generated during your
            use of the platform.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>12. Confidentiality</h2>
        <p>
          All information, materials, features, and content related to the
          Speqqle platform, including but not limited to platform design,
          features, user interface, and technical specifications, are considered
          confidential information. By accessing the platform, you agree to
          treat all such information as strictly confidential.
        </p>
        <p>
          Testers are prohibited from sharing, distributing, copying, or
          disclosing any information related to the platform with any third
          parties without the prior written consent of Speqqle SA. This includes
          but is not limited to screenshots, screen recordings, and written
          descriptions of the platform or its features. Any breach of this
          confidentiality obligation may result in termination of access to the
          platform and may also result in legal action to protect Speqqle
          SA&apos;s intellectual property and trade secrets.
        </p>
        <p>
          Confidentiality obligations remain in effect even after the completion
          of the Alpha Testing Program, unless explicitly released from such
          obligations by Speqqle SA in writing.
        </p>
      </div>

      <div className="section">
        <h2>13. Notification of Changes to the Terms of Use</h2>
        <p>
          Speqqle SA reserves the right to update or modify these Terms of Use
          at any time. If significant changes are made, users will be notified
          via email or through in-platform notifications. Users are responsible
          for reviewing the updated Terms of Use, and continued participation in
          the Alpha Testing Program after changes take effect constitutes
          acceptance of the new terms.
        </p>
      </div>

      <div className="section">
        <h2>14. Data Deletion Requests</h2>
        <p>
          Users have the right to request the deletion of their personal data at
          any time. Deletion requests can be made by contacting Speqqle at{" "}
          <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>. Upon
          receipt of the request, Speqqle will process and complete the deletion
          request within 30 days, in accordance with GDPR Article 12. Users will
          receive confirmation once their personal data has been deleted or
          anonymized.
        </p>
        <p>
          In the event that data cannot be fully deleted due to legal or
          regulatory requirements, Speqqle SA will inform the user and provide
          details on the applicable legal basis for the retention of such data.
        </p>
      </div>

      <div className="section">
        <h2>15. Governing Law and Dispute Resolution</h2>
        <p>
          These Terms of Use are governed by the laws of{" "}
          <strong>Switzerland</strong>. Any disputes arising from these terms
          will be subject to the exclusive jurisdiction of the courts of{" "}
          <strong>Geneva, Switzerland</strong>.
        </p>
      </div>

      <div className="section">
        <h2>16. Contact Us</h2>
        <p>
          If you have questions, comments, or concerns about these Terms of Use,
          please contact us at{" "}
          <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>.
        </p>
      </div>
    </div>
  );
}

export default TermsOfUse;
