import React, { useState, useContext } from "react";
// import logo from "../res/Logos/reffitLogo.png";
import logo from "../res/Logos/speqqle.png";
import { Typography, Box, Stack, Button, TextField, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdminFooter from "../components/commonComponents/AdminFooter";
import { AuthContext } from "../context/AuthContext";
import { api } from "../utils/authActions";
import "./login.scss";
import { validateEmail } from "../utils/helpers";

export default function Login() {
  const navigate = useNavigate();
  const { setAuthToken, setIsAdmin, setIsActive, setIsAuthReady } =
    useContext(AuthContext);
  const [loginFailed, setLoginFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  const [recoveryMode, setRecoveryMode] = useState(false);
  const [recoverSent, setRecoverSent] = useState(false);
  const [recoverFailed, setRecoverFailed] = useState(false);

  //set user input as username
  const handleUser = (e) => {
    setUser(e.target.value);
  };

  //set user input as password
  const handlePass = (e) => {
    setPass(e.target.value);
  };

  //send the username and password to API for verification
  const loginAuth = (e) => {
    // Prevent default form submission
    if (e) e.preventDefault();

    // Check if both user and pass are filled
    if (!user || !pass) return;

    api
      .logInGetToken(user, pass)
      .then((response) => {
        if (
          Object.prototype.hasOwnProperty.call(response, "data") &&
          Object.prototype.hasOwnProperty.call(response.data, "access_token") &&
          Object.prototype.hasOwnProperty.call(response.data, "token_type") &&
          response.data.token_type === "bearer"
        ) {
          setLoginFailed(false);
          setErrorMessage("");
          setAuthToken(response.data.access_token);
          setIsAdmin(response.data.admin);
          setIsActive(response.data.active);
          setIsAuthReady(true);
          navigate("/profile");
        }
      })
      .catch((err) => {
        console.log("ERROR: ", err);
        if (
          Object.prototype.hasOwnProperty.call(err, "response") &&
          Object.prototype.hasOwnProperty.call(err.response, "data") &&
          Object.prototype.hasOwnProperty.call(err.response.data, "detail")
        ) {
          setLoginFailed(true);
          setErrorMessage(err.response.data.detail);
        }
      });
  };

  const handleReset = (e) => {
    // Prevent default form submission
    if (e) e.preventDefault();

    // Check if user is filled
    if (!user || !validateEmail(user)) return;

    // const payload = {
    //   email: user,
    // };
    api
      .sendPasswordReset(user)
      .then(() => {
        setRecoverSent(true);
        setRecoverFailed(false);
      })
      .catch(() => {
        setRecoverSent(false);
        setRecoverFailed(true);
      });
  };

  const handleForgot = () => {
    setRecoveryMode(true);
  };

  const handleRegisterClick = () => {
    navigate("/register");
  };

  // Handler to detect the Enter key press on the input fields
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      loginAuth(e);
    }
  };

  return (
    <div className="loginPage">
      <Box textAlign="center">
        <img
          src={logo}
          alt="logo"
          // width="430"
          // height="150"
          style={{ padding: "2rem", scale: "70%", marginTop: "2vh" }}
        />
      </Box>

      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="50vh"
        className="centerGrid"
      >
        <Grid item xs={0} sx={{ padding: 0 }}>
          <form onSubmit={loginAuth}>
            <Stack spacing={2}>
              {recoveryMode ? (
                <Typography variant="h6">Password Recovery</Typography>
              ) : (
                <Typography variant="h6">Authentication</Typography>
              )}
              <TextField
                size="small"
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                onChange={handleUser}
                onKeyDown={handleKeyDown}
                error={loginFailed || recoverFailed}
                helperText={loginFailed ? errorMessage : ""}
                autoComplete="username"
              />
              {recoverSent ? (
                <div className="successRecover">
                  Recover submitted successfully
                </div>
              ) : (
                <></>
              )}
              {recoverFailed ? (
                <div className="failedRecover">Password recovery failed</div>
              ) : (
                <></>
              )}
              {recoveryMode ? (
                <></>
              ) : (
                <TextField
                  size="small"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  onChange={handlePass}
                  onKeyDown={handleKeyDown}
                  error={loginFailed}
                  helperText={loginFailed ? errorMessage : ""}
                  autoComplete="current-password"
                />
              )}

              {recoveryMode ? (
                <Button
                  type="button"
                  onClick={handleReset}
                  className="loginButton"
                >
                  Reset
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={loginAuth}
                  className="loginButton"
                  id="login-button"
                >
                  Login
                </Button>
              )}

              <div className="otherOptions">
                {recoveryMode ? (
                  <div
                    className="forgot"
                    onClick={() => {
                      setRecoveryMode(false);
                      setRecoverSent(false);
                      setRecoverFailed(false);
                    }}
                  >
                    Back to login
                  </div>
                ) : (
                  <div className="forgot" onClick={() => handleForgot()}>
                    Forgot your password?
                  </div>
                )}
                <div className="register">
                  Don&apos;t have an account yet?
                  <div
                    className="registerButton"
                    onClick={() => handleRegisterClick()}
                  >
                    Register now!
                  </div>
                </div>
              </div>
            </Stack>
          </form>
        </Grid>
      </Grid>
      <AdminFooter />
    </div>
  );
}
