import React from "react";
import "./loop.scss";
import { Button } from "@mui/material";

function LoopStopWindow(props) {
  const {
    iterationCount,
    handleStop,
    handleLoopStep,
    handleLoopRun,
    paused = false,
  } = props;

  return (
    <div className={`loopStopWindow`}>
      {!paused ? (
        <>
          <div className="loopInfo">
            <div className="text">Processing Loop...</div>
            <div className="iterationCount">{`Current iteration: ${
              iterationCount !== null ? iterationCount : 0
            }`}</div>
          </div>
          <div className="buttonArea">
            <Button
              variant="contained"
              className="stopLoopButton"
              onClick={() => handleStop()}
            >
              Stop Loop
            </Button>
          </div>
        </>
      ) : (
        <div className="pausedOptions">
          <div className="header">
            <div className="title">Loop Paused</div>
            <div className="iteration">
              Iteration:&nbsp;{iterationCount !== null ? iterationCount : 0}
            </div>
          </div>
          <div className="pausedBody">
            <Button
              variant="contained"
              className="stopLoopButton"
              onClick={() => handleLoopStep()}
            >
              fit next file
            </Button>
            <Button
              variant="contained"
              className="stopLoopButton"
              onClick={() => handleLoopRun()}
            >
              fit all files
            </Button>
            <Button
              variant="contained"
              className="stopLoopButton"
              onClick={() => handleStop()}
            >
              stop loop
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoopStopWindow;
