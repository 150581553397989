/**
 * @category LeftSide
 * @module Files/fileProcessing/processingLogic
 * @description This file is a list of functions that provide essential functionality to file processing and
 * loading.
 */
import { anyUnitToCm1, hasProperty } from "../../../../utils/helpers";

/**
 * This function returns a promise that resolves to a string containing the contents of the file. If the file
 * could not be read, it will generate a warning object, set the warnings, set the new warning count and
 * console log the error.
 * @function
 * @param {file} file - This is a file that will be read.
 * @param {Function} generateWarningObject - This function generates a warning object.
 * @param {Function} setWarnings - This function sets the warnings. Needed for generateWarningObject.
 * @param {Function} setNewWarningCount - This function sets the new warning count. Needed for generateWarningObject.
 * @returns {Promise<string>} Returns a promise that resolves to a string containing the contents of the file.
 */
export function readFile(
  file,
  generateWarningObject,
  setWarnings,
  setNewWarningCount,
  toast = () => {},
  recordedErrorLog
) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const fileContent = event.target.result;

        resolve(fileContent);
      } catch (error) {
        reader.abort();
        toast("File processing error.");
        generateWarningObject(
          `File ${
            file && hasProperty(file, "name")
              ? file.name
              : "'Could-not-retrieve-the-name'"
          } could not be processed`,
          2,
          setWarnings,
          setNewWarningCount
        );
        recordedErrorLog("THERE WAS AN ERROR READING A FILE: ", error);
      }
    };
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Error reading the file."));
    };
    reader.readAsText(file);
  });
}

function createLineRegex(colCount, separatorString) {
  // The base pattern for a column (matches a number that might be negative or decimal)
  const numberPattern = `-?\\d+(\\.)?(\\d+)?([eE][+-]?\\d+)?`;

  // Build the regex by repeating the number pattern colCount times, separated by separatorString
  let regexPattern = `^${numberPattern}`;
  for (let i = 1; i < colCount; i++) {
    regexPattern += `${separatorString}${numberPattern}`;
  }

  // Append the line ending pattern to handle possible carriage return characters
  regexPattern += `(\\r|\\\\r)?$`;

  return regexPattern;
}

/**
 * This function takes in file content as a string and option object and returns a list of data points
 * generated from them.
 * @function
 * @param {string} fileContent - This is a string containing the contents of the file.
 * @param {object} options - This is an object containing the options for processing the file.
 * @returns {object[]} Returns a list of data points generated from the file content and options.
 */
export function processFileWithOptions(
  fileContent,
  options,
  recordedErrorLog = () => {}
) {
  try {
    const separatorString = options.separator === "tab" ? "\\t+" : "\\s+";
    const separatorReg = new RegExp(separatorString);

    let contentToUse = fileContent;
    let lineRegex = createLineRegex(options.colCount, separatorString);

    if (!options.autoRemove) {
      const lines = contentToUse.split("\n");
      contentToUse = lines.slice(options.manualLineStart).join("\n");
    }

    const dataPointPattern = new RegExp(lineRegex);

    if (options.autoRemove) {
      const lines = fileContent.split("\n");

      // Find the index of the first line that looks like a data point
      const firstDataLineIndex = lines.findIndex((line) =>
        dataPointPattern.test(line)
      );

      // If no data point line was found, return an empty string
      if (firstDataLineIndex === -1) {
        return null;
      }

      // Join all the lines after the first data point line into a single string
      contentToUse = lines.slice(firstDataLineIndex).join("\n");
    }

    const data = contentToUse
      .trim()
      .split("\n")
      .map((line) => {
        const allColumns = line.trim().split(separatorReg);

        if (allColumns.length !== options.colCount) {
          throw new Error(
            "Parsed column count does not match selected column count."
          );
        }

        const xColNum = options.xColNum;
        const yColNum = options.yColNum;
        const eColNum = options.eColNum;

        let xVal = parseFloat(allColumns[xColNum - 1]);
        let yVal = parseFloat(allColumns[yColNum - 1]);

        if (options.shift) {
          const xShiftingVal = parseFloat(options.xShiftVal);
          const yShiftingVal = parseFloat(options.yShiftVal);

          switch (options.xShift) {
            case "val-x":
              xVal = xShiftingVal - xVal;
              break;
            case "x+val":
              xVal = xVal + xShiftingVal;
              break;
            case "x-val":
              xVal = xVal - xShiftingVal;
              break;
            case "x*val":
              xVal = xShiftingVal * xVal;
              break;
            default: // if set to "none"
              break;
          }
          switch (options.yShift) {
            case "val-x":
              yVal = yShiftingVal - yVal;
              break;
            case "x+val":
              yVal = yVal + yShiftingVal;
              break;
            case "x-val":
              yVal = yVal - yShiftingVal;
              break;
            case "x*val":
              yVal = yShiftingVal * yVal;
              break;
            default: // if set to "none"
              break;
          }
        }

        if (eColNum === "none") {
          return {
            x: xVal,
            y: yVal,
          };
        } else {
          return {
            x: xVal,
            y: yVal,
            e: allColumns[eColNum - 1],
          };
        }
      })
      .filter((coords) => {
        if (hasProperty(coords, "e")) {
          return (
            coords.x != null &&
            !Number.isNaN(coords.x) &&
            coords.e != null &&
            !Number.isNaN(coords.e) &&
            coords.y != null &&
            !Number.isNaN(coords.y)
          );
        } else {
          return (
            coords.x != null &&
            !Number.isNaN(coords.x) &&
            coords.y != null &&
            !Number.isNaN(coords.y)
          );
        }
      })
      .map((coords) => {
        let xaxisValue = anyUnitToCm1(options.xUnit, coords.x);

        return { ...coords, x: xaxisValue };
      });

    if (data.length > 0) {
      return data;
    } else {
      return null;
    }
  } catch (e) {
    recordedErrorLog("ERROR PARSING FILE DATA WITH OPTIONS: ", e);
    return null;
  }
}

/**
 * This function checks if file was marked as solved.
 * @function
 * @param {file} fileContent - This is a file that we want to check if it was solved already.
 * @returns {boolean} Returns true if file was marked as solved, false otherwise.
 */
export function checkIfSolved(file) {
  if (hasProperty(file, "solved") && file.solved) {
    return true;
  } else {
    return false;
  }
}

/**
 * This function copies an array of files with no references kept.
 * @function
 * @param {Array.<file>} fileContent - This is a file array that we want to copy.
 * @returns {Array.<file>} This is a copy of file array that no longer has reference to old array.
 */
export function deepCopyFiles(files) {
  const newFiles = structuredClone(files);

  for (let i = 0; i < files.length; i++) {
    const oldFile = files[i];

    if (hasProperty(oldFile, "solved")) {
      newFiles[i].solved = oldFile.solved;
    }
  }

  return newFiles;
}

/**
 * This function finds next available index of unsolved file.
 * @function
 * @param {Array.<object>} fileList - Array of objects that contain information about files being processed.
 * @returns {number} Returns an index of next unsolved file, if all the files are solved, returns null.
 */
export function getNextAvailableIndex(fileList) {
  let newIndex = null;

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];

    if (!hasProperty(file, "solved") || !file.solved) {
      newIndex = i;
      break;
    }
  }

  return newIndex;
}
