import React from "react";
import "./loop.scss";
import { FormattedText } from "../../../utils/helpers";

function TrackedParamDisplay(props) {
  const { listOfTrackables } = props;

  return (
    <div className="allTrackedParams">
      {listOfTrackables.map((modelSet) => {
        return (
          <div className="modelSet" key={modelSet.FE_ID}>
            <div className="title">{modelSet.modelName}&nbsp;:</div>
            <div className="parameters">
              {modelSet.selectedParams.map((parameterToTrack, index) => {
                return (
                  <div className="trackedParameter" key={index}>
                    {parameterToTrack.rec ? (
                      <div className="content">
                        <div className="paramName">
                          <FormattedText input={parameterToTrack.name} />
                        </div>
                        <div className="row">
                          Row {parameterToTrack.rowIndex + 1}
                        </div>
                      </div>
                    ) : (
                      <div className="content">
                        <div className="paramName">
                          <FormattedText input={parameterToTrack.name} />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TrackedParamDisplay;
