import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [authToken, setAuthToken] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [packages, setPackages] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  // BE URL Testing
  const [baseBE_URL, setBaseBE_URL] = useState(
    window.location.hostname === "app.speqqle.com"
      ? "wss://beprod1.speqqle.com/ws"
      : "wss://belab.speqqle.com/ws"
  );
  // const [baseBE_URL, setBaseBE_URL] = useState("wss://beprod1.speqqle.com/ws");

  const resetAuth = () => {
    setAuthToken("");
    setIsAdmin(false);
    setIsFirstLogin(true);
    setIsActive(false);
    setPackages([]);
    setCurrentUser(null);
    // setIsAuthReady(false);
  };

  useEffect(() => {
    const authTokenSession = JSON.parse(
      window.sessionStorage.getItem("authToken")
    );
    if (authTokenSession != null) {
      setAuthToken(authTokenSession);
    }

    const isAdminSession = JSON.parse(window.sessionStorage.getItem("isAdmin"));
    if (isAdminSession != null) {
      setIsAdmin(isAdminSession);
    }

    const isActiveSession = JSON.parse(
      window.sessionStorage.getItem("isActive")
    );
    if (isActiveSession != null) {
      setIsActive(isActiveSession);
    }

    const packagesSession = JSON.parse(
      window.sessionStorage.getItem("packages")
    );
    if (packagesSession != null) {
      setPackages(packagesSession);
    }

    const currentUserSession = JSON.parse(
      window.sessionStorage.getItem("currentUser")
    );
    if (currentUserSession != null) {
      setCurrentUser(currentUserSession);
    }

    const isFirstLoginSession = JSON.parse(
      window.sessionStorage.getItem("isFirstLogin")
    );
    if (isFirstLoginSession != null) {
      setIsFirstLogin(isFirstLoginSession);
    }

    const baseBE_URLSession = JSON.parse(
      window.sessionStorage.getItem("baseBE_URL")
    );
    if (baseBE_URLSession != null) {
      setBaseBE_URL(baseBE_URLSession);
    }

    setIsAuthReady(true);
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("authToken", JSON.stringify(authToken));
  }, [authToken]);

  useEffect(() => {
    window.sessionStorage.setItem("isAdmin", JSON.stringify(isAdmin));
  }, [isAdmin]);

  useEffect(() => {
    window.sessionStorage.setItem("isActive", JSON.stringify(isActive));
  }, [isActive]);

  useEffect(() => {
    window.sessionStorage.setItem("packages", JSON.stringify(packages));
  }, [packages]);

  useEffect(() => {
    window.sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
  }, [currentUser]);

  useEffect(() => {
    window.sessionStorage.setItem("isFirstLogin", JSON.stringify(isFirstLogin));
  }, [isFirstLogin]);

  useEffect(() => {
    window.sessionStorage.setItem("baseBE_URL", JSON.stringify(baseBE_URL));
  }, [baseBE_URL]);

  const value = {
    resetAuth,
    authToken,
    setAuthToken,
    isAdmin,
    setIsAdmin,
    isActive,
    setIsActive,
    isAuthReady,
    setIsAuthReady,
    packages,
    setPackages,
    currentUser,
    setCurrentUser,
    isFirstLogin,
    setIsFirstLogin,
    baseBE_URL,
    setBaseBE_URL,
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
