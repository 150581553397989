import React, { useState, useContext, useEffect, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableGraphWindow from "./DraggableGraphWindow";
import { DashboardContext } from "../../context/DashboardContext";
import { GraphContext } from "../../context/GraphContext";
import { WebSocketContext } from "../../context/WebSocketContext";
import { GeneralContext } from "../../context/GeneralContext";
import { AuthContext } from "../../context/AuthContext";
import {
  deepCopy,
  getModelById,
  replaceModelById,
} from "../leftSide/Models/modelLogic";
import Modal from "react-modal";
import {
  // containedModelIDsInGraphs,
  // createModelDistPayload,
  createModelList,
  createGraphModelEntry,
  generatePlotData,
  checkModelAndLoad,
} from "./graphLogic";
import {
  isDeepEqual,
  // generateWarningObject,
  updateOrAddCurve,
  produceCurveObject,
  findBestMatchingCurve,
  hasProperty,
  // getRangesForModelsFromGraphs,
  // getReturnedCurveDictionary,
  // replaceModelPlotData,
  getShortName,
  adjustModalPositionAndSize,
  // generateModelsRangesQuantPairsForModelDist,
  // checkBool,
  vwToPixels,
  vhToPixels,
} from "../../utils/helpers";
import {
  // getListOfTermsToUpdate,
  performChi2Message,
  // updatedChi2,
} from "../rightSide/fit/fitLogic";
import { DEFAULT_GRAPH } from "../../utils/constants";
import { processFileWithOptions } from "../leftSide/Files/fileProcessing/processingLogic";
import FitLoaderComponent from "../commonComponents/FitLoaderComponent";
// import FitStopWindow from "./FitStopWindow";
// import LoopStopWindow from "./loop/LoopStopWindow";

function GraphContainer() {
  const {
    uploadedFiles,
    // setUploadedFiles,
    fileID,
    // setFileID,
    modelData,
    setModelData,
    // setWarnings,
    // setNewWarningCount,
    // valueGroups,
    // setValueGroups,
    // fitIteration,
    // setFitIteration,
    allLocalModels,
    chi2ReqAllowed,
    setChi2ReqAllowed,
    chi2QueRef,
    addGraphModalOpen,
    setAddGraphModalOpen,
  } = useContext(DashboardContext);
  const {
    graphs,
    setGraphs,
    graphId,
    setGraphId,
    // updatedModelFE_IDs,
    // setUpdatedModelFE_IDs,
    requestedFitModel,
    // setRequestedFitModel,
    chi2Terms,
    // setChi2Terms,
    selectedChi2Terms,
    isGraphsReady,
    zIndices,
    setZIndices,
  } = useContext(GraphContext);
  const {
    lastJsonMessage,
    sendJsonMessage,
    isFitOngoing,
    isLoopOngoing,
    // setIsFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
  } = useContext(WebSocketContext);
  // const { limitedToast, limitedSucessToast, recordedErrorLog } =
  const { recordedErrorLog } = useContext(GeneralContext);
  const { currentUser } = useContext(AuthContext);
  const [graphModalIsOpenLocal, setGraphModalIsOpenLocal] = useState(false);
  // const [fitModalIsOpen, setFitModalIsOpen] = useState(false);
  const [selectFileList, setSelectFileList] = useState([]);
  const [selectModelList, setSelectModelList] = useState([]);
  const localFileID = useRef(fileID);
  const localGraphID = useRef(graphId);
  const localGraphs = useRef(graphs);
  // const localValueGroups = useRef(valueGroups);
  // const requestedModel = useRef(null);
  // const requestedModelsUpdate = useRef([]);
  const requestedFitModelsLocal = useRef([]);
  // const updatePending = useRef(false);
  // const updateIDWaitList = useRef([]);
  const graphSection = useRef();
  // const fitButton = useRef();
  const [smallWindow, setSmallWindow] = useState({
    height: Math.max(window.innerWidth * 0.3, 220),
    width: Math.max(window.innerWidth * 0.4, 260),
  });
  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  const [update, setUpdate] = useState(false);
  // const chiTermsToUpdate = useRef([]);
  const [updateChiTerms, setUpdateChiTerms] = useState(false);

  useEffect(() => {
    localFileID.current = fileID;
  }, [fileID]);

  // We do this chi2 update here instead of in the fit, because it takes a bit of time to unlock the websocket
  // to allow other requests straight after fit.
  useEffect(() => {
    // if (updateChiTerms && chiTermsToUpdate.current.length > 0) {
    if (updateChiTerms) {
      if (selectedChi2Terms.length > 0) {
        const termsToSend = chi2Terms.filter((term) => {
          if (
            selectedChi2Terms.some((id) => id === term.id) &&
            term.weight > 0
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (termsToSend.length > 0) {
          performChi2Message(
            termsToSend,
            modelData,
            uploadedFiles,
            sendJsonMessage,
            currentUser,
            recordedErrorLog,
            chi2ReqAllowed,
            setChi2ReqAllowed,
            chi2QueRef
          );
        }
      }

      // chiTermsToUpdate.current = [];
      setUpdateChiTerms(false);
    }
  }, [updateChiTerms]);

  // Update window sizes when browser window changes
  useEffect(() => {
    const handleResize = () => {
      setSmallWindow({
        height: Math.max(window.innerWidth * 0.3, 220),
        width: Math.max(window.innerWidth * 0.4, 260),
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isGraphsReady && graphs.length === 0) {
      setGraphs([DEFAULT_GRAPH]);
    }
  }, [isGraphsReady]);

  useEffect(() => {
    if (graphId != localGraphID.current) {
      localGraphID.current = graphId;
    }
  }, [graphId]);

  // useEffect(() => {
  //   if (!isDeepEqual(valueGroups, localValueGroups.current)) {
  //     localValueGroups.current = valueGroups;
  //   }
  // }, [valueGroups]);

  useEffect(() => {
    if (addGraphModalOpen) {
      const modalPosition = {
        top: vhToPixels(3),
        right: vwToPixels(15),
        left: "auto",
      };
      const modalSize = { width: 285, height: 350 };

      const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

      setModalPlaceAndSize(adjusted);
      setGraphModalIsOpenLocal(true);
    }
  }, [addGraphModalOpen]);

  function handleNewModelCurve(jsonMessage, details) {
    try {
      const foundData = jsonMessage.Model.SendCurve.find(
        (curve) =>
          curve.modelid == details.requestDetails.modelId &&
          curve.curves[0].quantity == details.requestDetails.quantity
      );

      if (foundData != undefined) {
        handleModelDistAdd(
          foundData,
          false,
          details.graphDetails.defaultMin,
          details.graphDetails.defaultMax
        );
      } else {
        console.log("DATA NOT FOUND FOR MODEL CURVE");
      }
    } catch (error) {
      recordedErrorLog("Error handling new model curve response: ", error);
    }
  }

  // function handleMultiIdDistUpdate(jsonMessage) {
  //   try {
  //     // if (updatePending.current) {
  //     // We extract coordinate set from the message
  //     const coordSets = jsonMessage.Model.SendCurve;

  //     const dict = getReturnedCurveDictionary(coordSets);
  //     const modelIds = Object.keys(dict);
  //     let graphsCopy = deepCopy(localGraphs.current);
  //     let modelsCopy = deepCopy(modelData);

  //     // We loop through graphs in order to update them
  //     for (let i = 0; i < graphsCopy.length; i++) {
  //       const min = graphsCopy[i].layout.xaxis.range[0];
  //       const max = graphsCopy[i].layout.xaxis.range[1];

  //       // We loop through contained model ids in graphs to check which ones received updated data
  //       for (let j = 0; j < graphsCopy[i].containedModels.length; j++) {
  //         const modelQuantitySet = graphsCopy[i].containedModels[j];

  //         // We check if current model id from graph is contained in updates
  //         if (
  //           modelIds.some((key) => parseInt(key) === modelQuantitySet.modelId)
  //         ) {
  //           const bestCurve = findBestMatchingCurve(
  //             dict[modelQuantitySet.modelId][modelQuantitySet.quantity],
  //             min,
  //             max,
  //             modelQuantitySet.quantity
  //           );

  //           modelsCopy = modelsCopy.map((modelToChange) => {
  //             if (modelToChange.FE_ID === modelQuantitySet.modelId) {
  //               const modelToUpdate = modelToChange;

  //               modelToUpdate.curves = updateOrAddCurve(
  //                 modelToUpdate.curves,
  //                 bestCurve.curve
  //               );
  //               return modelToUpdate;
  //             } else {
  //               return modelToChange;
  //             }
  //           });

  //           graphsCopy[i].plotData = replaceModelPlotData(
  //             graphsCopy[i].plotData,
  //             bestCurve,
  //             modelQuantitySet.modelId,
  //             modelQuantitySet.quantity
  //           );
  //         }
  //       }
  //     }

  //     localGraphs.current = graphsCopy;
  //     setGraphs(graphsCopy);
  //     setModelData(modelsCopy);

  //     if (updateIDWaitList.current.length > 0) {
  //       // handle ids from wait list here
  //       handleMultipleIdUpdate(updateIDWaitList.current);
  //       updateIDWaitList.current = [];
  //     }

  //     // We reset the pending update flag to false
  //     // THIS DOES NOT GET RESET ALWAYS, MAINLY ON SLOW PCS
  //     updatePending.current = false;
  //     // }
  //   } catch (error) {
  //     recordedErrorLog("Error handling new model curve response: ", error);
  //   }
  // }

  // function handleFitMessage(jsonMessage) {
  //   try {
  //     if (hasProperty(jsonMessage, "Error") && jsonMessage.Error.length > 0) {
  //       setUpdateChiTerms(true);
  //       setIsFitOngoing(false);
  //       setFitIteration(null);
  //       // Timeout here to make sure this is displayed, as the warnings and erros are generally caught and
  //       // displayed in another useEffect hook
  //       setTimeout(() => {
  //         limitedToast(`The fit has failed.`);
  //       }, 10);
  //       deleteRequestDataEntry(lastJsonMessage.requestID);
  //     } else {
  //       const fitSection = jsonMessage.Fit;
  //       if (hasProperty(fitSection, "fitactive")) {
  //         setIsFitOngoing(false);
  //         setFitIteration(null);
  //         deleteRequestDataEntry(lastJsonMessage.requestID);
  //       } else if (fitSection.fit.fit_done) {
  //         const fitStatus = fitSection.fit;
  //         handleFitResponse(jsonMessage, true, true);
  //         setUpdateChiTerms(true);
  //         setIsFitOngoing(false);
  //         setFitIteration(null);
  //         limitedSucessToast(
  //           `The fit was finished succesfully with ${fitStatus.n_iterations} iterations.`
  //         );
  //         generateWarningObject(
  //           `The fit was finished succesfully with ${fitStatus.n_iterations} iterations.`,
  //           0,
  //           setWarnings,
  //           setNewWarningCount
  //         );
  //         deleteRequestDataEntry(lastJsonMessage.requestID);
  //       } else {
  //         const fitStatus = fitSection.fit;
  //         setFitIteration(fitStatus.n_iterations);
  //         handleFitResponse(jsonMessage, false, false);

  //         const newTerms = jsonMessage.Fit.chi2.chi2s;
  //         const updatedTerms = updatedChi2(newTerms, chi2Terms);
  //         setChi2Terms(updatedTerms);
  //       }
  //     }
  //   } catch (error) {
  //     recordedErrorLog("Error handling Fit message: ", error);
  //   }
  // }

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "requestID")) {
          const requestData = retrieveRequestData(lastJsonMessage.requestID);
          if (requestData !== null) {
            switch (requestData.type) {
              case "new-model-curve-with-new-graph":
                handleNewModelCurve(lastJsonMessage, requestData);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              // case "multiple-id-dist-update":
              //   handleMultiIdDistUpdate(lastJsonMessage);
              //   deleteRequestDataEntry(lastJsonMessage.requestID);
              //   break;
              // case "autofit":
              //   handleFitResponse(lastJsonMessage, true, false);
              //   deleteRequestDataEntry(lastJsonMessage.requestID);
              //   break;
              // case "fit-message":
              //   handleFitMessage(lastJsonMessage);
              //   break;
              default:
                break;
            }
          }
        } else {
          throw new Error("Request ID was not attached to the json message.");
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json Message handling error in useEffect: ",
        error
      );
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    requestedFitModelsLocal.current = requestedFitModel;
  }, [requestedFitModel]);

  useEffect(() => {
    localGraphs.current = graphs;
    if (Object.keys(zIndices).length === 0 && localGraphs.current.length > 0) {
      setZIndices(
        deepCopy(localGraphs.current).reduce((acc, win) => {
          acc[win.id] = 0;
          return acc;
        }, {})
      );
    }
    setUpdate(!update);
  }, [graphs]);

  // useEffect(() => {
  //   if (updatedModelFE_IDs.length > 0) {
  //     const idsToUpdate = containedModelIDsInGraphs(
  //       updatedModelFE_IDs,
  //       localGraphs.current
  //     );
  //     if (!isFitOngoing && !isLoopOngoing) {
  //       handleMultipleIdUpdate(idsToUpdate);

  //       if (chi2Terms.length > 0 && selectedChi2Terms.length > 0) {
  //         const termsToSend = chi2Terms.filter((term) => {
  //           if (
  //             selectedChi2Terms.some((id) => id === term.id) &&
  //             term.weight > 0
  //           ) {
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         });

  //         if (termsToSend.length > 0) {
  //           performChi2Message(
  //             termsToSend,
  //             modelData,
  //             uploadedFiles,
  //             sendJsonMessage,
  //             currentUser,
  //             recordedErrorLog,
  //             chi2ReqAllowed,
  //             setChi2ReqAllowed,
  //             chi2QueRef
  //           );
  //         }
  //       }
  //     }

  //     setUpdatedModelFE_IDs([]);
  //   }
  // }, [updatedModelFE_IDs]);

  // const handleFitResponse = (
  //   response,
  //   isDone = true,
  //   requestChiUpdate = false
  // ) => {
  //   if (hasProperty(response, "Error") && response.Error.length > 0) {
  //     requestedFitModelsLocal.current = [];
  //     setRequestedFitModel(requestedFitModelsLocal.current);
  //   } else if (requestedFitModelsLocal.current.length > 0) {
  //     try {
  //       const newTerms = response.Fit.chi2.chi2s;

  //       let updatedTerms = updatedChi2(newTerms, chi2Terms);

  //       const termsLeftToUpdate = [];

  //       const termsToUpdateAfterFit = [];

  //       updatedTerms.forEach((term) => {
  //         if (
  //           !newTerms.some((newTerm) => {
  //             return (
  //               term.modelId === newTerm.ModelID &&
  //               term.fileId === newTerm.DataID &&
  //               term.quantity === newTerm.Dist
  //             );
  //           })
  //         ) {
  //           termsLeftToUpdate.push(term);
  //         }
  //         if (requestChiUpdate) {
  //           if (hasProperty(term, "needsUpdate") && term.needsUpdate) {
  //             termsToUpdateAfterFit.push(term);
  //           }
  //         }
  //       });

  //       // if (requestChiUpdate && termsToUpdateAfterFit.length > 0) {
  //       //   chiTermsToUpdate.current = termsToUpdateAfterFit;
  //       // }

  //       if (termsLeftToUpdate.length > 0) {
  //         // FOR NOW NO ERROR, BUT LATER WE NEED TO RETRIEVE ALL THE TERMS WITH FIT, NOT JUST THE ONES FOR THE SENT
  //         // FILE

  //         // limitedToast("Chi2 retrieval error.");
  //         generateWarningObject(
  //           "Not all the Chi2 Terms were updated in request. Please report this to support.",
  //           1,
  //           setWarnings,
  //           setNewWarningCount
  //         );

  //         // If we perform chi 2 message here instead of throwing an error, we get into infinite request loop
  //         // if BE never returns that chi 2 due to an error
  //         // performChi2Message(
  //         //   termsLeftToUpdate,
  //         //   modelData,
  //         //   uploadedFiles,
  //         //   sendJsonMessage
  //         // );
  //       }

  //       setChi2Terms(updatedTerms);
  //     } catch (error) {
  //       recordedErrorLog(
  //         "There was an error retrieving chi2 values from fit message: ",
  //         error
  //       );
  //     }

  //     try {
  //       //Updating edges of the file
  //       const filesCopy = deepCopy(uploadedFiles);
  //       for (let i = 0; i < response.Fit.chi2.chi2s.length; i++) {
  //         const chi2 = response.Fit.chi2.chi2s[i];
  //         if (hasProperty(chi2, "autofitedge")) {
  //           for (let j = 0; j < filesCopy.length; j++) {
  //             if (filesCopy[j].ID === chi2.DataID) {
  //               const maxFromFit = chi2.autofitedge[1];
  //               const minFromFit = chi2.autofitedge[0];
  //               if (
  //                 !filesCopy[j].edges.some(
  //                   (edge) => edge.min === minFromFit && edge.max === maxFromFit
  //                 )
  //               ) {
  //                 filesCopy[j].edges.push({ min: minFromFit, max: maxFromFit });
  //                 filesCopy[j].edges = filesCopy[j].edges.filter(
  //                   (edge) => edge.min !== "" && edge.max !== ""
  //                 );
  //               }
  //             }
  //           }
  //         }
  //       }
  //       //Files edges updated from autofit, we can update the files now.
  //       setUploadedFiles(filesCopy);

  //       let remainingIDs = [...requestedFitModelsLocal.current];
  //       let copyOfModels = deepCopy(modelData);
  //       let copyOfGraphs = deepCopy(graphs);
  //       let updateModels = false;
  //       let updateGraphs = false;

  //       // This variable will check if there are any strange values from the BE for parameters that are not used in FE
  //       let strangeResults = false;

  //       // We are updating model parameters and curves separately, because when we make a fit request, more than
  //       // just requested curve model is returned - sub models don't necessarily require curve update, but their
  //       // parameter update needs to be taken into account

  //       // UPDATING MODEL PARAMETERS AND ROWS
  //       for (let i = 0; i < response.Model.SendModel.length; i++) {
  //         const modelDataFromResponse = response.Model.SendModel[i];

  //         // Check if requested model is actually received
  //         updateModels = true;

  //         // Find the model which needs updating
  //         const modelToUpdate = deepCopy(
  //           getModelById(modelDataFromResponse.modelid, copyOfModels)
  //         );

  //         if (
  //           hasProperty(modelDataFromResponse, "vdf") &&
  //           hasProperty(modelToUpdate, "vdfData")
  //         ) {
  //           modelToUpdate.vdfData = {
  //             ...modelToUpdate.vdfData,
  //             ...modelDataFromResponse.vdf,
  //           };
  //         }

  //         // Find non recuring params from model
  //         const nonRecParams = modelToUpdate.modelParams.filter(
  //           (param) => param.recuring == 0
  //         );

  //         // Get new parameters for the model, remove first entry, since it's not needed
  //         const modelParamsToUse = deepCopy(
  //           modelDataFromResponse.parameters.global
  //         );
  //         if (modelDataFromResponse.modeltype !== 0) {
  //           modelParamsToUse.shift(); // IF NOT A DL MODEL ONLY!
  //         }

  //         let recParamsForUpdate = [];
  //         const hasRec = hasProperty(modelToUpdate, "recTemplate");

  //         // From a model recuring parameter template, get a parameter with a smallest reffit id
  //         const smallestIDTemplateParam = hasRec
  //           ? modelToUpdate.recTemplate.params.reduce(
  //               (min, param) => (min.reffit_id < param.reffit_id ? min : param),
  //               modelToUpdate.recTemplate.params[0]
  //             )
  //           : null;

  //         // In this array we will keep all the found parameters and we will use it to check which parameters
  //         // have not been used
  //         let allFoundParams = [];

  //         // Loop through all the new parameters to sort them
  //         for (let i = 0; i < modelParamsToUse.length; i++) {
  //           const modelParam = modelParamsToUse[i];
  //           // If new parameter matches non recuring parameter reffit id, update the non recuring parameter
  //           if (
  //             nonRecParams.some(
  //               (param) => param.reffit_id == modelParam.reffit_id
  //             )
  //           ) {
  //             modelToUpdate.modelParams = modelToUpdate.modelParams.map(
  //               (param) => {
  //                 if (param.reffit_id == modelParam.reffit_id) {
  //                   return {
  //                     ...param,
  //                     value:
  //                       param.type !== "Checkbox"
  //                         ? modelParam.value
  //                         : checkBool(modelParam.value),
  //                   };
  //                 } else {
  //                   return param;
  //                 }
  //               }
  //             );

  //             // Add Non Recuring parameters to the list of found values
  //             allFoundParams.push(modelParam);
  //           } else {
  //             // If it doesn't match, that means the new parameter is potentially a recuring one, add it to
  //             // possibly recuring parameter array if its reffit id is equal or higher than the smallest template reffit id
  //             // this prevents of adding values which are guaranteed unfit for the recuring parameters
  //             if (
  //               hasRec &&
  //               modelParam.reffit_id >= smallestIDTemplateParam.reffit_id
  //             ) {
  //               recParamsForUpdate.push(modelParam);
  //             } else {
  //               if (modelParam.value !== 0) {
  //                 strangeResults = true;
  //               }
  //             }
  //           }
  //         }

  //         // The following actions are only relevant if model has recurring parameters
  //         if (hasRec) {
  //           // Find the new parameter with the largest reffit id
  //           const paramForUpdateWithLargestID = recParamsForUpdate.reduce(
  //             (max, param) => (max.reffit_id > param.reffit_id ? max : param),
  //             recParamsForUpdate[0]
  //           );

  //           // Find the recuring parameter from a template with the largest reffit id and recuring value combination for
  //           // given largest reffit id of parameter for updating
  //           const largestIDModelTemplateParam =
  //             modelToUpdate.recTemplate.params.reduce(
  //               (max, param) =>
  //                 (paramForUpdateWithLargestID.reffit_id - max.reffit_id) /
  //                   max.recuring >
  //                 (paramForUpdateWithLargestID.reffit_id - param.reffit_id) /
  //                   param.recuring
  //                   ? max
  //                   : param,
  //               modelToUpdate.recTemplate.params[0]
  //             );

  //           // Calculate maximum possible recuring table rows
  //           const maxPossibleRows = Math.floor(
  //             (paramForUpdateWithLargestID.reffit_id -
  //               largestIDModelTemplateParam.reffit_id) /
  //               largestIDModelTemplateParam.recuring
  //           );

  //           let rowsOfValues = [];

  //           // Loop until max possible rows are reached, <= is used because we calculate rows from 0
  //           for (let row = 0; row <= maxPossibleRows; row++) {
  //             let values = [];
  //             // loop through template parameters to match each parameter with
  //             for (
  //               let paramIndex = 0;
  //               paramIndex < modelToUpdate.recTemplate.params.length;
  //               paramIndex++
  //             ) {
  //               // Find the value according to a parameter from the template
  //               const param = modelToUpdate.recTemplate.params[paramIndex];
  //               const foundValue = recParamsForUpdate.find(
  //                 (paramToUpdate) =>
  //                   paramToUpdate.reffit_id ==
  //                   param.reffit_id + row * param.recuring
  //               );
  //               // Add recuring parameters to the list of found values
  //               allFoundParams.push(foundValue);
  //               // If the value is found, push it to the value row
  //               if (foundValue != undefined) {
  //                 values.push({
  //                   value:
  //                     param.type !== "Checkbox"
  //                       ? foundValue.value
  //                       : checkBool(foundValue.value),
  //                   reffit_id: param.reffit_id,
  //                 });
  //               }
  //             }
  //             // Push the row of values to the array of rows
  //             rowsOfValues.push(values);
  //           }

  //           // Find all the unincluded parameters
  //           const allNotIncluded = recParamsForUpdate.filter(
  //             (item) => !allFoundParams.includes(item)
  //           );

  //           if (allNotIncluded.some((param) => param.value !== 0)) {
  //             strangeResults = true;
  //           }

  //           // Check if results are not strange and if they are, create an error message for the user
  //           if (strangeResults) {
  //             limitedToast("Model parameter calculation error: E0001");
  //             generateWarningObject(
  //               "There was an error with model parameter calculations. Please report this to support. Error code: E0001",
  //               2,
  //               setWarnings,
  //               setNewWarningCount
  //             );
  //           }

  //           // Check if the last row is completely filled, if not, discard it
  //           if (
  //             rowsOfValues[rowsOfValues.length - 1].length !=
  //             modelToUpdate.recTemplate.params.length
  //           ) {
  //             rowsOfValues.pop();
  //           }

  //           // Loop through the array of new row values
  //           for (let rowIndex = 0; rowIndex < rowsOfValues.length; rowIndex++) {
  //             const rowValues = rowsOfValues[rowIndex];

  //             // Check if the row is in the recuring parameters table
  //             if (modelToUpdate.recParams.length > rowIndex) {
  //               // If the recuring parameters table contains this row, do the updates
  //               for (
  //                 let i = 0;
  //                 i < modelToUpdate.recParams[rowIndex].length;
  //                 i++
  //               ) {
  //                 // Find the value from row values that matches rec table parameter reffit id
  //                 const foundVal = rowValues.find(
  //                   (rowVal) =>
  //                     rowVal.reffit_id ==
  //                     modelToUpdate.recParams[rowIndex][i].reffit_id
  //                 );

  //                 // If the value is found, update rec table parameter and row values
  //                 if (foundVal != undefined) {
  //                   if (
  //                     Object.prototype.hasOwnProperty.call(
  //                       modelToUpdate.recParams[rowIndex][i],
  //                       "group"
  //                     )
  //                   ) {
  //                     const groupToUpdate =
  //                       modelToUpdate.recParams[rowIndex][i].group;

  //                     const updatedGroups = localValueGroups.current.map(
  //                       (group) => {
  //                         if (group.groupNumber === groupToUpdate) {
  //                           const maxVal =
  //                             group.hardMax < foundVal.value
  //                               ? null
  //                               : group.hardMax;
  //                           const minVal =
  //                             group.hardMin > foundVal.value
  //                               ? null
  //                               : group.hardMin;
  //                           return {
  //                             ...group,
  //                             value: foundVal.value,
  //                             hardMax: maxVal,
  //                             hardMin: minVal,
  //                           };
  //                         } else {
  //                           return group;
  //                         }
  //                       }
  //                     );

  //                     localValueGroups.current = updatedGroups;
  //                   }

  //                   modelToUpdate.recParams[rowIndex][i] = {
  //                     ...modelToUpdate.recParams[rowIndex][i],
  //                     value: foundVal.value,
  //                   };

  //                   modelToUpdate.recTableRows[rowIndex] = {
  //                     ...modelToUpdate.recTableRows[rowIndex],
  //                     [modelToUpdate.recParams[rowIndex][i].name]:
  //                       foundVal.value,
  //                   };
  //                 }
  //               }

  //               if (!isDeepEqual(localValueGroups.current, valueGroups)) {
  //                 setValueGroups(localValueGroups.current);
  //               }
  //             } else {
  //               // If row index of new values is greater than current recuring parameter table lenght, that means we
  //               // need to add additional parameter row from template
  //               let paramRow = [];
  //               let tableRow = {};

  //               // Loop through recuring parameters template
  //               for (
  //                 let i = 0;
  //                 i < modelToUpdate.recTemplate.params.length;
  //                 i++
  //               ) {
  //                 // Find the value from values row that matches template parameter reffit id
  //                 const foundVal = rowValues.find(
  //                   (rowVal) =>
  //                     rowVal.reffit_id ==
  //                     modelToUpdate.recTemplate.params[i].reffit_id
  //                 );
  //                 // If the value is found, add the paramter with value from row of values to temporary parameters
  //                 // If the value is not found, we still add entry to the table, but with value from the template - this
  //                 // prevents the creation of tables with undefined table cells
  //                 if (foundVal != undefined) {
  //                   paramRow.push({
  //                     ...modelToUpdate.recTemplate.params[i],
  //                     value: foundVal.value,
  //                   });
  //                   tableRow = {
  //                     ...tableRow,
  //                     [modelToUpdate.recTemplate.params[i].name]:
  //                       foundVal.value,
  //                   };
  //                 } else {
  //                   paramRow.push({
  //                     ...modelToUpdate.recTemplate.params[i],
  //                   });
  //                   tableRow = {
  //                     ...tableRow,
  //                     [modelToUpdate.recTemplate.params[i].name]:
  //                       modelToUpdate.recTemplate.params[i].value,
  //                   };
  //                 }
  //               }

  //               // When temporary recuring parameter and table row values are filled, update model with them
  //               modelToUpdate.recParams.push(paramRow);
  //               modelToUpdate.recTableRows.push(tableRow);
  //             }

  //             // Here we will check if there are any other rows remaining in the parameter table after we finished adding ones from BE. We will remove them if found
  //             if (rowIndex === rowsOfValues.length - 1) {
  //               modelToUpdate.recTableRows.splice(rowIndex + 1);
  //               modelToUpdate.recParams.splice(rowIndex + 1);
  //             }
  //           }
  //         }

  //         copyOfModels = replaceModelById(
  //           copyOfModels,
  //           modelToUpdate,
  //           modelDataFromResponse.modelid
  //         );
  //       }

  //       // UPDATING MODEL CURVES
  //       for (let i = 0; i < requestedFitModelsLocal.current.length; i++) {
  //         const requestedModelQuantitySet = requestedFitModelsLocal.current[i];
  //         const modelCurveFound = hasProperty(response.Model, "SendCurve")
  //           ? response.Model.SendCurve.filter(
  //               (modelCurve) =>
  //                 modelCurve.modelid === requestedModelQuantitySet.modelId &&
  //                 modelCurve.curves[0].quantity ===
  //                   requestedModelQuantitySet.quantity
  //             )
  //           : [];

  //         if (isDone) {
  //           remainingIDs = remainingIDs.filter(
  //             (id) =>
  //               id.modelId != requestedModelQuantitySet.modelId &&
  //               id.quantity != requestedModelQuantitySet.quantity
  //           );
  //         }
  //         // Check if requested model is actually received
  //         if (modelCurveFound.length > 0) {
  //           // Find the model which needs updating
  //           const modelToUpdate = deepCopy(
  //             getModelById(requestedModelQuantitySet.modelId, copyOfModels)
  //           );

  //           // Update model with new Curve
  //           // We need to check if the curve exists here. If we do fit and model is not displayed in graph, we don't
  //           // receive a curve back from BE.
  //           const curveObj = produceCurveObject(
  //             modelCurveFound[0].curves[0].coordinates,
  //             modelCurveFound[0].quantity
  //           );
  //           modelToUpdate.curves = updateOrAddCurve(
  //             modelToUpdate.curves,
  //             curveObj
  //           );

  //           copyOfModels = replaceModelById(
  //             copyOfModels,
  //             modelToUpdate,
  //             requestedModelQuantitySet.modelId
  //           );
  //           copyOfGraphs = copyOfGraphs.map((graph) => {
  //             if (
  //               graph.containedModels.some(
  //                 (modelQuantityPair) =>
  //                   modelQuantityPair.modelId ===
  //                     requestedModelQuantitySet.modelId &&
  //                   modelQuantityPair.quantity ===
  //                     requestedModelQuantitySet.quantity
  //               )
  //             ) {
  //               updateGraphs = true;
  //               graph.plotData = graph.plotData.map((data) => {
  //                 if (
  //                   hasProperty(data, "modelId") &&
  //                   data.modelId === requestedModelQuantitySet.modelId &&
  //                   data.quantity === requestedModelQuantitySet.quantity
  //                 ) {
  //                   let coordSetForData =
  //                     modelCurveFound[0].curves[0].coordinates.map((coord) => {
  //                       return {
  //                         x: parseFloat(coord.x),
  //                         y: parseFloat(coord.y),
  //                       };
  //                     });
  //                   const dataGenerated = generatePlotData(
  //                     coordSetForData,
  //                     "",
  //                     "",
  //                     data.line.color,
  //                     data.name,
  //                     null,
  //                     requestedModelQuantitySet.modelId,
  //                     null,
  //                     data.quantity
  //                   );
  //                   return {
  //                     ...data,
  //                     x: dataGenerated[0].x,
  //                     y: dataGenerated[0].y,
  //                   };
  //                 } else {
  //                   return data;
  //                 }
  //               });
  //             }
  //             return graph;
  //           });
  //         }
  //       }

  //       requestedFitModelsLocal.current = remainingIDs;
  //       setRequestedFitModel(requestedFitModelsLocal.current);
  //       if (updateModels) {
  //         setModelData(copyOfModels);
  //       }
  //       if (updateGraphs) {
  //         setGraphs(copyOfGraphs);
  //       }
  //     } catch (error) {
  //       recordedErrorLog("Error updating details from AutoFit: ", error);
  //     }
  //   }
  // };

  // const handleMultipleIdUpdate = (idList) => {
  //   if (idList.length > 0) {
  //     try {
  //       if (updatePending.current) {
  //         updateIDWaitList.current = [
  //           ...new Set([...updateIDWaitList.current, ...idList]),
  //         ];
  //       } else {
  //         // requestedModelsUpdate.current = idList;

  //         const rangesForModels = getRangesForModelsFromGraphs(graphs);

  //         const paramsForPayload = generateModelsRangesQuantPairsForModelDist(
  //           idList,
  //           rangesForModels,
  //           modelData
  //         );

  //         const payload = createModelDistPayload(
  //           paramsForPayload.models,
  //           rangesForModels,
  //           paramsForPayload.quantityPairs,
  //           currentUser
  //         );

  //         updatePending.current = true;
  //         sendJsonMessage(payload, { type: "multiple-id-dist-update" });
  //         // console.log("PAYLOAD FOR GRAPH UPDATE SENT: ", payload);
  //       }
  //     } catch (error) {
  //       recordedErrorLog("Multiple Id update failed: ", error);
  //     }
  //   }
  // };

  const handleModelDistAdd = (
    data,
    curvesFromModel = false,
    defaultMin,
    defaultMax
  ) => {
    try {
      const { modelid, curves } = data;

      const coordinates = curves[0].coordinates;
      const quantity = curves[0].quantity;

      const modelAdded = getModelById(modelid, modelData);

      let coordsToUse = coordinates;

      if (!curvesFromModel) {
        const curveObj = produceCurveObject(coordinates, quantity);
        modelAdded.curves = updateOrAddCurve(modelAdded.curves, curveObj);

        const newModels = replaceModelById(modelData, modelAdded, modelid);
        setModelData(newModels);

        const foundCurve = findBestMatchingCurve(
          modelAdded.curves,
          defaultMin,
          defaultMax,
          quantity
        );

        if (!foundCurve.bestFound) {
          recordedErrorLog("THE CURVE FOUND DOES NOT FIT THE NEED");
        }

        coordsToUse = foundCurve.curve.curve;
      }

      createGraphEntry(
        coordsToUse,
        modelAdded.displayName,
        null,
        modelid,
        "",
        "",
        null,
        quantity
      );
    } catch (error) {
      recordedErrorLog("Model curve adding failure: ", error);
    }
  };

  const handleCloseGraphModal = () => {
    setAddGraphModalOpen(false);
    setGraphModalIsOpenLocal(false);
  };

  const generateGraphId = () => {
    const newId = localGraphID.current;

    localGraphID.current = localGraphID.current + 1;
    setGraphId(localGraphID.current);
    return newId;
  };

  const deleteGraph = (idToDelete) => {
    try {
      localGraphs.current = localGraphs.current.filter(
        (graph) => graph.id != idToDelete
      );

      setGraphs(localGraphs.current);
    } catch (error) {
      recordedErrorLog("Graph deletion failure: ", error);
    }
  };

  const handleFileSelected = (id) => {
    try {
      const fileForGraph = uploadedFiles.find((file) => file.ID == id);

      const data = Object.prototype.hasOwnProperty.call(
        fileForGraph,
        "dataPoints"
      )
        ? fileForGraph.dataPoints
        : processFileWithOptions(
            fileForGraph.content,
            fileForGraph.options,
            recordedErrorLog
          );

      createGraphEntry(
        data,
        fileForGraph.name,
        fileForGraph.ID,
        null,
        fileForGraph.dataRangeMax,
        fileForGraph.dataRangeMin,
        fileForGraph.edges
      );

      handleCloseGraphModal();
    } catch (error) {
      recordedErrorLog("File addition to graph failure: ", error);
    }
  };

  const createGraphEntry = (
    data,
    name,
    fileID,
    modelID,
    rangeMax,
    rangeMin,
    edges,
    quantity
  ) => {
    try {
      const shortName = getShortName(name);

      const plotData = generatePlotData(
        data,
        rangeMin,
        rangeMax,
        "blue",
        shortName,
        fileID,
        modelID,
        edges,
        quantity
      );

      const layout = {
        xaxis: {
          title: { text: "Wavenumber [cm<sup>-1</sup>]" },
          mirror: "ticks",
          showline: true,
          linecolor: "#000",
          linewidth: 1,
        },
        yaxis: {
          title: { text: "Amplitude" },
          mirror: "ticks",
          showline: true,
          linecolor: "#000",
          linewidth: 1,
        },
        margin: {
          l: 60, // left margin
          r: 5, // right margin
          b: 35, // bottom margin
          t: 20, // top margin
          pad: 0, // padding between the plotting area and the axis labels
        },
        autosize: true,
        width: smallWindow.width - 2,
        height: smallWindow.height - 38,
        // TRANSITION CAUSES ISSUES WITH RERENDERING AND PRODUCES VISUAL BUGS
        // transition: {
        //   duration: 500,
        //   easing: "cubic-in-out",
        // },
        paper_bgcolor: "#edf7ff",
        legend: {
          x: 1,
          y: 1,
          xanchor: "right", // anchors the legend's right side to the x position
          yanchor: "top", // anchors the legend's top to the y position
          bgcolor: "rgba(255,255,255,0.8)", // semi-transparent white background
        },
      };

      if (fileID !== null) {
        layout.xaxis.range = [rangeMin, rangeMax];
      }

      // localFileID.current = localFileID.current + 1;

      // setFileID(localFileID.current);

      const localGraphId = generateGraphId();
      const multipliers = divideWithRemainder(localGraphId, 5);
      const position = {
        x: multipliers.remainder * 100,
        y: multipliers.integer * 100,
      };

      localGraphs.current = [
        ...localGraphs.current,
        {
          id: localGraphId,
          position: position,
          title: name,
          plotData: plotData,
          layout: layout,
          containedFiles: fileID != null ? [fileID] : [],
          containedModels:
            modelID != null ? [{ modelId: modelID, quantity: quantity }] : [],
        },
      ];

      setGraphs(localGraphs.current);

      // This line to updates the zIndex state
      setZIndices((prevZIndices) => {
        return {
          ...prevZIndices,
          [localGraphId]: Object.keys(prevZIndices).length,
        };
      });
    } catch (error) {
      recordedErrorLog("Graph entry creation failure: ", error);
    }
  };

  function handleModelClick(modelId, quantity) {
    try {
      const foundModel = getModelById(modelId, modelData);

      const defaultModel = allLocalModels.find(
        (defMod) => defMod.reffit_id === foundModel.reffitID
      );

      let defaultMin = -1000;
      let defaultMax = 1000;

      if (defaultModel !== undefined) {
        defaultMin = defaultModel.default_curve_min;
        defaultMax = defaultModel.default_curve_max;
      }

      // const potentialModelQuantitySet =
      checkModelAndLoad(
        foundModel,
        handleModelDistAdd,
        sendJsonMessage,
        defaultMin,
        defaultMax,
        quantity,
        currentUser,
        {
          type: "new-model-curve-with-new-graph",
          graphDetails: { defaultMin, defaultMax },
        }
      );

      // if (potentialModelQuantitySet !== null) {
      //   requestedModel.current = potentialModelQuantitySet;
      // }
      handleCloseGraphModal();
    } catch (error) {
      recordedErrorLog("Model click handler failure: ", error);
    }
  }

  useEffect(() => {
    if (modelData.length > 0) {
      setSelectModelList(
        createModelList(handleModelClick, modelData, createGraphModelEntry)
      );
    }
    if (modelData.length === 0) {
      setSelectModelList([]);
    }
  }, [modelData]);

  useEffect(() => {
    try {
      if (uploadedFiles.length > 0) {
        const fileList = uploadedFiles.map((file) => {
          return (
            <div
              key={file.ID}
              className="fileEntry"
              id="file-dist-entry"
              onClick={() => handleFileSelected(file.ID)}
            >
              {file.name}
            </div>
          );
        });

        setSelectFileList(fileList);
      } else {
        setSelectFileList([]);
      }

      let graphsCopy = deepCopy(localGraphs.current);
      let updateNeeded = false;

      for (let j = 0; j < graphs.length; j++) {
        const graph = graphsCopy[j];

        for (let i = 0; i < graph.containedFiles.length; i++) {
          const fileId = graph.containedFiles[i];

          const uploadedFile = uploadedFiles.find((file) => file.ID == fileId);

          if (uploadedFile != undefined) {
            const fileData = Object.prototype.hasOwnProperty.call(
              uploadedFile,
              "dataPoints"
            )
              ? uploadedFile.dataPoints
              : processFileWithOptions(
                  uploadedFile.content,
                  uploadedFile.options,
                  recordedErrorLog
                );

            const currentPlots = graph.plotData.filter(
              (plot) => plot.fileId == fileId
            );

            const plotColor = currentPlots[0].line.color;
            const plotName = currentPlots[0].name;

            const newPlotData = generatePlotData(
              fileData,
              uploadedFile.dataRangeMin,
              uploadedFile.dataRangeMax,
              plotColor,
              plotName,
              fileId,
              null,
              uploadedFile.edges,
              null
            );

            if (!isDeepEqual(currentPlots, newPlotData)) {
              graphsCopy[j].plotData = graphsCopy[j].plotData.filter(
                (plot) => plot.fileId != fileId
              );
              for (let i = 0; i < newPlotData.length; i++) {
                graphsCopy[j].plotData.push({
                  ...newPlotData[i],
                  line: currentPlots[0].line,
                  mode: currentPlots[0].mode,
                  type: currentPlots[0].type,
                });
              }
              updateNeeded = true;
            }
          }
        }
      }

      if (updateNeeded) {
        setGraphs(graphsCopy);
      }
    } catch (error) {
      recordedErrorLog(
        "Applying uploaded files update in useEffect failure: ",
        error
      );
    }
  }, [uploadedFiles]);

  function divideWithRemainder(number, divisor) {
    const remainder = number % divisor;
    const integer = Math.floor(number / divisor);
    return { remainder, integer };
  }

  const reorderZIndices = (currentZIndices, activeId) => {
    try {
      // Extract the current z-index of the active graph
      const activeZIndex = currentZIndices[activeId];

      // Create a new object to store the updated z-indices
      const newZIndices = {};

      // Assign z-indices to other graphs, decrementing their index if it's higher than the active graph's index
      Object.entries(currentZIndices).forEach(([id, zIndex]) => {
        if (id !== activeId) {
          newZIndices[id] = zIndex < activeZIndex ? zIndex : zIndex - 1;
        }
      });

      // Assign the highest z-index to the active graph
      const maxZIndex = Object.keys(currentZIndices).length - 1;
      newZIndices[activeId] = maxZIndex;

      return newZIndices;
    } catch (error) {
      recordedErrorLog("Z indiced reorder failure: ", error);
    }
  };

  const updateZIndex = (id) => {
    setZIndices((prevZIndices) => {
      return reorderZIndices(prevZIndices, id);
    });
  };

  const onDragEnd = () => {
    // nothing for now
    // We can also have onDragStart if we need to
  };

  // const handleGraphModalOpen = () => {
  //   const modalPosition = {
  //     top:
  //       addButton.current != null
  //         ? addButton.current.offsetParent.offsetTop + 10
  //         : 0,
  //     right: "auto",
  //     left:
  //       addButton.current != null
  //         ? addButton.current.offsetParent.offsetLeft
  //         : 0,
  //   };
  //   const modalSize = { width: 285, height: 350 };

  //   const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

  //   setModalPlaceAndSize(adjusted);
  //   setGraphModalIsOpen(true);
  // };

  // const handleFitStop = () => {
  //   sendJsonMessage(
  //     {
  //       User: currentUser.id,
  //       stopfit: true,
  //     },
  //     false
  //   );
  //   limitedSucessToast(`The fit was stopped after ${fitIteration} iterations.`);
  //   generateWarningObject(
  //     `The fit was stopped after ${fitIteration} iterations.`,
  //     0,
  //     setWarnings,
  //     setNewWarningCount
  //   );
  //   setIsFitOngoing(false);
  //   setFitIteration(null);
  // };

  // const handleLoopStop = () => {
  //   sendJsonMessage(
  //     {
  //       User: currentUser.id,
  //       stopfit: true,
  //     },
  //     false
  //   );
  //   limitedSucessToast(`The fit was stopped after ${fitIteration} iterations.`);
  //   generateWarningObject(
  //     `The fit was stopped after ${fitIteration} iterations.`,
  //     0,
  //     setWarnings,
  //     setNewWarningCount
  //   );
  //   setIsFitOngoing(false);
  //   setFitIteration(null);
  // };

  return (
    <div className="graphSection" ref={graphSection}>
      <div className="graphWindowBar">
        {/* <div
          className="addGraphButton"
          id="add-graph-button"
          ref={addButton}
          onClick={() => {
            handleGraphModalOpen();
          }}
        >
          Add Graph
        </div> */}
        <Modal
          isOpen={graphModalIsOpenLocal}
          onRequestClose={handleCloseGraphModal}
          shouldCloseOnOverlayClick={true}
          contentLabel="Model Modal"
          appElement={graphSection.current}
          style={{
            content: {
              width: modalPlaceAndSize.width,
              height: modalPlaceAndSize.height,
              top: modalPlaceAndSize.top,
              left: modalPlaceAndSize.left,
              right: modalPlaceAndSize.right,
            },
            overlay: {
              backgroundColor: "transparent",
              zIndex: "5000",
            },
          }}
        >
          <div className="distributionsModal">
            <div className="fileDistributions">
              <div className="fileDistTitle">From Files:</div>
              <div className="fileDistList" id="file-dist-list-from-add-graph">
                {selectFileList.length > 0
                  ? selectFileList
                  : "No Files Uploaded"}
              </div>
            </div>
            <hr />
            <div className="modelDistributions">
              <div className="modelDistTitle">From Models:</div>
              {isFitOngoing || isLoopOngoing ? (
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <FitLoaderComponent scale={50} />
                </div>
              ) : (
                <div
                  className="modelDistList"
                  id="model-dist-list-from-add-graph"
                >
                  {selectModelList.length > 0
                    ? selectModelList
                    : "No Models Added"}
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
      {/* Adding this check to make sure that context is loaded in, so that we don't try to created random graphs with
      bad data */}
      {isGraphsReady ? (
        <DndProvider backend={HTML5Backend}>
          <div
            style={{
              height: "80vh",
              position: "relative",
            }}
          >
            {localGraphs.current.map((graph) => {
              return (
                <DraggableGraphWindow
                  key={graph.id}
                  id={graph.id}
                  initialPosition={graph.position}
                  initialData={graph.plotData}
                  initialLayout={graph.layout}
                  initialSize={
                    hasProperty(graph, "size") ? graph.size : smallWindow
                  }
                  zIndex={
                    zIndices[graph.id] !== undefined ? zIndices[graph.id] : 0
                  }
                  onDragStart={updateZIndex}
                  onGraphClick={updateZIndex}
                  onDragEnd={onDragEnd}
                  title={graph.title}
                  deleteGraph={deleteGraph}
                />
              );
            })}
          </div>
        </DndProvider>
      ) : (
        <></>
      )}
      {/* {isFitOngoing && (
        <FitStopWindow
          iterationCount={fitIteration}
          handleStop={handleFitStop}
        />
      )} */}
    </div>
  );
}

export default GraphContainer;
