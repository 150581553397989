import React from "react";
import "./legal.scss";

function AlphaTestersManual() {
  return (
    <div className="legalPage">
      <h1>Alpha Testers User Manual</h1>

      <div className="section">
        <h2>Quick Start Guide</h2>
        <p>
          Not a fan of long manuals? Here&apos;s the short version of what you
          need to do:
        </p>
        <ul>
          <li>
            <strong>Step 1:</strong> Log in at{" "}
            <a
              href="https://app.speqqle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              app.speqqle.com
            </a>{" "}
            and activate the Alpha Testers package.
          </li>
          <li>
            <strong>Step 2:</strong> Explore the platform freely in Stage 1.
            Test every button, try to break it, and tell us what feels great and
            what doesn&apos;t.
          </li>
          <li>
            <strong>Step 3:</strong> Submit your Stage 1 Feedback on the{" "}
            <a
              href={`${window.location.origin}/feedback`}
              //   target="_blank"
              //   rel="noopener noreferrer"
            >
              Feedback Page
            </a>
            .
          </li>
          <li>
            <strong>Step 4:</strong> Complete your assigned task for Stage 2.
            We&apos;ll send you the data and task instructions after your Stage
            1 feedback is submitted.
          </li>
          <li>
            <strong>Step 5:</strong> Submit your Stage 2 Feedback on the{" "}
            <a
              href={`${window.location.origin}/feedback`}
              //   target="_blank"
              //   rel="noopener noreferrer"
            >
              Feedback Page
            </a>
            . Congrats, you&apos;re done!
          </li>
        </ul>
      </div>

      <div className="important">
        <p>
          <strong>Important:</strong> Don&apos;t forget to submit your Stage 1
          and Stage 2 feedback via the{" "}
          <a
            href={`${window.location.origin}/feedback`}
            // target="_blank"
            // rel="noopener noreferrer"
          >
            Feedback Page
          </a>
          . Without it, we can&apos;t improve the platform!
        </p>
      </div>

      <div className="section">
        <h2>1. Introduction</h2>
        <p>
          Welcome, brave alpha tester! You&apos;re about to embark on an
          exciting adventure where YOU are the hero. This isn&apos;t just any
          user manual &mdash; it&apos;s your secret map to explore, test, and
          challenge our platform to its limits. We&apos;ve packed this guide
          with essential pointers to help you find your way, but (plot twist!)
          we&apos;re not going to reveal everything. Why? Because part of the
          magic is letting you discover how things work on your own. This way,
          we get honest insights into which features are smooth sailing and
          which ones are more &quot;hmm, what does this button do?&quot;
          moments. So, get ready to click, scroll, and experiment. Report your
          &quot;aha!&quot; moments, your &quot;uh-ohs,&quot; and everything in
          between. We&apos;re counting on you to help us make this platform the
          best it can be. Adventure awaits &mdash; let&apos;s get started!
        </p>
      </div>

      <div className="section">
        <h2>2. Getting Started</h2>

        <h3>2.1. Accessing the Platform</h3>
        <ul>
          <li>
            Visit the platform&apos;s dedicated testing environment at{" "}
            <a
              href="https://app.speqqle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              app.speqqle.com
            </a>
            .
          </li>
          <li>
            Before registering, you must review and accept our terms of use and
            privacy policy.
          </li>
          <li>
            Create your user credentials by registering as an alpha tester.
            Ensure you provide accurate and valid information during the
            process.
          </li>
          <li>
            Log in using your newly created credentials to access the platform.
          </li>
          <li>
            Once logged in, activate the Alpha Testers package. This will grant
            you access to the full range of platform features for the duration
            of the alpha testing phase.
          </li>
        </ul>

        <h3>2.2. Navigation Overview</h3>
        <ul>
          <li>
            <strong>
              <a
                href={`${window.location.origin}/profile`}
                // href="https://app.speqqle.com/profile/"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                Profile
              </a>
            </strong>
            : View and edit your personal profile, delete your account, contact
            support, participate in polls, manage your active and past
            subscriptions, and track your testing progress.
          </li>
          <li>
            <strong>
              <a
                href={`${window.location.origin}/dashboard`}
                // href="https://app.speqqle.com/dashboard/"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                Dashboard
              </a>
            </strong>
            : Access the full suite of platform functionalities for the first
            and second parts of the testing phase.
          </li>
          <li>
            <strong>
              <a
                href={`${window.location.origin}/feedback`}
                // href="https://app.speqqle.com/feedback/"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                Feedback
              </a>
            </strong>
            : Submit bug reports, suggestions, and general feedback about your
            experience with the platform.
          </li>
        </ul>

        <h3>2.3. System Requirements</h3>
        <ul>
          <li>
            <strong>Supported Browsers:</strong> Google Chrome, Mozilla Firefox,
            Microsoft Edge (latest versions), and your favorite Linux and macOS
            browsers. If it&apos;s a well-known browser, you&apos;re probably
            good to go. (Sorry, Internet Explorer fans &mdash; it&apos;s time to
            move on probably.)
          </li>
          <li>
            <strong>Internet Connection:</strong> Make sure your
            connection&apos;s as solid as a rock. No one likes lag, especially
            during epic testing adventures. If your Wi-Fi is feeling wobbly,
            give it a pep talk or plug in an Ethernet cable for turbo speed.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>3. General Workflow</h2>

        <h3>3.1. Testing Stage One</h3>
        <p>
          Welcome to Stage One of alpha testing! This is your opportunity to
          explore the platform with fresh eyes. No hand-holding here &mdash;
          just you, your data, and your intuition. Our aim? To see what&apos;s
          clear, what&apos;s confusing, and what&apos;s missing.
        </p>
        <ul>
          <li>
            Start by heading to the{" "}
            <strong>
              <a
                href={`${window.location.origin}/dashboard`}
                // href="https://app.speqqle.com/dashboard/"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                Dashboard
              </a>
            </strong>{" "}
            and having a look around.
          </li>
          <li>
            Try out every button, toggle, and link. See what they do and how
            they work.
          </li>
          <li>
            Load your own data for analysis. If you don&apos;t have any, reach
            out to us and we&apos;ll provide sample data to get you started.
          </li>
          <li>
            Take notes as you explore. Highlight the moments that felt
            intuitive, the features you&apos;d like to see, and any areas that
            felt clunky or confusing.
          </li>
          <li>
            On the software page you can find a link to this manual on the top
            of the page and another button to quickly report feedback (this is a
            quick feedback option, not the general forms for stage 1 and 2.)
          </li>
        </ul>

        <h3>3.2. Submitting Stage 1 Feedback</h3>
        <p>
          Once you&apos;ve done your exploration, it&apos;s time to share your{" "}
          <strong>
            <a
              href={`${window.location.origin}/feedback`}
              //   href="https://app.speqqle.com/feedback/"
              //   target="_blank"
              //   rel="noopener noreferrer"
            >
              Feedback
            </a>
          </strong>
          . This is your chance to influence the platform&apos;s development.
          The more detailed your feedback, the better we can make the platform
          for everyone.
        </p>
        <ul>
          <li>
            Go to the{" "}
            <strong>
              <a
                href={`${window.location.origin}/feedback`}
                // href="https://app.speqqle.com/feedback/"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                Feedback
              </a>
            </strong>{" "}
            page and click the &quot;Submit 1st Feedback&quot; button.
          </li>
          <li>
            Be specific in your responses. Highlight the good, the bad, and the
            &quot;this could be better&quot; moments. If you have screenshots or
            files that can help illustrate your points, attach them!
          </li>
        </ul>

        <h3>3.3. Testing Stage Two</h3>
        <p>
          Stage Two is a bit more focused. This time, you&apos;ll be assigned a
          specific task. No guesswork required &mdash; you&apos;ll receive clear
          instructions and sample data to work with. This stage helps us
          understand how well users can follow task-based workflows.
        </p>
        <ul>
          <li>
            After submitting your Stage 1 feedback, we&apos;ll send you a task
            description along with the data you&apos;ll need.
          </li>
          <li>
            Work through the task step-by-step. As before, keep track of your
            observations, noting what&apos;s smooth, what&apos;s confusing, and
            any issues or missing features.
          </li>
        </ul>

        <h3>3.4. Submitting Stage 2 Feedback</h3>
        <p>
          Finished your task? Great work! Now it&apos;s time to share your
          experience. This step is crucial because it gives us insight into how
          users approach task-based analysis. Your input directly shapes how we
          improve the platform.
        </p>
        <ul>
          <li>
            Go to the{" "}
            <strong>
              <a
                href={`${window.location.origin}/feedback`}
                // href="https://app.speqqle.com/feedback/"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                Feedback
              </a>
            </strong>{" "}
            page and click the &quot;Submit 2nd Feedback&quot; button.
          </li>
          <li>
            Provide clear, detailed feedback on the experience. Attach
            screenshots or files if they&apos;ll help clarify your points.
          </li>
          <li>
            Congratulations! You&apos;ve completed both testing stages. Your
            efforts make a real impact, and we&apos;re grateful for your time
            and insights. Well done!
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>4. Core Features</h2>
        <p>
          The Speqqle platform is built on a robust base that provides essential
          tools for data analysis. For those who need more specialized
          functionality, we offer a range of extensions that can be added to the
          base platform, allowing users to customize their experience based on
          specific needs.
        </p>

        <h3>4.1. Speqqle Base</h3>
        <p>
          The Speqqle Base includes everything you need for efficient and
          flexible spectroscopic data analysis. Here&apos;s what you&apos;ll
          find in the base platform:
        </p>
        <ul>
          <li>
            <strong>Data Loading &amp; Manipulation</strong>: Load, shift,
            transform, and select units for your data with ease.
          </li>
          <li>
            <strong>Phenomenological Models</strong>: Use the
            &quot;Experiments&quot; tab to select models for transmission,
            reflection, ellipsometry, and terahertz time-domain spectroscopy.
            These models are essential for fitting and modeling experimental
            spectra of isotropic bulk materials.
          </li>
          <li>
            <strong>Customizable Graphs</strong>: Visualize your data and model
            outputs. One default graph is ready for you, but you can add as many
            as you need.
          </li>
          <li>
            <strong>Parameter Control</strong>: Use the &quot;Parameter&quot;
            tab to initialize and adjust parameters. Tailor your analysis to
            your specific needs.
          </li>
          <li>
            <strong>Fitting Tools</strong>: Use the &quot;Fit&quot; tab to add
            chi-square fits. Link multiple datasets to multiple experimental
            models, assign weights, and easily exclude datasets from a fit with
            a simple click.
          </li>
          <li>
            <strong>VDF (Variable Dielectric Function)</strong>: Use the
            Kramers-Kronig transformation in a model-independent way. This
            one&apos;s a bit of a puzzle, but we think you&apos;ll figure it
            out!
          </li>
          <li>
            <strong>Looping Capabilities</strong>: Run sequential fits over
            multiple datasets using the &quot;Loop&quot; tab. You&apos;ll need
            to define a chi-square first, but once that&apos;s done, you&apos;ll
            have access to powerful loop options. Let us know which ones you
            like best!
          </li>
          <li>
            <strong>Parameter Tracking</strong>: Track the evolution of a
            specific parameter (like sample temperature or excitation position)
            over the course of a looped analysis.
          </li>
          <li>
            <strong>Session Saving</strong>: Save your session and pick up right
            where you left off. This feature is also useful for bug reporting
            &mdash; just save your session and send it along with your bug
            report!
          </li>
        </ul>

        <h3>4.2. Speqqle Extensions</h3>
        <p>
          Need something more advanced? We&apos;ve got you covered with Speqqle
          Extensions. These optional add-ons provide specialized features for
          unique analytical needs. Here&apos;s a taste of what&apos;s available:
        </p>
        <ul>
          <li>
            <strong>Gas Absorption Spectroscopy</strong>: Analyze gas absorption
            spectra with the added power of database functionality.
          </li>
          <li>
            <strong>Coatings &amp; Multilayers</strong>: Analyze anisotropic
            bulk samples and multi-layered materials, giving you flexibility for
            even the most complex coatings.
          </li>
          <li>
            <strong>Near-Field Spectroscopy</strong>: Leverage standard models
            for near-field spectroscopic analysis to explore nanoscale material
            properties.
          </li>
          <li>
            <strong>Raman Analysis</strong>: Automatically fit the Raman Stokes
            (and Anti-Stokes, if available) response, saving you time and
            effort.
          </li>
        </ul>

        <h3>4.3. Alpha Testing Features</h3>
        <p>
          During the alpha testing phase, we&apos;re pulling back the curtain
          and giving you access to a broad selection of features from both the
          Speqqle Base and certain Speqqle Extensions. No need to worry about
          what&apos;s an &quot;extension&quot; and what&apos;s part of the
          &quot;base&quot; &mdash; if you see it, you can use it. If you
          don&apos;t see it, it&apos;s not ready for release yet. But
          here&apos;s where you can help: if there&apos;s a feature you need
          that&apos;s not available, let us know! Your feedback will help us
          prioritize what to build next.
        </p>
      </div>

      <div className="section">
        <h2>6. Data Privacy, User Data Collection &amp; Security</h2>
        <p>
          For full details on how we protect your data and respect your privacy,
          please see our{" "}
          <a
            href={`${window.location.origin}/privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Statement
          </a>{" "}
          and our{" "}
          <a
            href={`${window.location.origin}/terms-of-use`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
          .
        </p>
      </div>

      <div className="section">
        <h2>7. Account Management</h2>
        <h3>7.1. Managing Your Account</h3>
        <p>
          Alpha testers can update their account details and customize their
          preferences on the{" "}
          <strong>
            <a
              href={`${window.location.origin}/profile`}
              //   href="https://app.speqqle.com/profile/"
              //   target="_blank"
              //   rel="noopener noreferrer"
            >
              Profile
            </a>
          </strong>{" "}
          page.
        </p>
        <ul>
          <li>Update your email, password, and profile information.</li>
          <li>
            Set notification preferences to control email alerts and reminders.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>8. Troubleshooting &amp; Support</h2>
        <p>
          Since this is an alpha testing phase, discovering and addressing
          issues is a big part of the process. If you encounter a problem that
          stops you from making progress, don&apos;t hesitate to reach out.
          We&apos;re here to help you keep moving forward and to learn from your
          experience.
        </p>
        <p>Here&apos;s how you can contact support:</p>
        <ul>
          <li>
            <strong>Email:</strong> Reach out to us at{" "}
            <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>.
            We&apos;ll get back to you as soon as possible.
          </li>
          <li>
            <strong>WhatsApp:</strong> Message us via the known contacts, but
            please note that support is only available during office hours.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>9. Roadmap and Upcoming Features</h2>
        <p>
          Our alpha testing phase is not just about identifying bugs &mdash;
          it&apos;s also about shaping the future of Speqqle. Your feedback
          plays a vital role in determining what&apos;s next. Here&apos;s a
          sneak peek at some of the exciting features that could be on the
          horizon:
        </p>
        <ul>
          <li>
            <strong>Automatic Uncertainty Evaluations:</strong> Take the
            guesswork out of error margins with built-in uncertainty
            calculations.
          </li>
          <li>
            <strong>Extensions to Additional Spectroscopic Methods:</strong>{" "}
            We&apos;re looking to expand our coverage. Which spectroscopic
            methods would you like to see added?
          </li>
          <li>
            <strong>Automatic Model Selection:</strong> Let Speqqle&apos;s smart
            assistant choose the most appropriate model for your data.
          </li>
          <li>
            <strong>Real-Time Capabilities:</strong> Get faster results and
            immediate feedback on your analysis in real time.
          </li>
          <li>
            <strong>Parameter Initialization by Speqqle:</strong> No more manual
            setup &mdash; Speqqle will auto-populate initial parameters for you.
          </li>
          <li>
            <strong>Database Connectivity:</strong> Seamlessly link your
            analysis with external databases for streamlined workflows and
            richer insights.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>10. Thank You!</h2>
        <div className="thank-you">
          <p>
            <strong>Thank you for being an alpha tester!</strong> Your insights,
            feedback, and patience make a world of difference. You&apos;re not
            just a tester &mdash; you&apos;re a co-creator of something truly
            special. Every suggestion you make and every bug you catch helps us
            shape a platform that works better for everyone.
          </p>
          <p>
            We know testing can be tricky at times, and that&apos;s why
            we&apos;re so grateful for your persistence and creativity. Your
            contributions will have a lasting impact, and we&apos;re excited to
            have you on this journey with us.
          </p>
          <p>
            <strong>
              Stay curious, stay adventurous, and keep sharing your brilliance
              with us!
            </strong>
          </p>
          <p>&mdash; The Speqqle Team</p>
        </div>
      </div>
    </div>
  );
}

export default AlphaTestersManual;
