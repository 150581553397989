import React from "react";
import {
  filterCurveDataToRange,
  findBestMatchingCurve,
  hasProperty,
  processSymbols,
  segmentsIntoSingleCurve,
  splitCurveIntoSegmentsByEdges,
} from "../../utils/helpers";
import { deepCopy, getModelsByIds } from "../leftSide/Models/modelLogic";
import { processFileWithOptions } from "../leftSide/Files/fileProcessing/processingLogic";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HtmlTooltip from "../commonComponents/HtmlTooltip";

export function createGraphModelEntry(
  model,
  depth,
  indexArray,
  handleModelClick
) {
  // let modelDataToSend;
  // if (hasProperty(model, "recParams")) {
  //   modelDataToSend = {
  //     FE_ID: model.FE_ID,
  //     modelParams: model.modelParams,
  //     reffitID: model.reffitID,
  //     recParams: model.recParams,
  //     recTableRows: model.recTableRows,
  //     abbreviation: model.abbreviation,
  //   };
  // } else {
  //   modelDataToSend = {
  //     FE_ID: model.FE_ID,
  //     modelParams: model.modelParams,
  //     reffitID: model.reffitID,
  //     abbreviation: model.abbreviation,
  //   };
  // }
  // return (
  //   <div
  //     key={indexArray + "|" + model.FE_ID}
  //     style={{ marginLeft: `${depth * 5}px` }}
  //     className="graphModelEntry"
  //     onClick={() => handleModelClick(modelDataToSend)}
  //   >
  //     {model.displayName}
  //   </div>
  // );

  if (model.outputs.length > 1) {
    return (
      <div
        key={indexArray + "|" + model.FE_ID}
        style={{ marginLeft: `${depth * 8}px` }}
      >
        <Accordion className="modelOutputAccordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="accordionSummary"
          >
            {/* <div>
            {model.displayName}
            </div> */}
            <Typography>{model.displayName}</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordionDetails">
            <div className="modelCurveAccordion">
              {model.outputs.map((output, index) => {
                return (
                  <div key={output.reffit_id}>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <div className="outputTooltip">
                            {processSymbols(output.description)}
                          </div>
                        </React.Fragment>
                      }
                    >
                      <div
                        className={`outputItem ${
                          index === model.outputs.length - 1 ? "lastItem" : ""
                        }`}
                        onClick={() => {
                          handleModelClick(model.FE_ID, output.reffit_id);
                        }}
                      >
                        {processSymbols(output.name)}
                      </div>
                    </HtmlTooltip>
                  </div>
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  } else if (model.outputs.length === 1) {
    const output = model.outputs[0];
    return (
      <div
        key={indexArray + "|" + model.FE_ID}
        style={{ marginLeft: `${depth * 8}px` }}
      >
        <div className="singleModelOutput" id="single-model-output-entry">
          <HtmlTooltip
            title={
              <React.Fragment>
                <div
                  className="outputTooltip"
                  style={{ fontSize: "1.2em", textDecoration: "underline" }}
                >
                  {processSymbols(output.name)}
                </div>
                <div className="outputTooltip">
                  {processSymbols(output.description)}
                </div>
              </React.Fragment>
            }
          >
            <div
              className={`outputItem lastItem`}
              id="single-model-output-name"
              onClick={() => {
                handleModelClick(model.FE_ID, output.reffit_id);
              }}
            >
              {/* {processSymbols(output.name)} */}
              {model.displayName}
            </div>
          </HtmlTooltip>
        </div>
      </div>
    );
  }
}

export function createModelList(
  handleModelClick,
  modelList,
  createModelEntry,
  selectedModelQuantitySet = null
) {
  const models = deepCopy(modelList);
  const listOfModels = [];

  for (let i = 0; i < models.length; i++) {
    const model = models[i];

    recursiveCreate(model, 0, "" + i);
  }

  function recursiveCreate(model, depth, indexArray) {
    if (selectedModelQuantitySet === null) {
      listOfModels.push(
        createModelEntry(model, depth, indexArray, handleModelClick)
      );
    } else {
      listOfModels.push(
        createModelEntry(
          model,
          depth,
          indexArray,
          handleModelClick,
          selectedModelQuantitySet
        )
      );
    }

    if (model.subModels.length > 0) {
      for (let i = 0; i < model.subModels.length; i++) {
        recursiveCreate(model.subModels[i], depth + 1, indexArray + ";" + i);
      }
    }
  }

  return listOfModels;
}

export function createModelDistPayload(
  modelsForPayload,
  ranges,
  // minRange = -1000,
  // maxRange = 1000
  quantityList,
  user
) {
  let payload;
  let sendModelData = createModelFitPayloadParams(modelsForPayload);
  let getModelCurveData = [];

  for (let i = 0; i < modelsForPayload.length; i++) {
    const model = modelsForPayload[i];

    if (hasProperty(ranges, model.FE_ID)) {
      const currentModelRanges = ranges[model.FE_ID];

      for (let j = 0; j < quantityList.length; j++) {
        const quantityPair = quantityList[j];
        if (
          quantityPair.modelId === model.FE_ID &&
          hasProperty(currentModelRanges, quantityPair.quantity)
        ) {
          for (
            let k = 0;
            k < currentModelRanges[quantityPair.quantity].length;
            k++
          ) {
            const ranges = currentModelRanges[quantityPair.quantity][k];
            getModelCurveData.push({
              modelid: model.FE_ID,
              min: ranges[0],
              max: ranges[1],
              quantity: quantityPair.quantity, // Default Raman is 5
            });
          }
        }
      }
    }
  }

  payload = {
    User: user.id,
    Model: {
      SendModel: sendModelData,
      GetModelCurve: getModelCurveData,
    },
  };

  return payload;
}

function addParametersToPayload(model, sendModelData, addedIds) {
  if (!addedIds.some((id) => id === model.FE_ID)) {
    let parameters = [];

    for (let i = 0; i < model.modelParams.length; i++) {
      const param = model.modelParams[i];
      if (param.recuring == 0) {
        let parameterToAdd = {
          reffit_id: param.reffit_id,
          fixed: param.customFixed,
        };

        if (
          Object.prototype.hasOwnProperty.call(param, "group") &&
          param.group !== undefined
        ) {
          parameterToAdd = { ...parameterToAdd, matched: param.group };
        }
        if (
          Object.prototype.hasOwnProperty.call(param, "hardMax") &&
          param.hardMax !== ""
        ) {
          parameterToAdd = { ...parameterToAdd, hardmax: param.hardMax };
        }
        if (
          Object.prototype.hasOwnProperty.call(param, "hardMin") &&
          param.hardMin !== ""
        ) {
          parameterToAdd = { ...parameterToAdd, hardmin: param.hardMin };
        }

        if (param.type !== "Model") {
          if (Object.prototype.hasOwnProperty.call(param, "value")) {
            parameterToAdd = { ...parameterToAdd, value: param.value };
          } else {
            parameterToAdd = { ...parameterToAdd, value: param.default };
          }
        } else {
          parameterToAdd = { ...parameterToAdd, value: param.FE_ID };
        }

        parameters.push(parameterToAdd);
      }
    }

    if (Object.prototype.hasOwnProperty.call(model, "recParams")) {
      const subModelIdList = [];

      if (model.subModels.length > 0) {
        for (let i = 0; i < model.subModels.length; i++) {
          const subModel = model.subModels[i];

          subModelIdList.push(subModel.FE_ID);
        }
      }

      for (let i = 0; i < model.recParams.length; i++) {
        const paramArray = model.recParams[i];
        for (let j = 0; j < paramArray.length; j++) {
          const param = paramArray[j];
          if (param.type != "Model") {
            let parameterToAdd = {
              reffit_id: param.reffit_id + param.recuring * i,
              value: model.recTableRows[i][param.name],
            };

            if (hasProperty(param, "customFixed")) {
              parameterToAdd = {
                ...parameterToAdd,
                fixed: param.customFixed,
              };
            }

            if (
              Object.prototype.hasOwnProperty.call(param, "group") &&
              param.group !== undefined
            ) {
              parameterToAdd = { ...parameterToAdd, matched: param.group };
            }
            if (
              Object.prototype.hasOwnProperty.call(param, "hardMax") &&
              param.hardMax !== ""
            ) {
              parameterToAdd = { ...parameterToAdd, hardmax: param.hardMax };
            }
            if (
              Object.prototype.hasOwnProperty.call(param, "hardMin") &&
              param.hardMin !== ""
            ) {
              parameterToAdd = { ...parameterToAdd, hardmin: param.hardMin };
            }

            parameters.push(parameterToAdd);
          } else {
            const idToUse = subModelIdList.shift();
            let parameterToAdd = {
              reffit_id: param.reffit_id + param.recuring * i,
              value: idToUse,
            };

            parameters.push(parameterToAdd);
          }
        }
      }
    }

    if (model.reffitID !== 0) {
      parameters.unshift({
        reffit_id: -1,
        value: model.reffitID,
        fixed: true,
      });
    } else {
      const epsilon = model.modelParams.find((param) => param.reffit_id == -1);
      let isFixed = false;

      if (hasProperty(epsilon, "customFixed")) {
        isFixed = epsilon.customFixed;
      } else {
        isFixed = epsilon.fixed;
      }

      let valueToPut = 0;

      if (Object.prototype.hasOwnProperty.call(epsilon, "value")) {
        valueToPut = epsilon.value;
      } else {
        valueToPut = epsilon.default;
      }

      if (parameters[0].reffit_id === -1) {
        parameters[0] = {
          ...parameters[0],
          value: valueToPut,
          fixed: isFixed,
        };
      } else {
        parameters.unshift({
          reffit_id: -1,
          value: valueToPut,
          fixed: isFixed,
        });
      }
    }

    const entryForModelData = {
      modelid: model.FE_ID,
      modeltype: model.reffitID,
      parameters: { global: parameters },
    };

    if (hasProperty(model, "vdfData")) {
      entryForModelData.vdf = model.vdfData;
    }

    sendModelData.push(entryForModelData);
    addedIds.push(model.FE_ID);
  }

  if (model.subModels.length > 0) {
    for (let x = 0; x < model.subModels.length; x++) {
      const subModel = model.subModels[x];

      addParametersToPayload(subModel, sendModelData, addedIds);
    }
  }
}

export function createModelFitPayloadParams(modelsForPayload) {
  let payload;
  let sendModelData = [];

  const addedIds = [];

  for (let i = 0; i < modelsForPayload.length; i++) {
    const model = modelsForPayload[i];

    addParametersToPayload(model, sendModelData, addedIds);
  }

  payload = sendModelData;

  return payload;
}

export function generatePlotData(
  data,
  generalMin,
  generalMax,
  graphColor,
  shortName,
  fileId,
  modelId,
  edges,
  quantity
) {
  let plotData = [];
  // let dataBefore = [];
  let dataWithin = [];
  // let dataAfter = [];
  const legendGroup =
    fileId !== null ? `file_${fileId}` : `model_${modelId}_${quantity}`;

  // If fileId is not null, perform filtering based on generalMin, generalMax, and edges
  if (fileId != null) {
    // Initial filtering based on generalMin and generalMax
    if (generalMin < generalMax) {
      // if (generalMin !== "") {
      //   dataBefore = data.filter((point) => point.x < generalMin);
      // }
      // if (generalMax !== "") {
      //   dataAfter = data.filter((point) => point.x > generalMax);
      // }
      dataWithin = data.filter(
        (point) =>
          (generalMin === "" || point.x >= generalMin) &&
          (generalMax === "" || point.x <= generalMax)
      );
    } else {
      dataWithin = data;
    }

    // Split dataWithin into segments based on edges
    let segments = splitCurveIntoSegmentsByEdges(dataWithin, edges);

    // Add each segment to plotData
    segments.forEach((segment, index) => {
      plotData.push({
        x: segment.map((point) => point.x),
        y: segment.map((point) => point.y),
        type: "scattergl",
        mode: "lines+markers",
        line: { color: graphColor },
        name: index === 0 ? shortName : `${shortName} (cont.)`,
        fileId: fileId,
        legendgroup: legendGroup,
        showlegend: index === 0,
      });
    });

    // NOT USED ANYMORE FOR NOW
    // Add data before and after range
    // if (dataBefore.length > 0) {
    //   plotData.push({
    //     x: dataBefore.map((point) => point.x),
    //     y: dataBefore.map((point) => point.y),
    //     type: "scatter",
    //     mode: "lines",
    //     line: { color: "#a1a1a1" },
    //     name: shortName,
    //     fileId: fileId,
    //     legendgroup: legendGroup,
    //     showlegend: false,
    //   });
    // }
    // if (dataAfter.length > 0) {
    //   plotData.push({
    //     x: dataAfter.map((point) => point.x),
    //     y: dataAfter.map((point) => point.y),
    //     type: "scatter",
    //     mode: "lines",
    //     line: { color: "#a1a1a1" },
    //     name: shortName,
    //     fileId: fileId,
    //     legendgroup: legendGroup,
    //     showlegend: false,
    //   });
    // }
  } else if (modelId != null) {
    // If fileId is null and modelId is not, use the entire dataset without additional filtering

    // IN THE FUTURE might be needed to add quantity names to Legend
    // const quantityName = getQuantityNameFromModels(
    //   modelData,
    //   modelId,
    //   quantity,
    //   true
    // );

    // console.log("quantity name: ", quantityName);
    // const quantityShortName =
    //   quantityName.length > 15
    //     ? quantityName.slice(0, 15 - 3) + "..."
    //     : quantityName;

    plotData.push({
      x: data.map((point) => point.x),
      y: data.map((point) => point.y),
      type: "scattergl",
      mode: "lines",
      line: { color: graphColor },
      name: shortName,
      modelId: modelId,
      legendgroup: legendGroup,
      quantity: quantity,
    });
  }

  return plotData;
}

export function containedModelIDsInGraphs(listToCheck, graphArray) {
  const foundIDs = [];

  for (let i = 0; i < listToCheck.length; i++) {
    const id = listToCheck[i];
    for (let k = 0; k < graphArray.length; k++) {
      const graph = graphArray[k];
      if (
        Object.prototype.hasOwnProperty.call(graph, "containedModels") &&
        graph.containedModels.some(
          (modelQuantitySet) => modelQuantitySet.modelId == id
        )
      ) {
        foundIDs.push(id);
      }
    }
  }

  // this [...new Set(foundIDs)] trick removes any ID duplicates if in some edge case they appear
  return [...new Set(foundIDs)];
}

export function generateFitPayload(
  uploadedFiles,
  models,
  terms,
  maxIteration,
  maxRunTime,
  chiVal,
  iterationRefreshCount,
  user,
  getCurvePayload,
  recordedErrorLog
) {
  const termsModels = [...new Set(terms.map((term) => term.modelId))];
  const termsFiles = [...new Set(terms.map((term) => term.fileId))];

  const modelsForPayload = getModelsByIds(termsModels, deepCopy(models));

  let modelDataPayload = createModelFitPayloadParams(modelsForPayload);

  let fileDataPayload = [];

  for (let i = 0; i < termsFiles.length; i++) {
    const termFileId = termsFiles[i];
    const file = uploadedFiles.find((file) => file.ID == termFileId);

    let data = Object.prototype.hasOwnProperty.call(file, "dataPoints")
      ? file.dataPoints
      : processFileWithOptions(file.content, file.options, recordedErrorLog);
    // getDataPointsFromFile(file.content);

    data = filterCurveDataToRange(data, file.dataRangeMin, file.dataRangeMax);

    data = segmentsIntoSingleCurve(
      splitCurveIntoSegmentsByEdges(data, file.edges)
    );

    let range = {};
    range = {
      ...range,
      min: file.dataRangeMin != "" ? file.dataRangeMin : -1000,
    };
    range = {
      ...range,
      max: file.dataRangeMax != "" ? file.dataRangeMax : 1000,
    };

    const filteredCutParams = file.edges.filter(
      (param) =>
        (param.min !== undefined && param.min !== "") ||
        (param.max !== undefined && param.max !== "")
    );

    fileDataPayload.push({
      dataid: termFileId,
      data: data,
      range: range,
      edges: filteredCutParams,
    });
  }

  let termsWithIDs = terms.map((term) => {
    if (term.weight !== 0 && term.weight !== "") {
      return {
        modelid: term.modelId,
        dataid: term.fileId,
        weight: term.weight,
        quantity: term.quantity, //Default for Raman is 5
      };
    }
  });

  let fitOptions = {};
  if (maxIteration !== "") {
    fitOptions = { ...fitOptions, maxiters: maxIteration };
  }
  if (iterationRefreshCount !== "") {
    fitOptions = { ...fitOptions, numupdateiters: iterationRefreshCount };
  }
  if (maxRunTime !== "") {
    fitOptions = { ...fitOptions, maxsecs: maxRunTime };
  }
  if (chiVal.val !== "") {
    fitOptions = {
      ...fitOptions,
      mindiffchi2: {
        numiters: chiVal.n,
        diffchi2: chiVal.val,
      },
    };
  }

  let payload = {
    User: user.id,
    Model: {
      SendModel: modelDataPayload,
    },
    Data: {
      SendData: fileDataPayload,
    },
    Fit: {
      FitOptions: fitOptions,
      Fit: termsWithIDs,
    },
  };

  if (getCurvePayload.length > 0) {
    payload.Model.GetModelCurve = getCurvePayload;
  }

  return payload;
}

export function checkModelAndLoad(
  foundModel,
  handleAdd,
  sendJsonMessage,
  min,
  max,
  quantity,
  user,
  payloadDetails
) {
  // Checking if the model already has some curves available
  if (hasProperty(foundModel, "curves")) {
    // Get the best matching curve from the list
    const bestCurveSet = findBestMatchingCurve(
      foundModel.curves,
      min,
      max,
      quantity
    );
    // Cheking if returned curve was actually what we needed
    if (bestCurveSet.bestFound) {
      handleAdd(
        {
          modelid: foundModel.FE_ID,
          coordinates: bestCurveSet.curve.curve,
          quantity: quantity,
        },
        true,
        min,
        max
      );
      // We got the needed curve and now we can skip the rest of the function
      return null;
    }
  }

  // If we get here after previous 'if', it means that there are no curves at all, or none of the contained
  // curves is actually what we need, so we need to load in a new one.
  const payload = createModelDistPayload(
    [foundModel],
    { [foundModel.FE_ID]: { [quantity]: [[min, max]] } },
    [{ modelId: foundModel.FE_ID, quantity: quantity }],
    user
  );
  sendJsonMessage(payload, {
    ...payloadDetails,
    requestDetails: {
      modelId: foundModel.FE_ID,
      quantity: quantity,
    },
  });
}
