import React from "react";
import "./legal.scss";

function TestersAgreement({ onAgree, noOptIn = false }) {
  return (
    <div className="legalPage">
      <h1>Alpha Testing Agreement</h1>

      <p>
        Welcome and thank you for considering becoming an alpha tester for
        Speqqle. This agreement outlines the expectations, benefits, timelines,
        and deliverables associated with participating in our alpha testing
        program. By agreeing to participate, you are helping shape the future of
        our software, and we deeply value your input.
      </p>

      <div className="section">
        <h2>1. Purpose of Alpha Testing</h2>
        <p>
          Alpha testing is a crucial stage of software development. It allows us
          to identify and resolve issues, validate features, and improve the
          user experience before moving to the beta testing phase. Unlike beta
          testing, which is open to a broader audience, alpha testing involves a
          smaller, more engaged group of users who work closely with our team to
          refine the product.
        </p>
      </div>

      <div className="section">
        <h2>2. Role of the Alpha Tester</h2>
        <p>As an alpha tester, you agree to:</p>
        <ul>
          <li>
            Test pre-release versions of our software and provide feedback on
            usability, performance, and features.
          </li>
          <li>
            Report bugs, issues, and suggestions via the channels provided.
          </li>
          <li>Complete periodic surveys or feedback forms as requested.</li>
          <li>Adhere to the agreed-upon testing schedule and timelines.</li>
        </ul>
      </div>

      <div className="section">
        <h2>3. Our Commitments to You</h2>
        <p>As a thank you for your participation, we will:</p>
        <ul>
          <li>
            Provide access to the latest alpha and beta versions of our
            software.
          </li>
          <li>Offer early insight into upcoming features and tools.</li>
          <li>
            Recognize your contribution, which may include acknowledgment (with
            consent) in product releases or public documentation.
          </li>
          <li>
            Offer potential discounts or access to exclusive features for our
            dedicated testers.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>4. Timelines & Deliverables</h2>
        <p>
          Alpha testing will be conducted according to the following schedule:
        </p>
        <ul>
          <li>
            <strong>Start Date:</strong> 6th of January 2025
          </li>
          <li>
            <strong>End Date:</strong> 17th of January 2025
          </li>
        </ul>
        <p>During this period, alpha testers are expected to:</p>
        <ul>
          <li>
            <strong>Feedback Reports:</strong> Submit a minimum of 2 feedback
            reports:
            <ul>
              <li>
                The first report is due before <strong>10/01/2025</strong>.
              </li>
              <li>
                The second report is due before <strong>17/01/2025</strong>.
              </li>
            </ul>
          </li>
          <li>
            <strong>First Test Deliverable (due on 10/01/2025):</strong>
            <ul>
              <li>
                Explore the platform independently: Use it as you see fit and
                share your experience. In addition to reporting bugs, we value
                your insights on what features are most useful to you and how
                intuitive the platform feels.
              </li>
              <li>
                We will provide a feedback form, which we expect you to complete
                and submit before <strong>10/01/2025</strong>.
              </li>
            </ul>
          </li>
          <li>
            <strong>Second Test Deliverable (due on 17/01/2025):</strong>
            <ul>
              <li>
                After the completion of the first test phase, we will assign a
                specific data analysis task, expected to begin on
                <strong>13/01/2025</strong>. Details of this task will be
                provided at that time.
              </li>
              <li>
                A feedback form related to this task will be provided, which we
                expect you to complete and submit before
                <strong>17/01/2025</strong>.
              </li>
            </ul>
          </li>
          <li>
            <strong>Participation:</strong> Participate in review sessions with
            our development team if requested. These sessions may be held on our
            premises or via remote communication tools.
          </li>
          <li>
            <strong>Response Time:</strong> Respond to requests for feedback
            within <strong>2 business days</strong> to ensure a smooth and
            effective testing process.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>5. Confidentiality & Use of Feedback</h2>
        <details>
          <summary style={{ cursor: "pointer" }}>Click to expand</summary>
          <p>
            All alpha testers are expected to treat the software, platform
            access, and all related materials as strictly confidential. Testers
            are prohibited from sharing, distributing, or otherwise disclosing
            any information, materials, or access links related to the alpha
            software with any third parties without prior written consent from
            us. This includes, but is not limited to, sharing the public link to
            the platform or any related login credentials.
          </p>
          <p>
            Testers are not permitted to take screenshots, screen recordings, or
            any visual captures of the platform, its interface, or its features,
            except when explicitly requested by our development team as part of
            the testing process.
          </p>
          <p>
            Your feedback will be used solely to improve the product. By
            providing feedback, you grant us the right to use it without any
            obligation to provide compensation, unless explicitly agreed
            otherwise.
          </p>
        </details>
      </div>

      <div className="section">
        <h2>6. Privacy and Data Protection</h2>
        <details>
          <summary style={{ cursor: "pointer" }}>Click to expand</summary>
          <p>
            We prioritize your privacy and are committed to protecting your
            personal data. All data collected during the alpha testing phase
            will be handled in strict compliance with GDPR and Swiss data
            protection laws. For more details, please review our{" "}
            <a
              href={`${window.location.origin}/privacy-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href={`${window.location.origin}/terms-of-use`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
            .
          </p>
          <p>
            During alpha testing, certain personal data may be collected,
            including your name, email address, and any other information
            necessary to facilitate feedback collection and communication. This
            data will not be anonymized during the testing phase to ensure that
            we can reach out to you for clarifications or additional input if
            needed.
          </p>
          <p>
            To participate as an alpha tester, you will be required to provide
            explicit consent and opt-in to allow us to collect, store, and
            process this data. After the alpha testing phase is complete, we
            will either anonymize or delete your personal data upon your
            request, in accordance with applicable data protection regulations.
          </p>
        </details>
      </div>

      <div className="section">
        <h2>7. Limitation of Liability</h2>
        <details>
          <summary style={{ cursor: "pointer" }}>Click to expand</summary>
          <p>
            The software provided during the alpha testing phase is a
            pre-release version and is made available &quot;as is.&quot; We do
            not guarantee its performance, stability, or uptime. Our liability
            is limited to the fullest extent permitted by law.
          </p>
        </details>
      </div>

      <div className="section">
        <h2>8. Termination</h2>
        <details>
          <summary style={{ cursor: "pointer" }}>Click to expand</summary>
          <p>
            We reserve the right to terminate your participation in the alpha
            testing program at any time. You may also withdraw from the program
            at any time by notifying us.
          </p>
          <p>
            Since this is a platform-based service, users do not possess copies
            of the software itself. However, upon termination, you are required
            to delete any materials, files, or outputs that you have generated
            or downloaded while using the platform. Additionally, you must
            remove any access credentials or other means of accessing the
            platform and cease all access to the program.
          </p>
        </details>
      </div>

      <div className="section">
        <h2>9. Links to Other Documents</h2>
        <p>For more information, please refer to the following documents:</p>
        <ul>
          <li>
            <a
              href={`${window.location.origin}/privacy-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href={`${window.location.origin}/terms-of-use`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
          </li>
          <li>
            <a
              href={`${window.location.origin}/alpha-manual`}
              //   href="https://app.speqqle.com/manualalpha/"
            >
              User Manual for our alpha testers
            </a>
          </li>
          <li>
            <a href={`${window.location.origin}/feedback`}>Feedback Form</a>
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>10. Acknowledgment & Agreement</h2>
        <p>
          By participating in the alpha testing program, you acknowledge that
          you have read and understood this agreement, and you agree to its
          terms and conditions. You also acknowledge that the software is a
          pre-release version and may contain defects that could cause data
          loss, instability, or other issues.
        </p>
        <p>
          Additionally, by opting in, you provide explicit consent for us to
          process and store your non-anonymized data for the purposes of
          feedback collection, issue resolution, and product improvement, as
          outlined in our{" "}
          <a
            href={`${window.location.origin}/privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
        <p>
          To confirm your participation and provide your consent, please click
          the &quot;Agree &amp; Opt-In&quot; button below:
        </p>
        {!noOptIn ? (
          <button className="btn-primary" onClick={() => onAgree()}>
            Agree &amp; Opt-In
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default TestersAgreement;
