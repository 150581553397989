/**
 * @category LeftSide
 * @component
 * @module Files/StickyFileWindow
 * @description This component is a sticky window that pops up when we want to give user an ability to edit a file.
 * At the moment it can be called from the graph component and from the file uploader component.
 */
import React, { useState, useEffect, useContext } from "react";
import { MenuItem, Select } from "@mui/material";
import { deepCopy } from "../Models/modelLogic";
import {
  filterCurveDataToRange,
  isDeepEqual,
  segmentsIntoSingleCurve,
  splitCurveIntoSegmentsByEdges,
} from "../../../utils/helpers";
import Range from "./Range";
import units from "../../../utils/units";
import "../leftSide.scss";
import addIcon from "../../../res/icons/add.png";
import deleteIcon from "../../../res/icons/delete.png";
import close from "../../../res/icons/delete.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { processFileWithOptions } from "./fileProcessing/processingLogic";
import { DashboardContext } from "../../../context/DashboardContext";
import { GeneralContext } from "../../../context/GeneralContext";

function StickyFileWindow(params) {
  const { selectedFiles, updateSelected, callFromGraph, resetSelection } =
    params;
  const { setFileToChange } = useContext(DashboardContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const { file, index } = selectedFiles[0];
  const [xUnit, setXUnit] = useState(file.xUnit);
  const [rangeMin, setRangeMin] = useState(file.dataRangeMin);
  const [rangeMax, setRangeMax] = useState(file.dataRangeMax);
  const [edges, setEdges] = useState(file.edges);
  // const [npoints, setNpoints] = useState(file.npoints);
  const [pointsInRange, setPointsInRange] = useState("N/A");

  /**
   * This function is called when user changes the unit of the file. It finds the correct unit object from the
   * units array and sets it as the new unit for the file.
   * @function handleUnitChange
   * @param {Event} e - This is the event that is triggered when user changes the unit of the file.
   */
  const handleUnitChange = (e) => {
    const value = e.target.value;
    const unit = units.filter((u) => u.value === value)[0];
    setXUnit(unit);
  };

  useEffect(() => {
    setXUnit(file.xUnit);
    setRangeMin(file.dataRangeMin);
    setRangeMax(file.dataRangeMax);
    setEdges(file.edges);
  }, [index]);

  useEffect(() => {
    let cutData = filterCurveDataToRange(
      file.dataPoints,
      file.dataRangeMin,
      file.dataRangeMax
    );

    cutData = segmentsIntoSingleCurve(
      splitCurveIntoSegmentsByEdges(cutData, file.edges)
    );

    setPointsInRange(cutData.length);
  }, []);

  useEffect(() => {
    try {
      const copyOfFile = deepCopy(file);

      let needsUpdate = false;

      if (!isDeepEqual(copyOfFile.xUnit, xUnit)) {
        const newOptions = { ...copyOfFile.options, xUnit: xUnit };
        const dataPoints = processFileWithOptions(
          file.content,
          newOptions,
          recordedErrorLog
        );
        const maxX = dataPoints.reduce(function (max, p) {
          const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
          return x > max ? x : max;
        }, Math.ceil(dataPoints[0].x));

        const minX = dataPoints.reduce(function (min, p) {
          const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
          return x < min ? x : min;
        }, Math.floor(dataPoints[0].x));

        copyOfFile.dataPoints = dataPoints;
        copyOfFile.dataRangeMax = maxX;
        setRangeMax(maxX);
        copyOfFile.dataRangeMin = minX;
        setRangeMin(minX);
        copyOfFile.xUnit = xUnit;
        needsUpdate = true;
      } else {
        if (copyOfFile.dataRangeMin !== rangeMin) {
          copyOfFile.dataRangeMin = rangeMin;
          needsUpdate = true;
        }
        if (copyOfFile.dataRangeMax !== rangeMax) {
          copyOfFile.dataRangeMax = rangeMax;
          needsUpdate = true;
        }
      }
      // if (copyOfFile.npoints !== npoints) {
      //   copyOfFile.npoints = npoints;
      //   needsUpdate = true;
      // }
      if (!isDeepEqual(edges, copyOfFile.edges)) {
        copyOfFile.edges = edges;
        needsUpdate = true;
      }

      let cutData = filterCurveDataToRange(
        copyOfFile.dataPoints,
        copyOfFile.dataRangeMin,
        copyOfFile.dataRangeMax
      );

      cutData = segmentsIntoSingleCurve(
        splitCurveIntoSegmentsByEdges(cutData, copyOfFile.edges)
      );

      copyOfFile.poinsInRange = cutData.length;

      if (needsUpdate) {
        setPointsInRange(copyOfFile.poinsInRange);
        updateSelected(copyOfFile, index);
      }
    } catch (error) {
      recordedErrorLog("useEffect after some values upadte failure: ", error);
    }
  }, [xUnit, rangeMin, rangeMax, edges]);
  // }, [xUnit, rangeMin, rangeMax, edges, npoints]);

  /**
   * This function updates the min edge point of the edge with the given index.
   * @function updateCutMin
   * @param {string} value - This is a string that represents the value of the min edge point.
   * @param {number} index - This is an index of the edge that we want to update.
   */
  function updateCutMin(value, index) {
    try {
      const cutParamsCopy = deepCopy(edges);
      if (value === "") {
        cutParamsCopy[index] = { ...cutParamsCopy[index], min: value };
      } else {
        cutParamsCopy[index] = {
          ...cutParamsCopy[index],
          min: parseFloat(value),
        };
      }
      setEdges(cutParamsCopy);
    } catch (error) {
      recordedErrorLog("Min cut update failure: ", error);
    }
  }

  /**
   * This function updates the max edge point of the edge with the given index.
   * @function updateCutMax
   * @param {string} value - This is a string that represents the value of the max edge point.
   * @param {number} index - This is an index of the edge that we want to update.
   */
  function updateCutMax(value, index) {
    try {
      const cutParamsCopy = deepCopy(edges);
      if (value === "") {
        cutParamsCopy[index] = { ...cutParamsCopy[index], max: value };
      } else {
        cutParamsCopy[index] = {
          ...cutParamsCopy[index],
          max: parseFloat(value),
        };
      }
      setEdges(cutParamsCopy);
    } catch (error) {
      recordedErrorLog("Max cut update failure: ", error);
    }
  }

  /**
   * This function adds a new empty edge to the list of edges.
   * @function handleAddCut
   */
  function handleAddCut() {
    setEdges([...edges, { min: "", max: "" }]);
  }

  /**
   * This function deletes the edge with the given index.
   * @function handleDeleteCut
   * @param {number} index - This is an index of the edge that we want to delete.
   */
  function handleDeleteCut(index) {
    setEdges(edges.filter((_, i) => i !== index));
  }

  // /**
  //  * This function updates the number of points of the file.
  //  * @function handleNPointsChange
  //  * @param {Event} e - This is the event that is triggered when user changes the number of points of the file.
  //  */
  // function handleNPointsChange(e) {
  //   if (e.target.value > 0) {
  //     setNpoints(parseInt(e.target.value));
  //   }
  // }

  /**
   * This function sets file that we want to edit again in file options window.
   * @function handleNameClick
   */
  function handleNameClick() {
    setFileToChange(file.ID);
  }

  return (
    <div
      className="stickyFileWindow"
      style={edges.length > 1 ? { height: "410px" } : {}}
      id="stickyFileEditWindow"
    >
      <div className="header">
        <div
          className="fileName"
          onClick={() => handleNameClick()}
          id="sticky-file-window-file-name"
        >
          {file.name}
        </div>
        {callFromGraph ? (
          <img
            src={close}
            alt="Close Button"
            className="closeIcon"
            id="close-sticky-file-window"
            onClick={() => resetSelection()}
          />
        ) : (
          <></>
        )}
      </div>
      <hr />
      <div className="unitsContainer">
        <div className="unitSelectorLabel">
          <div className="title">x-data unit:</div>
          <div
            className="extraInfo"
            title="File units will be translated to cm-1 before showing in the graphs"
          >
            <sup>
              <InfoOutlinedIcon className="infoIcon" />
            </sup>
          </div>
        </div>
        <div className="xUnitSelectContainer">
          <Select
            MenuProps={{
              container: () => document.getElementById("stickyFileEditWindow"),
            }}
            labelId="selectUnit"
            id="unitSelection"
            value={xUnit.value}
            onChange={(e) => handleUnitChange(e)}
            className="x_unit_select"
            style={{ paddingTop: "0px" }}
          >
            {units.map(({ name, value }) => (
              <MenuItem key={name} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {/* <div className="nPoints">
        <div className="title">Npoints:</div>
        <TextField
          style={{
            height: "100%",
          }}
          value={npoints}
          onChange={handleNPointsChange}
          className="points"
          size="small"
          type="number"
        />
         <div className="points">{file.npoints}</div>
      </div> */}
      <Range
        initialMin={rangeMin}
        initialMax={rangeMax}
        updateMin={setRangeMin}
        updateMax={setRangeMax}
        rangeTitle={"Data Range:"}
      />
      <div className="nPoints">
        <div className="title">Points:</div>
        <div>{pointsInRange}</div>
      </div>
      <div className="cutSettingsTitle" id="exclusion-ranges-title">
        Exclusion Ranges:
      </div>
      <div
        className="allCutsContainer"
        style={edges.length > 1 ? {} : { height: "75px" }}
      >
        {edges &&
          edges.map((param, index) => {
            return (
              <div className="cutContainer" key={index}>
                <Range
                  initialMin={param.min}
                  initialMax={param.max}
                  updateMin={updateCutMin}
                  updateMax={updateCutMax}
                  rangeTitle={null}
                  index={index}
                />
                <img
                  src={deleteIcon}
                  alt="delete Cut"
                  className="deleteCut"
                  onClick={() => handleDeleteCut(index)}
                />
              </div>
            );
          })}
        <img
          src={addIcon}
          alt="add another Cut"
          className="addCut"
          onClick={() => handleAddCut()}
        />
      </div>
    </div>
  );
}

export default StickyFileWindow;
