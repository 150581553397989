import React, { useState, useRef, useEffect, useContext } from "react";
// import { useTable, usePagination } from "react-table";
import { useTable } from "react-table";
import {
  useInputSize,
  isAllowedNum,
  toPrecision,
  isCompleteNumber,
  produceListOfExchangeableModels,
} from "../../../utils/helpers";
import addIcon from "../../../res/icons/add.png";
import deleteIcon from "../../../res/icons/delete.png";
import HtmlTooltip from "../../commonComponents/HtmlTooltip";
import { GeneralContext } from "../../../context/GeneralContext";
import { DashboardContext } from "../../../context/DashboardContext";
import "./parameters.scss";
import ModelExchange from "./ModelExchange";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getModelById } from "../../leftSide/Models/modelLogic";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  rec,
  handleCellClick,
  getCheckedValue,
  tableIndex,
}) => {
  const { recordedErrorLog } = useContext(GeneralContext);

  const [value, setValue] = useState(initialValue);
  const valueRef = useRef(value);
  const debounceTimeoutRef = useRef(null);

  const onChange = (e) => {
    try {
      let newValue = e.target.value;
      if (isAllowedNum(newValue)) {
        const checkedValue = getCheckedValue(
          index,
          id,
          newValue,
          rec,
          tableIndex
        );
        setValue(checkedValue);
        valueRef.current = checkedValue;

        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
          if (isCompleteNumber(e.target.value)) {
            onBlur(e);
          }
          debounceTimeoutRef.current = null;
        }, 100); // Adjust the debounce time as needed
      }
    } catch (error) {
      recordedErrorLog("Table cell on change handler failure: ", error);
    }
  };

  const onBlur = (e) => {
    try {
      if (e !== undefined) {
        if (e.target.value == null) {
          updateMyData(index, id, "", rec, tableIndex);
        } else if (!isNaN(e.target.value)) {
          updateMyData(index, id, Number(e.target.value), rec, tableIndex);
        } else {
          updateMyData(index, id, e.target.value, rec, tableIndex);
        }
      }
    } catch (error) {
      recordedErrorLog("Table cell onBlur handler failure: ", error);
    }
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleRightClick = (event) => {
    event.preventDefault();
  };

  const handleMouseDown = (event) => {
    if (event.button === 2) {
      // check if right mouse button is clicked
      event.preventDefault();
    }
  };

  const inputSize = useInputSize(value);

  return (
    <input
      className="tableCell"
      value={value != null ? value : ""}
      onChange={onChange}
      onBlur={onBlur}
      onContextMenu={handleRightClick}
      onMouseDown={handleMouseDown}
      onClick={handleCellClick}
      style={{ width: `${inputSize + 20}px` }}
    />
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

function getTooltip(title) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      {isOpen ? (
        <div className="customTooltip">{title}</div>
      ) : (
        <div
          className="customTooltip clickable"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <HelpOutlineIcon />
        </div>
      )}
    </React.Fragment>
  );
}

// Be sure to pass our updateMyData and the skipPageReset option
function ParameterTable(props) {
  const {
    columns,
    data,
    updateMyData,
    skipPageReset,
    getCellColor,
    getFontWeight,
    setFixed,
    getHeaderTitle,
    rec = false,
    handleAddLine,
    handleDuplicateLine,
    handleLineDelete,
    canDelete,
    handleTableCellClick,
    getCheckedValue,
    getColorForCell,
    tableIndex = null,
    sortTable,
    model,
    handleModelExchange,
    handleExchangeToNewModel,
  } = props;

  const { recordedErrorLog } = useContext(GeneralContext);
  const { modelData } = useContext(DashboardContext);
  const [exchangeModelList, setExchangeModelList] = useState([]);
  const [isExchangeOpen, setIsExchangeOpen] = useState(false);
  const [clickedSubModel, setClickedSubModel] = useState(null);
  const tableRef = useRef(null);

  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
      }
      // usePagination
    );

  function handleCellClick(cell) {
    handleTableCellClick(cell, rec, tableIndex);
  }

  function handleContextMenu(cell, event) {
    event.preventDefault();
    setFixed(cell, rec, tableIndex);
  }

  function handleColHeaderClick(column) {
    if (rec && model.sort) {
      sortTable(column.id);
    }
  }

  const isScientificNotation = (val) => {
    return /^[+-]?\d+(\.\d+)?([eE][+-]?\d+)$/.test(val);
  };

  function produceValueForDisplay(value) {
    let val;
    if (value !== "") {
      if (isScientificNotation(value)) {
        val = value.toExponential(3);
        return val;
      } else if (value === 0) {
        return 0;
      } else if (value > 0 && `${toPrecision(value, 0)}`.length > 4) {
        val = toPrecision(value, 6).toExponential(3);
        return val;
      } else if (value < 0.0001 && value > 0) {
        val = value.toExponential(2);
        return val;
      } else if (value < -0.0001 && `${toPrecision(value, 0)}`.length > 5) {
        val = toPrecision(value, 6).toExponential(3);
        return val;
      } else if (value > -0.0001 && value < 0) {
        val = value.toExponential(3);
        return val;
      }
      val = toPrecision(value, 6);
      return val;
    } else {
      return "";
    }
  }

  const handleCheckboxClick = (rowIndex, columnId, currentValue) => {
    try {
      updateMyData(rowIndex, columnId, !currentValue, rec, tableIndex);
    } catch (error) {
      recordedErrorLog("Checkbox click handler failure", error);
    }
  };

  const handleModelClick = (rowIndex, columnIndex) => {
    try {
      // THIS WILL BECOME A PROBLEM IF THERE ARE NON REC AND REC SUB MODELS IN THE SAME MODEL !!!!
      // !!!!
      // !!!!
      let clickedSubModelLocal = null;
      if (rec) {
        // Problem would only happen now for rec models in previously mentioned case, non rec sub models should be
        // much more robust.
        clickedSubModelLocal = model.subModels[rowIndex];
      } else {
        const clickedModelID = model.modelParams.find(
          (param) =>
            param.type === "Model" &&
            param.displaytable === tableIndex &&
            param.displayposition === columnIndex
        ).FE_ID;

        clickedSubModelLocal = getModelById(clickedModelID, model.subModels);
      }
      setClickedSubModel(clickedSubModelLocal);

      const listOfOptions = produceListOfExchangeableModels(
        clickedSubModelLocal.reffitID,
        clickedSubModelLocal.FE_ID,
        modelData
      );

      setExchangeModelList(listOfOptions);

      setIsExchangeOpen(true);
    } catch (error) {
      recordedErrorLog("Model click handler failure in parameter table", error);
    }
  };

  return (
    <>
      <table {...getTableProps()} ref={tableRef}>
        <thead>
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const headerTitle = getHeaderTitle(column);
                  if (headerTitle !== "") {
                    const headerProps = column.getHeaderProps();
                    return (
                      <th
                        key={column.id}
                        {...headerProps}
                        onClick={() => handleColHeaderClick(column)}
                      >
                        <HtmlTooltip
                          title={
                            getTooltip(headerTitle)
                            // <React.Fragment>
                            //   <div className="customTooltip">{headerTitle}</div>
                            // </React.Fragment>
                          }
                        >
                          <div>{column.render("Header")}</div>
                        </HtmlTooltip>
                      </th>
                    );
                  } else {
                    return (
                      <th key={column.id} {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    );
                  }
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <tr key={rowProps.key} {...rowProps}>
                {row.cells.map((cell, index) => {
                  const cellProps = cell.getCellProps({
                    style: {
                      color: getCellColor(cell, rec),
                      fontWeight: getFontWeight(cell, rec),
                      backgroundColor: getColorForCell(cell, rec),
                    },
                    age: cell.row.original.age,
                    onContextMenu: (event) => handleContextMenu(cell, event),
                  });
                  return (
                    <td
                      key={cell.column.id + "|" + cell.row.index}
                      {...cellProps}
                      className="cellForTable"
                    >
                      {typeof cell.value === "boolean" ? (
                        <input
                          type="checkbox"
                          style={{ marginRight: "5px", marginLeft: "5px" }}
                          checked={cell.value}
                          onChange={() =>
                            handleCheckboxClick(
                              cell.row.index,
                              cell.column.id,
                              cell.value
                            )
                          }
                        />
                      ) : cell.column.Header === "Model" ||
                        cell.column.Header.props.input === "Model" ? (
                        <div
                          className="modelCell"
                          onClick={() =>
                            handleModelClick(cell.row.index, index)
                          }
                        >
                          {cell.value}
                        </div>
                      ) : (
                        <EditableCell
                          value={produceValueForDisplay(cell.value)}
                          row={{ index: cell.row.index }}
                          column={{ id: cell.column.id }}
                          updateMyData={updateMyData}
                          rec={rec}
                          tableIndex={tableIndex}
                          handleCellClick={() => handleCellClick(cell)}
                          getCheckedValue={getCheckedValue}
                        />
                      )}
                    </td>
                  );
                })}
                {rec && canDelete() ? (
                  <td id="buttonCell">
                    <div className="addContainer">
                      <img
                        src={addIcon}
                        alt="Add another table line button"
                        className="addButton"
                        onClick={() => handleAddLine(row)}
                      />
                      <ContentCopyIcon
                        alt="Duplicate current table line button"
                        className="duplicateButton"
                        onClick={() => handleDuplicateLine(row)}
                      />
                    </div>
                    <img
                      src={deleteIcon}
                      alt="Delete table line button"
                      className="deleteButton"
                      onClick={() => handleLineDelete(row)}
                    />
                  </td>
                ) : rec ? (
                  <td className="tableSpacerContainer">
                    <div className="spacer" />
                    <div className="spacer" />
                    <div className="spacer" />
                  </td>
                ) : (
                  // <></>
                  <></>
                )}
                {rec && !canDelete() ? (
                  <td id="buttonCell">
                    <div className="addContainer">
                      <img
                        src={addIcon}
                        alt="Add another table line button"
                        className="addButton"
                        onClick={() => handleAddLine(row)}
                      />
                      <ContentCopyIcon
                        alt="Duplicate current table line button"
                        className="duplicateButton"
                        onClick={() => handleDuplicateLine(row)}
                      />
                    </div>
                  </td>
                ) : rec ? (
                  <td className="tableSpacerContainer">
                    <div className="spacer" />
                    <div className="spacer" />
                    <div className="spacer" />
                  </td>
                ) : (
                  // <></>
                  <></>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {model.subModels.length > 0 && clickedSubModel !== null ? (
        <ModelExchange
          componentRef={tableRef}
          isOpen={isExchangeOpen}
          setIsOpen={setIsExchangeOpen}
          listOfOptions={exchangeModelList}
          model={clickedSubModel}
          handleModelExchange={handleModelExchange}
          handleExchangeToNewModel={handleExchangeToNewModel}
          isRec={rec}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default ParameterTable;
