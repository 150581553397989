import React from "react";
import ModelEntry from "./ModelEntry";
import ModalListEntry from "./ModalListEntry";
import { checkBool, hasProperty } from "../../../utils/helpers";
import { getUpdatedUniqueIds } from "../../rightSide/parameters/parameterLogic";

export function hasParentOrChildWithID(objList, targetID, checkID) {
  for (let i = 0; i < objList.length; i++) {
    const obj = objList[i];
    if (obj.FE_ID === targetID) {
      if (hasIDInChildren(obj.subModels, checkID)) {
        return true;
      }
    } else if (obj.FE_ID === checkID) {
      if (hasIDInChildren(obj.subModels, targetID)) {
        return true;
      }
    } else {
      if (hasIDInDescendants(obj.subModels, targetID, checkID)) {
        return true;
      }
    }
  }
  return false;
}

function hasIDInChildren(subList, id) {
  for (let i = 0; i < subList.length; i++) {
    const subObj = subList[i];
    if (subObj.FE_ID === id) {
      return true;
    }
    if (hasIDInChildren(subObj.subModels, id)) {
      return true;
    }
  }
  return false;
}

function hasIDInDescendants(subList, targetID, checkID) {
  for (let i = 0; i < subList.length; i++) {
    const subObj = subList[i];
    if (subObj.FE_ID === targetID) {
      if (hasIDInChildren(subObj.subModels, checkID)) {
        return true;
      }
    } else if (subObj.FE_ID === checkID) {
      if (hasIDInChildren(subObj.subModels, targetID)) {
        return true;
      }
    } else {
      if (hasIDInDescendants(subObj.subModels, targetID, checkID)) {
        return true;
      }
    }
  }
  return false;
}

export function getModelById(ID, modelList) {
  for (let i = 0; i < modelList.length; i++) {
    const model = modelList[i];
    if (model.FE_ID === ID) {
      return model;
    }
    if (model.subModels && model.subModels.length > 0) {
      const subModel = getModelById(ID, model.subModels);
      if (subModel) {
        return subModel;
      }
    }
  }
  return null;
}

export function getModelsByIds(ids, modelList) {
  let idSet = new Set(ids);
  let models = [];

  function searchModelList(modelList) {
    for (let i = 0; i < modelList.length; i++) {
      const model = modelList[i];
      if (idSet.has(model.FE_ID)) {
        models.push(model);
        idSet.delete(model.FE_ID);
        if (idSet.size === 0) {
          break; // All IDs have been found, no need to continue
        }
      }
      if (model.subModels && model.subModels.length > 0) {
        searchModelList(model.subModels);
      }
    }
  }

  searchModelList(modelList);
  return models;
}

export function replaceModelById(modelList, modelToCopy, ID) {
  const newModelList = [];
  for (let i = 0; i < modelList.length; i++) {
    const model = modelList[i];
    if (model.FE_ID === ID) {
      newModelList.push({ ...modelToCopy });
    } else {
      const newModel = { ...model };
      if (newModel.subModels && newModel.subModels.length > 0) {
        newModel.subModels = replaceModelById(
          newModel.subModels,
          modelToCopy,
          ID
        );
      }
      newModelList.push(newModel);
    }
  }
  return newModelList;
}

export function setLinked(objectIsLinked) {
  // set linked to true for the current object
  objectIsLinked.linked = true;

  // recursively set linked to true for all sub-objects
  for (let subModel of objectIsLinked.subModels) {
    setLinked(subModel);
  }
}

export function deleteObjectsByID(objectList, ID) {
  let objectListToReturn = deepCopy(objectList);

  objectListToReturn = objectListToReturn.filter((obj) => {
    if (obj.FE_ID === ID) {
      return false;
    } else if (obj.subModels) {
      obj.subModels = deleteObjectsByID(obj.subModels, ID);
    }
    return true;
  });

  return objectListToReturn;
}

export function linkedSubModelAdd(
  objectList_p,
  ID_p,
  getID_p,
  allModels_p,
  upID_p,
  getAbbrDic_p,
  setLocalAbbrDic_p,
  namingDic_p,
  setNamingDic_p,
  recordedErrorLog,
  indexToInsert
) {
  let linkedSubModels = null;
  const linked = true;
  const objectListCopy = deepCopy(objectList_p);
  const allModelsCopy = deepCopy(allModels_p);

  linkedSubModelFinder(
    objectListCopy,
    ID_p,
    getID_p,
    allModelsCopy,
    upID_p,
    getAbbrDic_p,
    setLocalAbbrDic_p,
    namingDic_p,
    setNamingDic_p,
    linked,
    recordedErrorLog
  );

  function linkedSubModelFinder(
    objectList,
    ID,
    getID,
    allModels,
    upID,
    getAbbrDic,
    setLocalAbbrDic,
    namingDic,
    setNamingDic,
    linked,
    recordedErrorLog
  ) {
    for (let i = 0; i < objectList.length; i++) {
      if (linkedSubModels != null) {
        break;
      }
      let obj = objectList[i];
      if (obj.FE_ID === ID) {
        linkedSubModels = obj.subModelTypes.map((modelID) =>
          generateModel(
            modelID,
            getID,
            upID,
            getAbbrDic,
            setLocalAbbrDic,
            allModels,
            namingDic,
            setNamingDic,
            linked,
            recordedErrorLog
          )
        );
        break;
      } else {
        obj.subModels = linkedSubModelFinder(
          obj.subModels,
          ID,
          getID,
          allModels,
          upID,
          getAbbrDic,
          setLocalAbbrDic,
          namingDic,
          setNamingDic,
          linked
        );
      }
    }
  }

  return addSubModelToModels(
    objectList_p,
    ID_p,
    getID_p,
    allModels_p,
    upID_p,
    getAbbrDic_p,
    setLocalAbbrDic_p,
    namingDic_p,
    setNamingDic_p,
    linkedSubModels,
    recordedErrorLog,
    indexToInsert
  );
}

export function addSubModelToModels(
  modelList,
  ID,
  getID,
  allModels,
  upID,
  getAbbrDic,
  setLocalAbbrDic,
  namingDic,
  setNamingDic,
  linkedSubModels = null,
  recordedErrorLog,
  indexToInsert
) {
  return modelList.map((model) => {
    if (model.FE_ID === ID) {
      let updatedIndexForInsertion =
        indexToInsert !== null ? indexToInsert : model.subModels.length;
      let addedModels = [];
      if (linkedSubModels == null) {
        const subModels = model.subModelTypes.map((modelID) =>
          generateModel(
            modelID,
            getID,
            upID,
            getAbbrDic,
            setLocalAbbrDic,
            allModels,
            namingDic,
            setNamingDic,
            false,
            recordedErrorLog
          )
        );
        model.subModels.splice(updatedIndexForInsertion, 0, ...subModels);
        addedModels = subModels;
      } else {
        model.subModel.splice(updatedIndexForInsertion, 0, ...linkedSubModels);
        addedModels = linkedSubModels;
      }

      for (let i = 0; i < addedModels.length; i++) {
        const newSubModel = addedModels[i];

        let newRecRows = [...model.recTableRows];
        let newRecParams = [...model.recParams];

        let rowObject = {};
        if (
          model.recTemplate.params.some((param) => param.defaultperline != null)
        ) {
          for (let j = 0; j < model.recTemplate.params.length; j++) {
            const element = model.recTemplate.params[j];

            if (element.defaultperline != null) {
              rowObject = {
                ...rowObject,
                [element.name]:
                  element.default +
                  element.defaultperline * (updatedIndexForInsertion + 1),
              };
            } else {
              rowObject = { ...rowObject, [element.name]: element.default };
            }
          }
          rowObject = {
            ...rowObject,
            Model: newSubModel.displayName,
          };
        } else {
          rowObject = {
            ...model.recTemplate.row,
            Model: newSubModel.displayName,
          };
        }
        newRecRows.splice(updatedIndexForInsertion, 0, rowObject);

        const updatedParamRow = [];
        // THIS WILL NEED SOME UPDATING IF WE START HAVING 2 MODELS PER REC PARAMETER ROW.
        // P.S. param.name === reffit_id when it comes to models as parameters
        for (let j = 0; j < model.recTemplate.params.length; j++) {
          const param = model.recTemplate.params[j];
          if (param.type === "Model") {
            const paramToReturn = {
              ...param,
              FE_ID: newSubModel.FE_ID,
            };
            updatedParamRow.push(paramToReturn);
          } else {
            updatedParamRow.push(param);
          }
        }

        newRecParams.splice(updatedIndexForInsertion, 0, updatedParamRow);
        updatedIndexForInsertion++;

        model.recTableRows = newRecRows;
        model.recParams = newRecParams;
      }
    } else {
      model.subModels = addSubModelToModels(
        model.subModels,
        ID,
        getID,
        allModels,
        upID,
        getAbbrDic,
        setLocalAbbrDic,
        namingDic,
        setNamingDic,
        linkedSubModels,
        recordedErrorLog,
        indexToInsert
      );
    }
    return model;
  });
}

export function unlinkModelList(
  modelList,
  indexPath,
  firstIDToUse,
  setLastID,
  getAbbrDic,
  namingDic,
  setLocalAbbrDic,
  setNamingDic,
  indexChain
) {
  let localList = [...modelList];
  let localFEID = firstIDToUse;

  recursiveUpdate(localList);

  function recursiveUpdate(listOfModels) {
    let indexInPath = indexPath.shift();
    let currentModel = listOfModels[indexInPath];
    const oldOriginalModel = deepCopy(currentModel);

    if (currentModel.linked) {
      currentModel.displayName = generateName(
        currentModel.abbreviation,
        getAbbrDic,
        namingDic,
        setLocalAbbrDic,
        setNamingDic,
        localFEID
      );
      currentModel.linked = false;
      currentModel.FE_ID = localFEID;
      localFEID++;
    }
    listOfModels[indexInPath] = currentModel;

    if (indexPath[0] != undefined) {
      const updates = recursiveUpdate(listOfModels[indexInPath].subModels);

      let nonRecSubModel = false;

      for (let i = 0; i < currentModel.modelParams.length; i++) {
        const param = currentModel.modelParams[i];

        if (param.type === "Model") {
          if (
            param.reffit_id === updates.new.reffitID &&
            param.recuring === 0
          ) {
            nonRecSubModel = true;
          } else {
            break;
          }
        }
      }

      if (currentModel.subModels.length > 0 && !nonRecSubModel) {
        currentModel = updateParamsAndRowsOfModel(
          currentModel,
          updates.old.FE_ID,
          updates.new,
          indexChain
        );
      }

      listOfModels[indexInPath] = currentModel;
    }

    return {
      new: currentModel,
      old: oldOriginalModel,
    };
  }

  setLastID(localFEID);
  return localList;
}

function countIds(obj, lookup = {}) {
  if (Array.isArray(obj)) {
    obj.forEach((element) => countIds(element, lookup));
  } else if (typeof obj === "object" && obj !== null) {
    if ("FE_ID" in obj) {
      if (!(obj.FE_ID in lookup)) {
        lookup[obj.FE_ID] = 0;
      }
      lookup[obj.FE_ID]++;
    }

    if ("subModels" in obj) {
      countIds(obj.subModels, lookup);
    }
  }
}

function updateLinks(obj, lookup) {
  if (Array.isArray(obj)) {
    obj.forEach((element) => updateLinks(element, lookup));
  } else if (typeof obj === "object" && obj !== null) {
    if ("FE_ID" in obj && lookup[obj.FE_ID] === 1) {
      obj.linked = false;
    }

    if ("subModels" in obj) {
      updateLinks(obj.subModels, lookup);
    }
  }
}

export function refreshLinks(modelList) {
  const lookup = {};
  countIds(modelList, lookup);
  updateLinks(modelList, lookup);
}

function displayModels(
  modelData,
  renameModel,
  startLinking,
  isLinkActive,
  deleteModel,
  addSubModel,
  parentIndex,
  unlinkModel,
  canLink,
  canBeDeleted,
  namingDic,
  linkingID
) {
  let subModel = <></>;
  if (modelData.subModels.length > 0) {
    subModel = [];
    for (let i = 0; i < modelData.subModels.length; i++) {
      const deeperIndex = parentIndex + ";" + i;
      let canBeDeletedNext = false;
      if (
        modelData.subModels.length > 0 &&
        modelData.subModels.length > modelData.minSubModelCount
      ) {
        canBeDeletedNext = true;
      }
      subModel.push(
        displayModels(
          modelData.subModels[i],
          renameModel,
          startLinking,
          isLinkActive,
          deleteModel,
          addSubModel,
          deeperIndex,
          unlinkModel,
          canLink,
          canBeDeletedNext,
          namingDic,
          linkingID
        )
      );
    }
  }

  const keyFromIndexes = parseInt("1" + parentIndex.replaceAll(";", ""));
  const nameForDisplay = Object.prototype.hasOwnProperty.call(
    namingDic,
    modelData.FE_ID
  )
    ? namingDic[modelData.FE_ID]
    : modelData.displayName;

  const isLinked = hasProperty(modelData, "linked") ? modelData.linked : false;

  return (
    <div
      className="nestedModels"
      key={keyFromIndexes + "|" + modelData.FE_ID}
      data-key={parentIndex}
    >
      <ModelEntry
        modelName={nameForDisplay}
        canAdd={modelData.canAdd}
        linked={isLinked}
        ID={modelData.FE_ID}
        addSubModel={addSubModel}
        unlink={unlinkModel}
        startLink={startLinking}
        deleteEntry={deleteModel}
        setModelName={renameModel}
        isLinkActive={isLinkActive}
        // canLink={canLink}
        canBeDeleted={canBeDeleted}
        reffitID={modelData.reffitID}
        indexChain={parentIndex}
        linkingID={linkingID}
      />
      {subModel}
    </div>
  );
}

export function createModelsForDisplay(
  modelData,
  renameModel,
  startLinking,
  isLinkActive,
  deleteModel,
  addSubModel,
  unlinkModel,
  canLink,
  namingDic,
  linkingID
) {
  let modelsForDisplay = [];
  for (let i = 0; i < modelData.length; i++) {
    const parentIndex = "" + i;
    const canBeDeleted = true;
    modelsForDisplay.push(
      displayModels(
        modelData[i],
        renameModel,
        startLinking,
        isLinkActive,
        deleteModel,
        addSubModel,
        parentIndex,
        unlinkModel,
        canLink,
        canBeDeleted,
        namingDic,
        linkingID
      )
    );
  }

  return modelsForDisplay;
}

export function deepCopy(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepCopy);
  }

  const copiedObj = {};
  for (const key in obj) {
    copiedObj[key] = deepCopy(obj[key]);
  }

  return copiedObj;
}

export function updateDisplayName(modelData, ID, newName) {
  // Create a new copy of the model data array
  const newData = [...modelData];

  // Loop through each object in the model data array
  newData.forEach((obj) => {
    // If the current object's FE_ID matches the provided ID, update its displayName property
    if (obj.FE_ID === ID) {
      obj.displayName = newName;
    }

    // If the current object has subModels, recursively call this function on each subModel
    if (obj.subModels && obj.subModels.length > 0) {
      obj.subModels = updateDisplayName(obj.subModels, ID, newName);
    }
  });

  // Return the updated model data array
  return newData;
}

export function isDescendant(event) {
  if (
    event != undefined &&
    (event.className === "models" ||
      event.className === "modelList" ||
      event.className === "nestedModels" ||
      event.className === "modelEntry" ||
      event.className === "modelEntryName" ||
      event.className === "modelEntryIcon")
  ) {
    return true;
  } else {
    return false;
  }
}

export function constructModelList(modelList, handleModalEntryClick) {
  let localModels = [...modelList];
  localModels = localModels.map((model, index) => {
    return (
      <ModalListEntry
        key={index}
        modelName={model.name}
        modelDescription={model.description}
        reffitID={model.reffit_id}
        // id={model.id}
        // params={model.params}
        // abbreviation={model.abbreviation}
        handleClick={handleModalEntryClick}
      />
    );
  });

  return localModels;
}

function generateName(
  name,
  getAbbrDic,
  namingDic,
  setLocalAbbrDic,
  setNamingDic,
  idToPut
) {
  const abbrDic = getAbbrDic();
  if (Object.prototype.hasOwnProperty.call(namingDic, idToPut)) {
    return namingDic[idToPut];
  } else if (Object.prototype.hasOwnProperty.call(abbrDic, name)) {
    const nameWithNum = name + "_" + abbrDic[name];
    setLocalAbbrDic({ ...abbrDic, [name]: abbrDic[name] + 1 });
    setNamingDic({ ...namingDic, [idToPut]: nameWithNum });
    return nameWithNum;
  } else {
    setLocalAbbrDic({ ...abbrDic, [name]: 1 });
    setNamingDic({ ...namingDic, [idToPut]: name });
    return name;
  }
}

export function generateModel(
  modelReffitID,
  getID,
  upID,
  getAbbrDic,
  setLocalAbbrDic,
  allModels,
  namingDic,
  setNamingDic,
  linked = false,
  recordedErrorLog
) {
  const idToPut = getID();

  const currentModel = allModels.find(
    (model) => model.reffit_id === modelReffitID
  );

  const autoFit = currentModel.autofit == true;
  const vdf = currentModel.vdf == true;
  const sort = currentModel.sort ? true : false;

  const displayName = generateName(
    currentModel.abbreviation != null
      ? currentModel.abbreviation
      : currentModel.name,
    getAbbrDic,
    namingDic,
    setLocalAbbrDic,
    setNamingDic,
    idToPut
  );

  const canAdd = currentModel.params.some((param) => {
    return param.type == "Model" && param.recuring > 0;
  });

  const minSubModelCount = canAdd ? 1 : 0;

  const abbreviation =
    currentModel.abbreviation != null
      ? currentModel.abbreviation
      : currentModel.name;

  const subModelTypes = currentModel.params
    .filter((param) => param.type == "Model" && param.recuring > 0)
    .map((param) => parseInt(param.name));

  const modelOutputs = currentModel.outputs;

  const FE_ID = idToPut;
  upID();
  const BE_ID = currentModel.id;
  const modelDescription = currentModel.description;

  let subModelsToGenerate = [];

  currentModel.params
    .filter((param) => param.type == "Model")
    .forEach((param) => {
      for (let i = 0; i < param.default; i++) {
        subModelsToGenerate.push(parseInt(param.name));
      }
    });

  const subModels = subModelsToGenerate.map((modelRefID) =>
    generateModel(
      modelRefID,
      getID,
      upID,
      getAbbrDic,
      setLocalAbbrDic,
      allModels,
      namingDic,
      setNamingDic,
      false,
      recordedErrorLog
    )
  );

  const modelParams = currentModel.params.map((param) => {
    if (param.type === "Model" && param.recuring === 0) {
      const nonRecModelToUse = subModels.find(
        (model) => model.reffitID === parseInt(param.name)
      );
      return {
        ...param,
        value: param.default,
        customFixed: param.fixed,
        FE_ID: nonRecModelToUse.FE_ID,
        name: param.name
          .replace(/\s+/g, "")
          .replace(/\[/g, "(")
          .replace(/\]/g, ")"),
      };
    }
    return {
      ...param,
      value: param.default,
      customFixed: param.fixed,
      name: param.name
        .replace(/\s+/g, "")
        .replace(/\[/g, "(")
        .replace(/\]/g, ")"),
    };
  });

  const recParams = [
    currentModel.params
      .filter(
        (param) =>
          param.recuring != 0 &&
          (param.type === "int" ||
            param.type === "float" ||
            param.type === "Checkbox" ||
            param.type === "Model")
      )
      .map((param) => {
        if (param.type === "int" || param.type === "float") {
          return {
            ...param,
            value: param.default,
            customFixed: param.fixed,
            name: param.name
              .replace(/\s+/g, "")
              .replace(/\[/g, "(")
              .replace(/\]/g, ")"),
          };
        } else if (param.type === "Checkbox") {
          return {
            ...param,
            value: param.default === 1,
            name: param.name
              .replace(/\s+/g, "")
              .replace(/\[/g, "(")
              .replace(/\]/g, ")"),
          };
        } else if (param.type === "Model") {
          // THIS WILL NEED SOME UPDATING IF WE START HAVING 2 MODELS PER REC PARAMETER ROW.
          // P.S. param.name === reffit_id when it comes to models as parameters
          const paramToReturn = {
            ...param,
            FE_ID: subModels[0].FE_ID,
          };
          return paramToReturn;
        } else {
          return param;
        }
      }),
  ];

  let recTableRow = null;
  let recTableRows = [];
  let recTemplate = null;

  if (recParams.length > 0) {
    if (
      subModels.length > 0 &&
      recParams[0].some((param) => param.type === "Model")
    ) {
      let templateRowNoMod = {};

      for (let i = 0; i < recParams[0].length; i++) {
        const param = recParams[0][i];
        if (param.type == "Model") {
          templateRowNoMod = { ...templateRowNoMod, Model: "PLACEHOLDER" };
        } else {
          templateRowNoMod = { ...templateRowNoMod, [param.name]: param.value };
        }
      }

      for (let i = 0; i < subModels.length; i++) {
        // If we are here, it means we will have multiple rows of rec params with sub models, therefore on index = 2
        // we need to start pushing duplicate param rows to rec params
        const subModel = subModels[i];

        if (i > 0) {
          const updatedParamRow = [];
          // THIS WILL NEED SOME UPDATING IF WE START HAVING 2 MODELS PER REC PARAMETER ROW.
          // P.S. param.name === reffit_id when it comes to models as parameters
          for (let j = 0; j < recParams[0].length; j++) {
            const param = recParams[0][j];
            if (param.type === "Model") {
              const paramToReturn = {
                ...param,
                FE_ID: subModel.FE_ID,
              };
              updatedParamRow.push(paramToReturn);
            } else {
              updatedParamRow.push(param);
            }
          }

          recParams.push(updatedParamRow);
        }

        recTableRows.push({ ...templateRowNoMod, Model: subModel.displayName });
      }

      recTableRow = recTableRows[0];
    } else {
      for (let i = 0; i < recParams[0].length; i++) {
        const param = recParams[0][i];
        if (param.type == "Model") {
          recordedErrorLog(
            "PARAM WITH MODEL FOUND IN NO SUB MODEL CASE: ",
            param
          );
        } else {
          recTableRow = { ...recTableRow, [param.name]: param.value };
        }
      }
      recTableRows = [recTableRow];
    }

    recTemplate = {
      row: deepCopy(recTableRow),
      params: deepCopy(recParams[0]),
    };
  }

  if (recParams.length > 0 && recTableRow != null) {
    return {
      displayName,
      canAdd,
      autoFit,
      vdf,
      abbreviation,
      minSubModelCount,
      modelParams,
      FE_ID,
      BE_ID,
      linked,
      subModelTypes,
      reffitID: modelReffitID,
      modelDescription,
      subModels: subModels,
      sort,
      recParams,
      recTableRows,
      recTemplate,
      outputs: modelOutputs,
    };
  } else {
    return {
      displayName,
      canAdd,
      autoFit,
      vdf,
      abbreviation,
      minSubModelCount,
      modelParams,
      FE_ID,
      BE_ID,
      linked,
      subModelTypes,
      reffitID: modelReffitID,
      modelDescription,
      subModels: subModels,
      sort,
      outputs: modelOutputs,
    };
  }
}

export function generateModelFromFile(
  modelFromFile,
  getID,
  upID,
  getAbbrDic,
  setLocalAbbrDic,
  namingDic,
  setNamingDic,
  linked = false
) {
  const idToPut = getID();
  const FE_ID = idToPut;
  upID();

  const displayName = generateName(
    modelFromFile.displayName,
    getAbbrDic,
    namingDic,
    setLocalAbbrDic,
    setNamingDic,
    idToPut
  );

  const subModels = modelFromFile.subModels.map((model) =>
    generateModelFromFile(
      model,
      getID,
      upID,
      getAbbrDic,
      setLocalAbbrDic,
      namingDic,
      setNamingDic
    )
  );

  let modelToReturn = {
    displayName,
    canAdd: modelFromFile.canAdd,
    autoFit: modelFromFile.autoFit,
    vdf: modelFromFile.vdf,
    abbreviation: modelFromFile.abbreviation,
    minSubModelCount: modelFromFile.minSubModelCount,
    modelParams: modelFromFile.modelParams,
    FE_ID,
    BE_ID: "!THIS ID IS NEVER USED AND THEREFORE NOT BEING SET HERE!",
    linked,
    subModelTypes: modelFromFile.subModelTypes,
    reffitID: modelFromFile.reffitID,
    outputs: modelFromFile.outputs,
    modelDescription: modelFromFile.modelDescription,
    subModels: subModels,
  };

  if (Object.prototype.hasOwnProperty.call(modelFromFile, "recParams")) {
    modelToReturn = {
      ...modelToReturn,
      recParams: modelFromFile.recParams,
      recTableRows: modelFromFile.recTableRows,
      recTemplate: modelFromFile.recTemplate,
    };
  }
  if (Object.prototype.hasOwnProperty.call(modelFromFile, "curve")) {
    modelToReturn = {
      ...modelToReturn,
      curves: modelFromFile.curves,
    };
  }

  return modelToReturn;
}

export function createModelFromParamList(
  modelLoaded,
  modelTemplate,
  generateModel
) {
  let modelTemplateCopy = deepCopy(modelTemplate);
  // Adding outputs to the model template
  // modelTemplateCopy.outputs = modelLoaded.outputs;
  // let strangeResults = false;
  // Find non recuring params from model
  const nonRecParams = modelTemplateCopy.modelParams.filter(
    (param) => param.recuring == 0
  );

  // Get new parameters for the model, remove first entry, since it's not needed
  const modelParamsToUse = deepCopy(modelLoaded.parameters.global);
  if (modelTemplate.reffitID !== 0) {
    modelParamsToUse.shift(); // IF NOT A MATERIAL(DL) MODEL ONLY!
  }

  let recParamsForUpdate = [];
  const hasRec = hasProperty(modelTemplateCopy, "recTemplate");
  // From a model recuring parameter template, get a parameter with a smallest reffit id
  const smallestIDTemplateParam = hasRec
    ? modelTemplateCopy.recTemplate.params.reduce(
        (min, param) => (min.reffit_id < param.reffit_id ? min : param),
        modelTemplateCopy.recTemplate.params[0]
      )
    : null;

  // In this array we will keep all the found parameters and we will use it to check which parameters
  // have not been used
  let allFoundParams = [];

  // Loop through all the new parameters to sort them
  for (let i = 0; i < modelParamsToUse.length; i++) {
    const modelParam = modelParamsToUse[i];
    // If new parameter matches non recuring parameter reffit id, update the non recuring parameter
    if (nonRecParams.some((param) => param.reffit_id == modelParam.reffit_id)) {
      modelTemplateCopy.modelParams = modelTemplateCopy.modelParams.map(
        (param) => {
          if (param.reffit_id == modelParam.reffit_id) {
            const newParam = {
              ...param,
              value:
                param.type !== "Checkbox"
                  ? modelParam.value
                  : checkBool(modelParam.value),
            };
            if (hasProperty(modelParam, "matched")) {
              newParam.group = modelParam.matched;
            } else if (hasProperty(newParam, "group")) {
              delete newParam.group;
            }
            if (hasProperty(modelParam, "hardmax")) {
              newParam.hardMax = modelParam.hardmax;
            } else if (hasProperty(newParam, "hardMax")) {
              delete newParam.hardMax;
            }
            if (hasProperty(modelParam, "hardmin")) {
              newParam.hardMin = modelParam.hardmin;
            } else if (hasProperty(newParam, "hardMin")) {
              delete newParam.hardMin;
            }
            if (modelParam.fixed && !newParam.fixed) {
              newParam.customFixed = true;
            } else {
              newParam.customFixed = newParam.fixed;
            }
            return newParam;
          } else {
            return param;
          }
        }
      );

      // Add Non Recuring parameters to the list of found values
      allFoundParams.push(modelParam);
    } else {
      // If it doesn't match, that means the new parameter is potentially a recuring one, add it to
      // possibly recuring parameter array if its reffit id is equal or higher than the smallest template reffit id
      // this prevents of adding values which are guaranteed unfit for the recuring parameters
      if (hasRec && modelParam.reffit_id >= smallestIDTemplateParam.reffit_id) {
        recParamsForUpdate.push(modelParam);
      }
      // else {
      //   if (modelParam.value !== 10 && modelParam.value !== 100) {
      //     // strangeResults = true;
      //   }
      // }
    }
  }

  if (hasRec) {
    // Find the new parameter with the largest reffit id
    const paramForUpdateWithLargestID = recParamsForUpdate.reduce(
      (max, param) => (max.reffit_id > param.reffit_id ? max : param),
      recParamsForUpdate[0]
    );

    // Find the recuring parameter from a template with the largest reffit id and recuring value combination for
    // given largest reffit id of parameter for updating
    const largestIDModelTemplateParam =
      modelTemplateCopy.recTemplate.params.reduce(
        (max, param) =>
          (paramForUpdateWithLargestID.reffit_id - max.reffit_id) /
            max.recuring >
          (paramForUpdateWithLargestID.reffit_id - param.reffit_id) /
            param.recuring
            ? max
            : param,
        modelTemplateCopy.recTemplate.params[0]
      );

    // Calculate maximum possible recuring table rows
    const maxPossibleRows = Math.floor(
      (paramForUpdateWithLargestID.reffit_id -
        largestIDModelTemplateParam.reffit_id) /
        largestIDModelTemplateParam.recuring
    );

    let rowsOfValues = [];

    // Loop until max possible rows are reached, <= is used because we calculate rows from 0
    for (let row = 0; row <= maxPossibleRows; row++) {
      let values = [];
      // loop through template parameters to match each parameter with
      for (
        let paramIndex = 0;
        paramIndex < modelTemplateCopy.recTemplate.params.length;
        paramIndex++
      ) {
        // Find the value according to a parameter from the template
        const param = modelTemplateCopy.recTemplate.params[paramIndex];
        let foundValue;
        if (param.type === "Model") {
          const modelDataFromLoaded = recParamsForUpdate.find(
            (paramToUpdate) =>
              paramToUpdate.reffit_id == param.reffit_id + row * param.recuring
          );
          foundValue = {
            modelReffitId: parseInt(param.name),
            type: param.type,
            reffit_id: param.reffit_id,
            FE_ID: modelDataFromLoaded.value,
          };
        } else {
          foundValue = recParamsForUpdate.find(
            (paramToUpdate) =>
              paramToUpdate.reffit_id == param.reffit_id + row * param.recuring
          );
        }
        // Add recuring parameters to the list of found values
        allFoundParams.push(foundValue);
        // If the value is found, push it to the value row
        if (foundValue != undefined) {
          let valueToUse;

          if (foundValue.type === "Model") {
            valueToUse = { ...foundValue };
          } else {
            valueToUse = {
              value:
                param.type !== "Checkbox"
                  ? foundValue.value
                  : checkBool(foundValue.value),
              reffit_id: param.reffit_id,
            };
            if (foundValue.fixed !== undefined) {
              valueToUse.fixed = foundValue.fixed;
            }
            if (hasProperty(foundValue, "matched")) {
              valueToUse.group = foundValue.matched;
            }
            if (hasProperty(foundValue, "hardmax")) {
              valueToUse.hardMax = foundValue.hardmax;
            }
            if (hasProperty(foundValue, "hardmin")) {
              valueToUse.hardMin = foundValue.hardmin;
            }
          }
          values.push(valueToUse);
        }
      }
      // Push the row of values to the array of rows
      rowsOfValues.push(values);
    }

    // Find all the unincluded parameters
    // const allNotIncluded = recParamsForUpdate.filter(
    //   (item) => !allFoundParams.includes(item)
    // );

    // if (
    //   allNotIncluded.some((param) => param.value !== 10 && param.value !== 100)
    // ) {
    //   strangeResults = true;
    // }

    // // Check if results are not strange and if they are, create an error message for the user
    // if (strangeResults) {
    //   console.error("File did not match model with same reffit id");
    //   return "File did not match model with same reffit id";
    // }

    // Check if the last row is completely filled, if not, discard it
    if (
      rowsOfValues[rowsOfValues.length - 1].length !=
      modelTemplateCopy.recTemplate.params.length
    ) {
      rowsOfValues.pop();
    }

    // Loop through the array of new row values
    for (let rowIndex = 0; rowIndex < rowsOfValues.length; rowIndex++) {
      const rowValues = rowsOfValues[rowIndex];

      // Check if the row is in the recuring parameters table
      if (modelTemplateCopy.recParams.length > rowIndex) {
        // If the recuring parameters table contains this row, do the updates
        for (let i = 0; i < modelTemplateCopy.recParams[rowIndex].length; i++) {
          // Find the value from row values that matches rec table parameter reffit id
          const foundVal = rowValues.find(
            (rowVal) =>
              rowVal.reffit_id ==
              modelTemplateCopy.recParams[rowIndex][i].reffit_id
          );

          // If the value is found, update rec table parameter and row values
          // We skip this if foundVal is for model, since the model name used here should not change based on loaded params
          if (foundVal != undefined) {
            if (foundVal.type !== "Model") {
              const recParamsUpdate = {
                ...modelTemplateCopy.recParams[rowIndex][i],
                value: foundVal.value,
              };
              if (hasProperty(foundVal, "group")) {
                recParamsUpdate.group = foundVal.group;
              } else if (hasProperty(recParamsUpdate, "group")) {
                delete recParamsUpdate.group;
              }
              if (hasProperty(foundVal, "hardMax")) {
                recParamsUpdate.hardMax = foundVal.hardMax;
              } else if (hasProperty(recParamsUpdate, "hardMax")) {
                delete recParamsUpdate.hardMax;
              }
              if (hasProperty(foundVal, "hardMin")) {
                recParamsUpdate.hardMin = foundVal.hardMin;
              } else if (hasProperty(recParamsUpdate, "hardMin")) {
                delete recParamsUpdate.hardMin;
              }
              if (foundVal.fixed !== undefined && foundVal.fixed) {
                recParamsUpdate.customFixed = true;
              } else {
                recParamsUpdate.customFixed = recParamsUpdate.fixed;
              }
              modelTemplateCopy.recParams[rowIndex][i] = recParamsUpdate;

              modelTemplateCopy.recTableRows[rowIndex] = {
                ...modelTemplateCopy.recTableRows[rowIndex],
                [modelTemplateCopy.recParams[rowIndex][i].name]: foundVal.value,
              };
            } else {
              const newlyGeneratedModel = generateModel(
                foundVal.modelReffitId,
                foundVal.FE_ID
              );
              const idOfModelToChange =
                modelTemplateCopy.recParams[rowIndex][i].FE_ID;

              modelTemplateCopy.subModels = modelTemplateCopy.subModels.map(
                (subMod) => {
                  if (subMod.FE_ID === idOfModelToChange) {
                    subMod.recParams = newlyGeneratedModel.recParams;
                    subMod.modelParams = newlyGeneratedModel.modelParams;
                    subMod.recTableRows = newlyGeneratedModel.recTableRows;
                    subMod.recTemplate = newlyGeneratedModel.recTemplate;
                    subMod.subModels = newlyGeneratedModel.subModels;
                    return subMod;
                  } else {
                    return subMod;
                  }
                }
              );
            }
          }
        }
      } else {
        // If row index of new values is greater than current recuring parameter table lenght, that means we
        // need to add additional parameter row from template
        let paramRow = [];
        let tableRow = {};

        // Loop through recuring parameters template
        for (let i = 0; i < modelTemplateCopy.recTemplate.params.length; i++) {
          // Find the value from values row that matches template parameter reffit id
          const foundVal = rowValues.find(
            (rowVal) =>
              rowVal.reffit_id ==
              modelTemplateCopy.recTemplate.params[i].reffit_id
          );
          // If the value is found, add the paramter with value from row of values to temporary parameters
          // If the value is not found, we still add entry to the table, but with value from the template - this
          // prevents the creation of tables with undefined table cells
          if (foundVal != undefined) {
            if (foundVal.type !== "Model") {
              const updatedParamRow = {
                ...modelTemplateCopy.recTemplate.params[i],
                value: foundVal.value,
              };
              if (hasProperty(foundVal, "group")) {
                updatedParamRow.group = foundVal.group;
              }
              if (hasProperty(foundVal, "hardMax")) {
                updatedParamRow.hardMax = foundVal.hardMax;
              }
              if (hasProperty(foundVal, "hardMin")) {
                updatedParamRow.hardMin = foundVal.hardMin;
              }
              if (foundVal.fixed && !recParamsForUpdate.fixed) {
                updatedParamRow.customFixed = true;
              }
              paramRow.push(updatedParamRow);
              tableRow = {
                ...tableRow,
                [modelTemplateCopy.recTemplate.params[i].name]: foundVal.value,
              };
            } else {
              const newlyGeneratedModel = generateModel(
                foundVal.modelReffitId,
                foundVal.FE_ID
              );
              tableRow = {
                ...tableRow,
                Model: newlyGeneratedModel.displayName,
              };
              const updatedParamRow = {
                ...modelTemplateCopy.recTemplate.params[i],
                FE_ID: newlyGeneratedModel.FE_ID,
              };
              paramRow.push(updatedParamRow);
              modelTemplateCopy.subModels.push(newlyGeneratedModel);
            }
          } else {
            paramRow.push({
              ...modelTemplateCopy.recTemplate.params[i],
            });
            tableRow = {
              ...tableRow,
              [modelTemplateCopy.recTemplate.params[i].name]:
                modelTemplateCopy.recTemplate.params[i].value,
            };
          }
        }

        // When temporary recuring parameter and table row values are filled, update model with them
        modelTemplateCopy.recParams.push(paramRow);
        modelTemplateCopy.recTableRows.push(tableRow);
      }

      // Here we will check if there are any other rows remaining in the parameter table after we finished adding ones from BE. We will remove them if found
      if (rowIndex === rowsOfValues.length - 1) {
        modelTemplateCopy.recTableRows.splice(rowIndex + 1);
        modelTemplateCopy.recParams.splice(rowIndex + 1);
      }
    }

    // +1 to max possible row count because we calculate that from 0 and sub model length is calculated from 1
    if (maxPossibleRows + 1 < modelTemplateCopy.subModels.length) {
      modelTemplateCopy.subModels = modelTemplateCopy.subModels.slice(
        0,
        maxPossibleRows + 1
      );
    }
  }

  return modelTemplateCopy;
}

export function collectClashingGroupsFromNonRecParams(
  model,
  valueGroups,
  groupsUpdate
) {
  let clashingGroups = {};
  let localGroupsUpdate = groupsUpdate;

  for (let i = 0; i < model.modelParams.length; i++) {
    const param = model.modelParams[i];

    if (param.recuring === 0 && hasProperty(param, "group")) {
      const matchingGroup = valueGroups.find(
        (group) => group.groupNumber === param.group
      );
      if (matchingGroup.value !== null && matchingGroup.value !== param.value) {
        if (hasProperty(clashingGroups, param.group)) {
          clashingGroups = {
            ...clashingGroups,
            [param.group]: {
              currentValue: matchingGroup.value,
              incomingValue: param.value,
              memberCount: clashingGroups[param.group].memberCount + 1,
            },
          };
        } else {
          clashingGroups = {
            ...clashingGroups,
            [param.group]: {
              currentValue: matchingGroup.value,
              incomingValue: param.value,
              memberCount: 1,
            },
          };
        }
      } else {
        localGroupsUpdate = localGroupsUpdate.map((group) => {
          if (group.groupNumber === param.group) {
            return {
              ...group,
              value: param.value,
              memberCount: group.memberCount + 1,
            };
          } else {
            return group;
          }
        });
      }
    }
  }

  return {
    clashingGroups,
    groupsUpdate: localGroupsUpdate,
  };
}

export function collectClashingGroupsFromRecParams(
  model,
  valueGroups,
  clashingGroups,
  groupsUpdate
) {
  let localClashingGroups = clashingGroups;
  let localGroupsUpdate = groupsUpdate;

  for (let i = 0; i < model.recParams.length; i++) {
    const paramRow = model.recParams[i];

    for (let j = 0; j < paramRow.length; j++) {
      const param = paramRow[j];
      if (hasProperty(param, "group")) {
        const matchingGroup = valueGroups.find(
          (group) => group.groupNumber === param.group
        );
        if (
          matchingGroup.value !== null &&
          matchingGroup.value !== param.value
        ) {
          if (hasProperty(localClashingGroups, param.group)) {
            localClashingGroups = {
              ...localClashingGroups,
              [param.group]: {
                currentValue: matchingGroup.value,
                incomingValue: param.value,
                memberCount: localClashingGroups[param.group].memberCount + 1,
              },
            };
          } else {
            localClashingGroups = {
              ...localClashingGroups,
              [param.group]: {
                currentValue: matchingGroup.value,
                incomingValue: param.value,
                memberCount: 1,
              },
            };
          }
        } else {
          localGroupsUpdate = localGroupsUpdate.map((group) => {
            if (group.groupNumber === param.group) {
              return {
                ...group,
                value: param.value,
                memberCount: group.memberCount + 1,
              };
            } else {
              return group;
            }
          });
        }
      }
    }
  }

  return {
    clashingGroups: localClashingGroups,
    groupsUpdate: localGroupsUpdate,
  };
}

export function allContainedGroups(model) {
  const containedGroups = {};

  for (let i = 0; i < model.modelParams.length; i++) {
    const param = model.modelParams[i];

    if (param.recuring === 0 && hasProperty(param, "group")) {
      if (hasProperty(containedGroups, param.group)) {
        containedGroups[param.group] = {
          value: param.value,
          memberCount: containedGroups[param.group].memberCount + 1,
        };
      } else {
        containedGroups[param.group] = {
          value: param.value,
          memberCount: 1,
        };
      }
    }
  }

  if (hasProperty(model, "recParams")) {
    for (let i = 0; i < model.recParams.length; i++) {
      const paramRow = model.recParams[i];

      for (let j = 0; j < paramRow.length; j++) {
        const param = paramRow[j];
        if (hasProperty(param, "group")) {
          if (hasProperty(containedGroups, param.group)) {
            containedGroups[param.group] = {
              value: param.value,
              memberCount: containedGroups[param.group].memberCount + 1,
            };
          } else {
            containedGroups[param.group] = {
              value: param.value,
              memberCount: 1,
            };
          }
        }
      }
    }
  }

  return containedGroups;
}

// export function addNonClashingGroups(
//   allContainedGroups,
//   groupsToUpdate,
//   clashingGroupNumbers
// ) {
//   const containedGroupKeys = Object.keys(allContainedGroups);
//   const updatedGroups = groupsToUpdate.map((group) => {
//     if (
//       containedGroupKeys.some((key) => parseInt(key) === group.groupNumber) &&
//       !clashingGroupNumbers.some((key) => parseInt(key) === group.groupNumber)
//     ) {
//       return {
//         ...group,
//         value: allContainedGroups[group.groupNumber].value,
//         memberCount:
//           group.memberCount + allContainedGroups[group.groupNumber].memberCount,
//       };
//     } else {
//       return group;
//     }
//   });

//   return updatedGroups;
// }

export function updateRecParamsAndRows(
  modelsToUpdate,
  modelToReplaceID,
  newModel,
  indexChain
) {
  let modelsCopy = deepCopy(modelsToUpdate);

  const modelChain = getUpdatedUniqueIds([modelToReplaceID], modelsToUpdate);

  const indexOfParentModel = modelChain.indexOf(modelToReplaceID) - 1;

  if (indexOfParentModel < 0) {
    return null;
  }

  let parentModel = getModelById(modelChain[indexOfParentModel], modelsCopy);

  parentModel = updateParamsAndRowsOfModel(
    parentModel,
    modelToReplaceID,
    newModel,
    indexChain
  );

  modelsCopy = replaceModelById(modelsCopy, parentModel, parentModel.FE_ID);

  return modelsCopy;
}

function updateParamsAndRowsOfModel(
  parentModel,
  modelToReplaceID,
  newModel,
  indexChain
) {
  const indexChainInt = indexChain.split(";").map((index) => parseInt(index));
  const indexOfRow = indexChainInt[indexChainInt.length - 1];

  const parentModelCopy = deepCopy(parentModel);

  // let indexOfRow = null;

  // const recParamsUpdate = [];

  // for (let i = 0; i < parentModelCopy.recParams.length; i++) {
  //   const paramRow = parentModelCopy.recParams[i];

  //   const paramRowUpdate = [];
  //   for (let j = 0; j < paramRow.length; j++) {
  //     const param = paramRow[j];

  //     if (hasProperty(param, "FE_ID") && param.FE_ID === modelToReplaceID) {
  //       param.FE_ID = newModel.FE_ID;
  //       // indexOfRow = i;
  //     }

  //     paramRowUpdate.push(param);
  //   }

  //   recParamsUpdate.push(paramRowUpdate);
  // }

  // parentModelCopy.recParams = recParamsUpdate;

  const paramRowUpdate = [];

  for (let j = 0; j < parentModelCopy.recParams[indexOfRow].length; j++) {
    const param = parentModelCopy.recParams[indexOfRow][j];

    if (hasProperty(param, "FE_ID") && param.FE_ID === modelToReplaceID) {
      param.FE_ID = newModel.FE_ID;
      // indexOfRow = i;
    }

    paramRowUpdate.push(param);
  }

  parentModelCopy.recParams[indexOfRow] = paramRowUpdate;

  parentModelCopy.recTableRows[indexOfRow].Model = newModel.displayName;

  return parentModelCopy;
}
